// Components
import { Collapse, Table, Icon } from '@octano/global-ui';
import { Col } from 'reactstrap';
import Badge from './Badge';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { format } from 'rut.js';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentStep } from '../../../recruitment/offer-detail/types';
import { AuthorizationStatus } from '../../../academic-authorization/types';
import UseAuthorizedPostulantsPostulationsColumns, {
  UseAuthorizedPostulantsPostulationsColumnsProps,
} from '../hooks/useAuthorizedPostulantsPostulationsColumns';
import { useGetAuthorizedPostulantPostulationsQuery } from '../../api';

// Types
import {
  GetAuthorizedPostulatsFilters,
  AuthorizedPostulantItem,
} from '../../types';
import { AuthorizedPostulantsContext } from '../../../../providers/AuthorizedPostulantsProvider';

type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  name: string | null;
  offerId: ItemSelect | null;
  campusId: ItemSelect | null;
  careerId: ItemSelect | null;
  statusId: ItemSelect | null;
};

export type OnFilterParams = GetAuthorizedPostulatsFilters;

export type FilterFieldsSubmit = {
  name: string | null;
  offerId: { value: string };
  campusId: { value: string };
  careerId: { value: string };
  statusId: { value: string };
  schoolId: { value: string };
};

type AuthorizedPostulantRowProps =
  UseAuthorizedPostulantsPostulationsColumnsProps & {
    item: AuthorizedPostulantItem;
    filters?: OnFilterParams;
  };

const keyPrefix = 'views.hiring.authorizedPostulants';

// Render
const AuthorizedPostulantRow = (props: AuthorizedPostulantRowProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { item } = props;
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

  const history = useHistory();

  const { selecteds = [], onToggle } = useContext(AuthorizedPostulantsContext);

  const { data = [], isFetching } = useGetAuthorizedPostulantPostulationsQuery(
    [item?.user?.id, props?.filters],
    {
      refetchOnMountOrArgChange: true,
      skip: !isOpenCollapse,
    },
  );

  const columns = UseAuthorizedPostulantsPostulationsColumns({
    ...props,
    userId: item?.user?.id,
    generateEnabled:
      !isFetching &&
      data?.some(
        (e) =>
          e?.authorizationStatus === AuthorizationStatus.AuthorizedForHiring,
      ),
  });

  const isSelected = useMemo(
    () => Boolean(selecteds?.some((id) => id === item?.user?.id)),
    [item?.user?.id, selecteds],
  );

  const openRequirements = useCallback(
    (user: AuthorizedPostulantItem['user']) => {
      history.push({
        pathname: `/hiring/users/${user?.id}/required-files`,
        state: {
          user,
        },
      });
    },
    [history],
  );

  return (
    <Collapse
      renderTitle={() => (
        <>
          <Col>
            <span className="text-center w-100">{item?.user?.name}</span>
          </Col>
          <Col>
            <span className="text-center w-100">
              {format(item?.user?.runOrPassport)}
            </span>
          </Col>
          <Col>
            <span className="text-center w-100">
              {item?.postulationCounter?.total}
            </span>
          </Col>
          <Col>
            <span className="text-center w-100 fw-700">{`${item?.postulationCounter?.changedStatus}/${item?.postulationCounter?.total}`}</span>
          </Col>
          <Col>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <div className="mr-2">
                <Badge
                  text={
                    !item?.hasPendingFiles
                      ? t('hiringRequires.ready')
                      : t('hiringRequires.pending')
                  }
                  color={!item?.hasPendingFiles ? '#CEF0DA' : '#DDEFFF'}
                />
              </div>
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => openRequirements(item?.user)}
              >
                <Icon name="edit" color="primary" />
              </button>
            </div>
          </Col>
        </>
      )}
      headerClassName="row"
      renderBtn={({ isOpened, toggle }) => (
        <>
          <Col style={{ maxWidth: 125 }}>
            <div className="w-100 d-flex align-items-center">
              <Collapse.ToggleButton
                iconName={isOpened ? 'minus' : 'plus'}
                toggle={() => {
                  setIsOpenCollapse(!isOpened);
                  toggle();
                }}
                btnClassName="bg-primary mx-auto"
                iconColor="white"
              />
              <button
                type="button"
                style={{
                  width: 19,
                  height: 19,
                  borderWidth: 1,
                  borderRadius: 4,
                }}
                className={`btn btn-sm d-flex align-items-center justify-content-center text-white ${
                  isSelected ? ' btn-primary' : 'btn-outline-primary'
                }`}
                onClick={() => onToggle && onToggle(item?.user?.id)}
              >
                {isSelected && (
                  <div>
                    <Icon name="check" color="white" size={9} />
                  </div>
                )}
              </button>
            </div>
          </Col>
        </>
      )}
    >
      <Table
        columns={columns}
        isLoadingResults={isFetching}
        data={[
          ...data,
          {
            id: 0,
            offerName: '',
            currentStep: CurrentStep.Hired,
            authorizationStatus: AuthorizationStatus.Hired,
          },
        ]}
        striped
        color="transparent"
        borderless={false}
      />
    </Collapse>
  );
};

export default AuthorizedPostulantRow;
