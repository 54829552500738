import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { getSubjectsList } from '../../api';
import { SectionFiltersQuery, SectionsResponse } from '../../type';

interface BaseLoaderProps {
  children: ReactElement;
  filterComponent: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  SectionFiltersQuery,
  SectionsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useSectionsLoader() {
  return useFetch();
}

export const SectionsLoaderConsumer = FetchConsumer;

export default function SectionsLoader({
  children,
  filterComponent,
}: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: SectionFiltersQuery,
    ): Promise<AxiosResult<SectionsResponse, AxiosResultDefaultError>> => {
      return getSubjectsList(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{
        page: 1,
        itemsPerPage: 10,
      }}
      fetchImmediately
    >
      {filterComponent}
      {children}
    </FetchProvider>
  );
}
