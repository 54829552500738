// Components
import Badge from '../parts/Badge';

// Hooks
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { Button, ColumnTable, Icon } from '@octano/global-ui';
import {
  AuthorizationStatus,
  PostulantToAuthorizePostulationItem,
} from '../../types';
import { OnFilterParams } from '../parts/Filters';

export type UsePostulantsAuthorizationPostulationsColumnsProps = {
  userId?: string | number;
  filters?: OnFilterParams;
  onPressReject: (
    userId: string | number,
    postulationId: string | number,
  ) => void;
  onPressAuthorize: (
    userId: string | number,
    postulationId: string | number,
  ) => void;
  onPressAuthorizeAll: (
    userId: string | number,
    postulationsIds: (string | number)[],
  ) => void;
};

const keyPrefix = 'views.academicAuthorization.postulantsAuthorization';

// Hook
const UsePostulantsAuthorizationPostulationsColumns = ({
  userId = 0,
  onPressReject,
  onPressAuthorize,
  onPressAuthorizeAll,
}: UsePostulantsAuthorizationPostulationsColumnsProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const history = useHistory();

  const goToPostulation = useCallback(
    (id: string | number) => {
      history.push(
        `/academic-authorization/users/${userId}/postulations/${id}`,
      );
    },
    [history, userId],
  );

  const statuses: Record<AuthorizationStatus, { color: string; text: string }> =
    useMemo(
      () => ({
        [AuthorizationStatus.NotAuthorizedForHiring]: {
          color: '#F2C0BD',
          text: t('authorizationStatuses.notAuthorizedForHiring'),
        },
        [AuthorizationStatus.DiscardedInHiring]: {
          color: '#F2C0BD',
          text: t('authorizationStatuses.discardedInHiring'),
        },
        [AuthorizationStatus.Hired]: {
          color: '#CEF0DA',
          text: t('authorizationStatuses.hired'),
        },
        [AuthorizationStatus.AuthorizedForHiring]: {
          color: '#CEF0DA',
          text: t('authorizationStatuses.authorizedForHiring'),
        },
        [AuthorizationStatus.Pending]: {
          color: '#DDEFFF',
          text: t('authorizationStatuses.pending'),
        },
      }),
      [t],
    );

  const columns = useMemo<ColumnTable<PostulantToAuthorizePostulationItem>[]>(
    () => [
      {
        columnName: 'offerName',
        headerText: t('table.postulationsTable.offer'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <div>
            <p>{value}</p>
          </div>
        ),
      },
      {
        columnName: 'id',
        headerText: t('table.postulationsTable.resumen'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => (
          <div>
            {row?.id !== 0 ? (
              <span onClick={() => goToPostulation(value)}>
                <Icon name="eye" size={18} />
              </span>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        columnName: 'authorizationStatus',
        headerText: t('table.postulationsTable.status'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => (
          <div>
            {row?.id !== 0 ? (
              <Badge
                text={statuses[value as AuthorizationStatus].text}
                color={statuses[value as AuthorizationStatus].color}
              />
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        columnName: 'id',
        headerText: t('table.postulationsTable.authorize'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '340px',
        cellFormat: ({ value, row }) => (
          <div className="d-flex flex-row">
            {row?.id !== 0 ? (
              <>
                <Button
                  style={{ height: 32, minHeight: 20 }}
                  color="danger"
                  size="sm"
                  className="mr-1 flex-fill"
                  disabled={
                    row?.authorizationStatus ===
                      AuthorizationStatus.DiscardedInHiring ||
                    row?.authorizationStatus ===
                      AuthorizationStatus.NotAuthorizedForHiring
                  }
                  outlined={true}
                  text={t('table.postulationsTable.rejectPostulation')}
                  onClick={() => onPressReject(userId, value)}
                />
                <Button
                  className="ml-1 flex-fill"
                  size="sm"
                  disabled={
                    row?.authorizationStatus ===
                      AuthorizationStatus.AuthorizedForHiring ||
                    row?.authorizationStatus === AuthorizationStatus.Hired
                  }
                  style={{ height: 32, minHeight: 20 }}
                  text={t('table.postulationsTable.authorizePostulation')}
                  onClick={() => onPressAuthorize(userId, value)}
                />
              </>
            ) : (
              <>
                <Button
                  className="flex-fill"
                  style={{ height: 40, minHeight: 20 }}
                  text={t('table.postulationsTable.authorizeAllPostulations')}
                  onClick={() => onPressAuthorizeAll(userId, [])}
                />
              </>
            )}
          </div>
        ),
      },
    ],
    [
      goToPostulation,
      onPressAuthorize,
      onPressAuthorizeAll,
      onPressReject,
      statuses,
      userId,
      t,
    ],
  );

  return columns;
};

export default UsePostulantsAuthorizationPostulationsColumns;
