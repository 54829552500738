// Types
type RadioButtonProps<T> = {
  value?: T;
  defaultValue?: T;
  label?: string | null;
  name?: string;
  id?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChangeChecked?: (next: boolean) => void;
  onChangeValue?: (next: T) => void;
};

// Render
function RadioButton<T = any>({
  value,
  defaultValue,
  label,
  name,
  id,
  className,
  checked = false,
  disabled = false,
  onChangeChecked,
  onChangeValue,
}: RadioButtonProps<T>) {
  const handleChange = (e: any) => {
    onChangeChecked && onChangeChecked(!checked);
    onChangeValue && onChangeValue(e?.target?.value);
  };

  return (
    <div className={`g-radio-button form-check ${className?.trim()}`?.trim()}>
      <input
        checked={!!checked}
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        disabled={disabled}
        value={value as any}
        defaultValue={defaultValue as any}
        onChange={handleChange}
      />
      {!!label?.trim() && (
        <label className="form-check-label" htmlFor={id}>
          {label?.trim()}
        </label>
      )}
    </div>
  );
}

export default RadioButton;
