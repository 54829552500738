import { Dispatch } from 'react';
import { OfferType } from './jobOffer';
import { OfferGuest } from '../views/draftMaintainer/types';

export enum StepActionType {
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  SET_STEP = 'SET_STEP',
}

export type StepAction =
  | { type: StepActionType.NEXT_STEP }
  | { type: StepActionType.PREV_STEP }
  | { type: StepActionType.SET_STEP; payload: number };

export type StepState = {
  currentStep: number;
};

export type StepContextType = {
  state: StepState;
  dispatch: Dispatch<StepAction>;
};

export interface CurrentStep {
  currentStep: {
    id: number;
    step:
      | 'detail-offer'
      | 'requirement'
      | 'requirement-applicant'
      | 'stages-process'
      | 'review-offer'
      | 'invitation';
  };
  offer: {
    name: string;
    vacant: number;
    subjectHours?: number;
    inclusionLaw: string;
    description: string;
    levelResponsibility: string;
    benefits: number[] | null;
    laws: number[] | null;
    //step2
    educations: number[] | null;
    competences: number[] | null;
    certifications: number[] | null;
    licenseTypes: number[] | null;
    requirements: number[] | null;
    //step4
    requests: number[] | null;
    questions: number[] | null;
    //step5
    applicationStages: number[] | null;
    //
    languageLevels: any;
    initialDate: string;
    finishDate: string;
    knowledge: any;
    campusId: number | null;
    contractId: number | null;
    workingDayId: number | null;
    modalityId: number | null;
    classTypeId: number | null;
    specialtyAreaId: number | null;
    subjectId: number | null;
    schoolId: number | null;
    draftFlowId: number | null;
    id: number;
    salaryMin: number | null;
    salaryMax: number | null;
    guestQueue?: OfferGuest[];
    updatedAt: Date;
    type?: OfferType;
    school: {
      id: number;
      name: string;
    };
    career: {
      id: number;
      name: string;
    };
    specialtyArea: {
      id: number;
      name: string;
    };
    subject: {
      schoolId: number;
      id: number;
      name: string;
    };
    period: {
      id: number;
      name: string;
    };
    campus: {
      id: number;
      name: string;
    };
    workingDay: {
      id: number;
      name: string;
    };
    modality: {
      id: number;
      name: string;
    };
    contract: {
      id: number;
      name: string;
    };
  };
}
