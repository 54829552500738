import { memo } from 'react';
import { Container } from 'reactstrap';
import SectionsFilterLoader from './parts/Loaders/SectionsFilterLoader';
import SectionsLoader from './parts/Loaders/SectionsLoader';
import SectionFilters from './parts/SectionsFilter';
import SectionsTable from './parts/SectionsTable';
function Sections() {
  return (
    <Container fluid className="mt-4">
      <SectionsFilterLoader>
        <SectionsLoader filterComponent={<SectionFilters />}>
          <SectionsTable />
        </SectionsLoader>
      </SectionsFilterLoader>
    </Container>
  );
}
export default memo(Sections);
