import { useCallback, useMemo } from 'react';

// Components
import RequiredFilesTable from '../../required-files/parts/RequiredFilesTable';
import { Button, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import { useHistory, useLocation, useParams } from 'react-router-dom';

// I18n
import { useTranslation } from 'react-i18next';
import PostulantDetails from './parts/PostulantDetails';
import { format } from 'rut.js';

type RequiredFilesScreenParams = {
  userId: string;
};

// Render
const RequiredFilesScreen = () => {
  const { t } = useTranslation();

  const { userId } = useParams<RequiredFilesScreenParams>();
  const location = useLocation<{
    user: { runOrPassport: string; name: string };
  }>();

  const user = useMemo(() => location.state?.user || undefined, [location]);

  const isMobile = useMobile();
  const history = useHistory();

  const goBackToList = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
      <Row>
        <Col className="mb-1" xs={12} sm={6}>
          <Button
            className="mb-3"
            icon="back"
            onClick={goBackToList}
            outlined
            rounded
            size="sm"
            text={t('views.hiring.requirements.goBackToList')}
          />
        </Col>
        <Col className="mb-1" xs={12} sm={6}>
          <div className="w-100 d-flex justify-content-end">
            <PostulantDetails
              id={format(user?.runOrPassport)}
              names={user?.name}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col xs={12}>
          <div className="w-100">
            <span className="d-block w-100 text-primary fs-22 fw-700">
              {t('views.hiring.requirements.title')}
            </span>
            <span className="d-block w-100 text-light fs-16 fw-400">
              {t('views.hiring.requirements.description')}
            </span>
          </div>
        </Col>
      </Row>
      <RequiredFilesTable userId={userId} />
    </Card>
  );
};

export default RequiredFilesScreen;
