import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInformation } from '../../../offer-detail/types';

export interface Props {
  information: BaseInformation;
}

export default function Box({ information }: Props) {
  const { t } = useTranslation();

  const columns = useMemo<DetailBoxColumn[]>(() => {
    let cols = [];
    cols.push({
      title: t(`common.terms.campus`),
      body: information.campus.toUpperCase(),
      size: { xs: 'auto' },
    });

    cols.push({
      title: t(`common.terms.school`),
      body: information.school.toUpperCase(),
      size: { xs: 'auto' },
    });

    cols.push({
      title: t(`common.terms.career`),
      body: information.career.toUpperCase(),
      size: { xs: 'auto' },
    });

    cols.push({
      title: t(`common.terms.subject`),
      body: information.subject.toUpperCase(),
      size: { xs: 'auto' },
    });

    return cols;
  }, [
    information.campus,
    information.career,
    information.school,
    information.subject,
    t,
  ]);

  return <DetailBox columns={columns} />;
}
