import { Base } from './GenericFormTypes';

export enum RequestEnum {
  AvailabilityTime = 'availability-time',
  AvailabilityDate = 'availability-date',
  Attachment = 'attachment',
  Pretension = 'pretension',
  AdministrativeInabilities = 'administrative-inabilities',
  InstitutionalInabilities = 'institutional-inabilities',
}

export interface Education extends Base {}
export interface Competence extends Base {}
export interface Certification extends Base {}
export interface LicenseType extends Base {}
export interface Requirement extends Base {}
export interface LanguageLevel extends Base {}
export interface LanguageLevel extends Base {}
export interface Benefit extends Base {}
export interface Law extends Base {}
export interface ApplicationStage extends Base {}
export interface Request extends Base {
  shortName: RequestEnum;
}

export interface OfferReview {
  name: string;
  campus: string;
  career: string;
  workingDay: string;
  modality: string;
  period: string;
  subjectHours: number;
  educations: Education[];
  competencies: Competence[];
  certifications: Certification[];
  licenseTypes: LicenseType[];
  requirements: Requirement[];
  languageLevels: LanguageLevel[];
  benefits: Benefit[];
  requests: Request[];
  laws: Law[];
  applicationStages: ApplicationStage[];
  inclusionLaw: boolean;
  description: string;
  levelResponsibility: string;
  knowledge: string;
}
