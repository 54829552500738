import {
  Button,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Subject } from '../../type';
import { useSubjectFiltersLoader } from '../Loaders/SubjectFiltersLoader';
import { TEXTINPUT_MAXLENGTH } from '../../../../../config/constants';

interface Props {
  actions: {
    createSubject: (data: any) => Promise<void>;
    updateSubject: (params: { subjectId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Subject) => void;
}
interface FormData {
  name: string;
  career: SelectOptionType | null;
}
const optionFormat = (option: { id: number; name: string }) => ({
  label: option.name,
  value: option.id,
});

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const { data } = useSubjectFiltersLoader();
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Subject | null>(null);

    const careers = useMemo(
      () => data?.careers.map((c) => ({ label: c.name, value: c.id })) || [],
      [data?.careers],
    );

    useEffect(() => {
      if (formData) {
        reset({
          name: formData.name,
          career: formData.career ? optionFormat(formData.career) : null,
        });
      } else reset({ name: undefined, career: null });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row) {
        setFormData(row ?? null);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        name: values.name,
        careerId: parseInt(values.career?.value as string),
      };
      if (formData) {
        await actions.updateSubject({
          subjectId: formData.id,
          data,
        });
      } else {
        await actions.createSubject(data);
      }
      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            Ingresar nuevo módulo
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                  maxLength={TEXTINPUT_MAXLENGTH}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="career"
                  options={careers}
                  label={'Carrera'}
                  placeholder={'Carrera'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
