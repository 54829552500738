import request from '../../../api/request';
import {
  SectionFiltersQuery,
  SectionFiltersResponse,
  SectionsResponse,
} from './type';

const SECTION_MAINTAINER = {
  FORM_DATA: '/mantainer-portal/maintainers/sections/form-data',
  GET: '/mantainer-portal/maintainers/sections',
  POST: '/mantainer-portal/maintainers/sections',
  PUT: '/mantainer-portal/maintainers/sections',
  DELETE: (sectionId: number) =>
    `/mantainer-portal/maintainers/sections/${sectionId}`,
};

export function getSectionsList(params: SectionFiltersQuery) {
  return request<SectionsResponse>(SECTION_MAINTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}
export function getFormData() {
  return request<SectionFiltersResponse>(SECTION_MAINTAINER.FORM_DATA, {
    method: 'GET',
  });
}

export function createSection(data: any) {
  return request(SECTION_MAINTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateSection(id: number, data: any) {
  return request(`${SECTION_MAINTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteSection(sectionId: number) {
  return request(SECTION_MAINTAINER.DELETE(sectionId), {
    method: 'DELETE',
  });
}
