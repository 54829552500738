// Components
import OfferDetailSection from './parts/OfferDetailSection';
import VacanciesAndContractsSection from './parts/VacanciesAndContractsSection';
import SalaryRangeSection from './parts/SalaryRangeSection';
import OfferDescriptionSection from './parts/OfferDescriptionSection';
import LevelOfResponsibilitySection from './parts/LevelOfResponsibilitySection';
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import Loading from '../../../../components/info/Loading';

import { FormProvider, useForm } from 'react-hook-form';
import { Form, Row } from 'reactstrap';
import { useMobile } from '@octano/global-ui';

// Hooks
import useGetStepData from '../../hooks/useGetStepData';
import useGetCreationLists from '../../hooks/useGetCreationLists';
import { useCallback, useEffect, useRef, useState } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { createFillFormObject, generatePayload } from './utils';

// Types
import { OfferDetailsFormFields } from './types';
import { OfferType } from '../../../../types/jobOffer';
import { CreateOfferStepArea, OfferCreationStepProps } from '../../types';
import { CurrentStep } from '../../../../types/stepsTypes';
import { CheckNames } from '../../../../types/GenericFormTypes';
import ChecklistSection2 from '../../../../components/steps/ChecklistSection2';

type OfferDetailsStepProps = OfferCreationStepProps & {
  scope: OfferType;
};

const keyPrefix = 'views.addOffer.offerDetails';
const area = CreateOfferStepArea.OfferDetails;

// Render
const OfferDetailsStep = ({
  scope,
  backRequireConfimation = false,
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: OfferDetailsStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { t: validationText } = useTranslation('translation', {
    keyPrefix: 'common.validations',
  });
  const isMobile = useMobile();
  const stepWrapperRef = useRef<StepsWrappeMethods>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const { data: offerData, isFetching: isFetchingOffer } =
    useGetStepData<CurrentStep>(offerDraftId);
  const { lists, isLoading } = useGetCreationLists();

  const methods = useForm<OfferDetailsFormFields>();
  const { reset, getValues, setError } = methods;

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (onSubmit) {
        const values = getValues();

        if (
          (!values.subjects?.value || !values.specialtyArea?.value) &&
          !next
        ) {
          const validation = {
            type: 'custom',
            message: validationText('required'),
          };

          ['subjects', 'specialtyArea'].forEach((field) => {
            setError(field as keyof OfferDetailsFormFields, validation);
          });
          return;
        }

        await onSubmit({
          area,
          nextStep,
          payload: generatePayload(values, offerData?.offer?.type || scope),
          next,
          successMsg: t('onSave.success'),
          failedMsg: t('onSave.error'),
        });
      }
    },
    [
      getValues,
      nextStep,
      offerData?.offer?.type,
      onSubmit,
      scope,
      setError,
      t,
      validationText,
    ],
  );

  useEffect(() => {
    if (offerData?.offer) {
      const filler = createFillFormObject(offerData?.offer, lists);
      reset(filler);
      setIsMounted(true);
    } else if (!offerDraftId) {
      reset({ benefits: lists.benefits, laws: lists.laws });
      setIsMounted(true);
    }
  }, [lists, offerData?.offer, offerDraftId, reset]);

  if (isFetchingOffer || isLoading) {
    return <Loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods?.handleSubmit(handleSave)}>
          <StepsWrapper
            keyPrefix={keyPrefix}
            ref={stepWrapperRef}
            offerDraftId={offerDraftId}
            onSave={onSave}
            prevStep={prevStep}
            backRequireConfimation={backRequireConfimation}
            buttonText={t('goBackToList')}
          >
            <Row>
              <OfferDetailSection lists={lists} />
              {!!isMounted && <VacanciesAndContractsSection />}
              <ChecklistSection2
                name={CheckNames.Laws}
                title={t(`lawsSection.title`)}
                subtitle={t(`lawsSection.subtitle`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={false}
              />
              <SalaryRangeSection />
              <OfferDescriptionSection />
              <LevelOfResponsibilitySection />
              <ChecklistSection2
                name={CheckNames.Benefits}
                title={t('benefitsSection.title')}
                subtitle={t(`benefitsSection.subtitle`)}
                hasIcon={true}
                hasDescription={true}
                canAddItems={false}
              />
              <BottomButtons
                buttons={[
                  {
                    outlined: true,
                    color: 'danger',
                    text: t('actions.cancel'),
                    onClick: () => stepWrapperRef?.current?.prev(prevStep),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                  },
                  {
                    outlined: true,
                    text: t('actions.save'),
                    onClick: () => handleSave({ next: false }),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                    disabled: isSubmitting,
                  },
                  {
                    type: 'submit',
                    text: t('actions.next'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
                hint={t('infoOnClickNext')}
              />
            </Row>
          </StepsWrapper>
        </Form>
      </FormProvider>
    </>
  );
};

export default OfferDetailsStep;
