interface Props {
  color?: string;
  text?: string;
}

export default function Badge({ color = 'white', text = 'Leyenda' }: Props) {
  return (
    <div
      className="mx-auto"
      style={{
        backgroundColor: color,
        width: 'max-content',
        borderRadius: '30px',
        minWidth: '108px',
      }}
    >
      <p className="mb-0 text-center px-3 py-2">{text}</p>
    </div>
  );
}
