import { SidenavCategoryType, SidenavLinkType } from '@octano/global-ui';
import { PermissionName } from '../types/auth';

export interface WithPermissions {
  requiredPermissions?: PermissionName[];

  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType & WithPermissions;

export type SubLinkWithPermissions = SidenavCategoryType['links'][number] &
  WithPermissions;

export type CategoryWithPermissions = Omit<SidenavCategoryType, 'links'> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinksWithPermissions = (
  | LinkWithPermissions
  | CategoryWithPermissions
)[];

export const SIDENAV_LINKS: SidenavLinksWithPermissions = [
  //Ejemplos de rutas
  /*{
    path: "/module1/analyst-route",
    keyName: "analystRoute",
    icon: "academic_offer",
  },
  {
    path: "/module2/admin-route",
    keyName: "adminRoute",
    icon: "admision",

    // Lista de permisos que se requieren. Se evaluan mediante OR o AND dependiendo de la prop allPermissionsRequired
    requiredPermissions: [
      PermissionName.exampleEdit,
      PermissionName.exampleDelete,
    ],

    // Determina si la lista de permisos se evalua como OR o AND (todos requeridos o al menos 1 requerido)
    allPermisionsRequired: false,
  },*/
  {
    name: 'maintainer',
    icon: 'subjects',
    allPermisionsRequired: true,
    requiredPermissions: [PermissionName.maintainers],
    links: [
      {
        path: '/maintainer/sections',
        name: 'sections',
      },
      {
        path: '/maintainer/subjects',
        name: 'subjects',
      },
    ],
  },
  {
    path: '/offer-maintainer/published-offers',
    name: 'offerRoute',
    icon: 'offers',
    requiredPermissions: [PermissionName.readOfferMaintainer],
    allPermisionsRequired: false,
  },
  {
    path: '/offer-maintainer/draft-offers',
    name: 'draftOfferRoute',
    icon: 'certificates_requests',
    requiredPermissions: [PermissionName.readOfferDraftMaintainer],
    allPermisionsRequired: false,
  },
  {
    path: '/recruitment/offer-list',
    name: 'selectionAndRecruitment',
    icon: 'identification',
    requiredPermissions: [PermissionName.readRecruitment],
    allPermisionsRequired: false,
  },
  {
    path: '/academic-authorization/postulants-authorization',
    name: 'academicAuthorization',
    icon: 'success',
    requiredPermissions: [PermissionName.readAcademicAuthorization],
    allPermisionsRequired: false,
  },
  {
    name: 'hiring',
    icon: 'offers',
    requiredPermissions: [PermissionName.readHiring],
    allPermisionsRequired: false,
    links: [
      {
        path: '/hiring/authorized-postulants',
        name: 'hiring',
        requiredPermissions: [PermissionName.readHiring],
        allPermisionsRequired: false,
      },
      {
        path: '/hiring/history',
        name: 'hiringHistory',
        requiredPermissions: [PermissionName.readHiring],
        allPermisionsRequired: false,
      },
    ],
  },
  {
    name: 'settings',
    icon: 'settings',
    requiredPermissions: [PermissionName.readSettings],
    allPermisionsRequired: false,
    links: [
      {
        path: '/settings/contract-viewfinders',
        name: 'contractViewfindersSettings',
        requiredPermissions: [PermissionName.readSettings],
        allPermisionsRequired: false,
      },
      {
        path: '/settings/rates',
        name: 'priceRateSettings',
        requiredPermissions: [PermissionName.readSettings],
        allPermisionsRequired: false,
      },
      {
        path: '/settings/reports',
        name: 'reports',
        requiredPermissions: [PermissionName.readReports],
        allPermisionsRequired: false,
      },
    ],
  },
];
