// Components
import HeaderSection from '../../../components/steps/HeaderSection';
import { Row } from 'reactstrap';
import { Dialog, showDialogConfirm } from '@octano/global-ui';

// Hooks
import {
  useCallback,
  ReactNode,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
} from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Redux
import { useUpdateDraftStepMutation } from '../api';
import { useHistory } from 'react-router-dom';
import { CreateOfferStepCode } from '../types';

// Types
import { OfferCreationStepProps } from '../types';

export type StepsWrappeMethods = {
  confirmedBack: (step?: CreateOfferStepCode) => void;
  prev: (step?: CreateOfferStepCode) => void;
};
type StepsWrapperProps = OfferCreationStepProps & {
  keyPrefix: string;
  children: ReactNode;
  description?: string;
  buttonText?: string;
  backRequireConfimation?: boolean;
};

// Render
const StepsWrapper = (
  {
    keyPrefix,
    offerDraftId = 0,
    description,
    buttonText,
    children,
    prevStep,
    backRequireConfimation = false,
    onSave,
  }: StepsWrapperProps,
  ref: Ref<StepsWrappeMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const history = useHistory();

  const [loadingBack, setLoadingBack] = useState<boolean>(false);
  const [updateStep] = useUpdateDraftStepMutation();

  const handlePrev = useCallback(
    async (step?: CreateOfferStepCode) => {
      try {
        if (prevStep) {
          if (onSave) {
            if (loadingBack) {
              return;
            }
            setLoadingBack(true);
            await updateStep({
              id: offerDraftId,
              step: step || prevStep,
            })?.unwrap();
            await onSave();
            setLoadingBack(false);
          }
        } else {
          history.push('/offer-maintainer/draft-offers');
        }
      } catch (_error) {
        setLoadingBack(false);
        console.error('_error', _error);
      }
    },
    [prevStep, onSave, loadingBack, updateStep, offerDraftId, history],
  );

  const handleConfirmedBack = useCallback(
    (step?: CreateOfferStepCode) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'primary',
        },
        title: t('backModal.title'),
        subtitle: t('backModal.subtitle'),
        btnClose: {
          text: t('backModal.cancel'),
          onClose: () => {},
        },
        btnConfirm: {
          text: t('backModal.confirm'),
          onConfirm: () => handlePrev(step),
        },
      });
    },
    [t, handlePrev],
  );

  const handleBack = useCallback(() => {
    if (backRequireConfimation) {
      handleConfirmedBack();
    } else {
      handlePrev();
    }
  }, [backRequireConfimation, handleConfirmedBack, handlePrev]);

  useImperativeHandle(ref, () => ({
    prev: handlePrev,
    confirmedBack: handleConfirmedBack,
  }));

  return (
    <>
      <Row>
        <HeaderSection
          loadingBack={loadingBack}
          description={description}
          onBack={handleBack}
          buttonText={buttonText}
        />
      </Row>
      {children}
      <Dialog />
    </>
  );
};

export default forwardRef(StepsWrapper);
