import {
  Dialog,
  DisplayInfo,
  IconColorType,
  OutlinedSelectOptionType,
  Table,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import {
  getPosibleOfferStateList,
  updateOfferState,
} from '../../../api/requests/offer';
import DisplayError from '../../../components/info/DisplayError';
import { OfferDetail, UpdateOfferStateType } from '../../../types/jobOffer';
import { useOfferColumns } from './hooks/useOfferColumns';
import { useCloneOfferMutation, useGetOffersDataQuery } from '../api';
import { useHistory } from 'react-router-dom';
import PublishedOffersFilter, { OnFilterParams } from './parts/Filters';

const ITEMS_PER_PAGE = 10;

interface ColorByState {
  Finalizada: IconColorType;
  Cerrada: IconColorType;
  Publicada: IconColorType;
  Pausada: IconColorType;
  EnProceso: IconColorType;
  [key: string]: any;
}

export default function OffersMaintainer() {
  const { t } = useTranslation();
  const history = useHistory();

  const prefix = 'views.offersMaintainer';

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<OnFilterParams>({});

  const [availableOptions, setAvailableOptions] = useState<
    OutlinedSelectOptionType[]
  >([]);

  const [cloneOffer] = useCloneOfferMutation();

  const getStateList = useCallback(async () => {
    const { data, error } = await getPosibleOfferStateList();
    if (error) {
      console.log(error);
    }
    if (data) {
      const colors: ColorByState = {
        Finalizada: 'success',
        Cerrada: 'danger',
        Publicada: 'primary',
        Pausada: 'warning',
        EnProceso: 'info',
      };
      const optionsWithIcon = data?.map((state) => ({
        icon: { name: state.icon, color: colors[state.name.replace(' ', '')] },
        label: state.name,
        value: state.id,
      }));
      setAvailableOptions(optionsWithIcon);
    }
  }, []);

  const { data, isFetching, error, refetch } = useGetOffersDataQuery(
    {
      ...filters,
      page: currentPage - 1,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const dataTable = useMemo(() => data?.data || [], [data?.data]);
  const totalOffers = useMemo(() => data?.total || 0, [data?.total]);
  const totalPages = useMemo(() => data?.totalPages || 0, [data?.totalPages]);

  const tryUpdateOfferState = useCallback(
    async (newState: UpdateOfferStateType, offerId: number) => {
      const { data, error } = await updateOfferState(offerId, newState);
      if (error) {
        console.log(error);
      }
      if (data) {
        refetch();
      }
    },
    [refetch],
  );

  const handleClone = useCallback(
    async (offer: OfferDetail) => {
      try {
        await cloneOffer(offer?.id)?.unwrap();
        history.push('/offer-maintainer/draft-offers');
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.cloneModal.success`),
        });
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.cloneModal.failed`),
        });
      }
    },
    [cloneOffer, history, t],
  );

  const tryCloneOffer = useCallback(
    async (offer: OfferDetail) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'primary',
        },
        title: t(`${prefix}.cloneModal.title`),
        subtitle: t(`${prefix}.cloneModal.subtitle`),
        btnClose: {
          text: t(`${prefix}.cloneModal.cancel`),
          onClose: () => {},
        },
        btnConfirm: {
          text: t(`${prefix}.cloneModal.confirm`),
          onConfirm: () => handleClone(offer),
        },
      });
    },
    [t, handleClone],
  );

  const handleFilter = useCallback(
    (params: OnFilterParams) => {
      setCurrentPage(1);
      setFilters(params);
    },
    [setFilters],
  );
  const columns = useOfferColumns(
    availableOptions,
    tryUpdateOfferState,
    tryCloneOffer,
  );

  useEffect(() => {
    getStateList();
  }, [getStateList]);

  return (
    <>
      <Row className="py-5">
        <Col xs={12}>
          <Card className="p-4">
            <Col xs={12} className="pt-4">
              <PublishedOffersFilter onFilter={handleFilter} />

              <Table
                columns={columns}
                data={dataTable}
                isLoadingResults={isFetching}
                loadingView={{
                  title: t(`common.loading.title`),
                  subtitle: t(`common.loading.subtitle`),
                }}
                noResultsText={
                  (error as any) ? (
                    <DisplayError typeError={(error as any)?.status} />
                  ) : (
                    <div className="d-flex flex-column align-items-center no-result">
                      <DisplayInfo
                        title={t(`${prefix}.emptyOffersTitle`)}
                        textBody={t(`${prefix}.emptyOffersSubTitle`)}
                      />
                    </div>
                  )
                }
                pagination={{
                  currentPage: currentPage,
                  itemsPerPage: ITEMS_PER_PAGE,
                  onChangePage: (page) => setCurrentPage(page),
                  totalItems: totalOffers,
                  totalPages: totalPages,
                }}
              />
            </Col>
          </Card>
        </Col>
      </Row>
      <Dialog />
    </>
  );
}
