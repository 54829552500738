import { CurrentStep } from '../offer-detail/types';

export enum PostulationStageStep {
  // paso pendiente de revisión
  Pending = 'pending',
  // paso aprobado
  Approved = 'approved',
  // paso no aprobadio
  Rejected = 'rejected',
}

export interface Postulation {
  id: number;
  currentStep: CurrentStep;
  userId: number;
  offerId: number;
  useApplicationCurriculum: boolean;
  createdAt: string;
  updatedAt: string;
  postulationApplicationStages?: PostulationApplicationStage[];
}

export interface ApplicationStage {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  order: number;
  postulationApplicationStages?: PostulationApplicationStage[];
}

export interface PostulationApplicationStage {
  id?: number;
  postulantApplicationStageId: number;
  postulationId: number;
  applicationStageId: number;
  status: PostulationStageStep;
  calification?: number;
  observation: string | null;
  createdAt: string;
  updatedAt: string;
  postulation?: Postulation;
  applicationStage?: ApplicationStage;
  name?: string;
  order: number;
  hasCalification: boolean;
}

export interface SavePostulationApplicationStagePayload {
  applicationStageId: number | string,
  observation: string | null,
  calification?: number,
  status: PostulationStageStep,
  postulantApplicationStageId: number | string,
}

export interface SavePostulationApplicationStageParams {
  id: number | string;
  payload: SavePostulationApplicationStagePayload;
}

export interface SavePostulationPayload {
  status: CurrentStep;
}

export interface SavePostulationParams {
  id: number | string;
  payload: SavePostulationPayload;
}