// Components
import PostulantsAuthorizationFilters, {
  OnFilterParams as Filters,
} from './parts/Filters';
import DisplayError from '../../../components/info/DisplayError';
import {
  Dialog,
  DisplayInfo,
  Table,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import UsePostulantsAuthorizationColumns from './hooks/usePostulantsAuthorizationColumns';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import {
  useGetPostulantsToAuthorizeQuery,
  useUpdatePostulantPostulationsMutation,
} from '../api';
import { AuthorizationStatus } from '../types';

// Types
const ITEMS_PER_PAGE = 10;
const keyPrefix = 'views.academicAuthorization.postulantsAuthorization';

// Render
export default function Postulants() {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  const {
    data: postulantsData,
    isFetching,
    error,
    refetch,
  } = useGetPostulantsToAuthorizeQuery(
    {
      ...filters,
      page: currentPage - 1,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [updatePostulationsStatus, { isLoading: isSaving }] =
    useUpdatePostulantPostulationsMutation();

  const postulants = useMemo(
    () => postulantsData?.data || [],
    [postulantsData?.data],
  );
  const totalPostulants = useMemo(
    () => postulantsData?.total || 0,
    [postulantsData?.total],
  );
  const totalPages = useMemo(
    () => postulantsData?.totalPages || 0,
    [postulantsData?.totalPages],
  );

  const handleFilter = useCallback(
    (params: Filters) => {
      setCurrentPage(1);
      setFilters(params);
    },
    [setFilters],
  );

  const postulationAuthorizeAll = useCallback(
    async (userId: string | number, postulationIds: (string | number)[]) => {
      try {
        await updatePostulationsStatus({
          userId,
          postulationIds,
          authorizationStatus: AuthorizationStatus.AuthorizedForHiring,
        });
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${keyPrefix}.authorizeAllModal.success`),
        });
        refetch();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.authorizeAllModal.failed`),
        });
      }
    },
    [refetch, t, updatePostulationsStatus],
  );
  const postulationAuthorize = useCallback(
    async (userId: string | number, postulationId: string | number) => {
      try {
        await updatePostulationsStatus({
          userId,
          postulationIds: [postulationId],
          authorizationStatus: AuthorizationStatus.AuthorizedForHiring,
        });
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${keyPrefix}.authorizeModal.success`),
        });
        refetch();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.authorizeModal.failed`),
        });
      }
    },
    [refetch, t, updatePostulationsStatus],
  );
  const postulationReject = useCallback(
    async (userId: string | number, postulationId: string | number) => {
      try {
        await updatePostulationsStatus({
          userId,
          postulationIds: [postulationId],
          authorizationStatus: AuthorizationStatus.NotAuthorizedForHiring,
        });
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${keyPrefix}.rejectModal.success`),
        });
        refetch();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.rejectModal.failed`),
        });
      }
    },
    [refetch, t, updatePostulationsStatus],
  );

  const requestAuthorizeAll = useCallback(
    (userId: string | number, postulationsIds: (string | number)[]) => {
      showDialogConfirm({
        title: t(`${keyPrefix}.authorizeAllModal.title`),
        subtitle: t(`${keyPrefix}.authorizeAllModal.subtitle`),
        btnClose: {
          text: t(`common.actions.cancel`),
        },
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => postulationAuthorizeAll(userId, postulationsIds),
        },
      });
    },
    [postulationAuthorizeAll, t],
  );
  const requestAuthorize = useCallback(
    (userId: string | number, postulationId: string | number) => {
      showDialogConfirm({
        title: t(`${keyPrefix}.authorizeModal.title`),
        subtitle: t(`${keyPrefix}.authorizeModal.subtitle`),
        btnClose: {
          text: t(`common.actions.cancel`),
        },
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => postulationAuthorize(userId, postulationId),
        },
      });
    },
    [postulationAuthorize, t],
  );
  const requestReject = useCallback(
    (userId: string | number, postulationId: string | number) => {
      showDialogConfirm({
        title: t(`${keyPrefix}.rejectModal.title`),
        subtitle: t(`${keyPrefix}.rejectModal.subtitle`),
        btnClose: {
          text: t(`common.actions.cancel`),
        },
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => postulationReject(userId, postulationId),
        },
      });
    },
    [postulationReject, t],
  );

  const columns = UsePostulantsAuthorizationColumns({
    filters,
    onPressAuthorize: requestAuthorize,
    onPressReject: requestReject,
    onPressAuthorizeAll: requestAuthorizeAll,
  });

  return (
    <>
      <Card className="p-4">
        <Row>
          <Col className="mb-4" xs={12}>
            <p className="fs-16">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>
        <PostulantsAuthorizationFilters onFilter={handleFilter} />
        <Row className="mt-3">
          <Col xs={12} className="pt-1 mb-5 g-table-hidden-x">
            <Table
              columns={columns}
              data={!isFetching && !isSaving ? postulants : []}
              isLoadingResults={isFetching || isSaving}
              loadingView={{
                title: t(`common.loading.title`),
                subtitle: t(`common.loading.subtitle`),
              }}
              noResultsText={
                (error as any) ? (
                  <DisplayError typeError={(error as any)?.status} />
                ) : (
                  <div className="d-flex flex-column align-items-center no-result">
                    <DisplayInfo
                      title={t(`${keyPrefix}.empty.title`)}
                      textBody={t(`${keyPrefix}.empty.body`)}
                    />
                  </div>
                )
              }
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                onChangePage: (page: number) => setCurrentPage(page),
                totalItems: totalPostulants,
                totalPages: totalPages,
              }}
            />
          </Col>
        </Row>
      </Card>
      <Dialog />
    </>
  );
}
