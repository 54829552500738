// Components
import { Card, Row, Col } from 'reactstrap';
import { Button, TextInput, addToast } from '@octano/global-ui';

// Hooks
import useUserState from '../../../hooks/useUserState';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// Utils
import { isUserAuthorizedTo } from '../../../utils/auth';
import { formatToOnlyPositiveScaledIntegers } from '../../../utils/formatter';
import { useCallback, useEffect, useMemo } from 'react';
import {
  useGetContractViewfinderQuery,
  useSaveRateSettingsMutation,
} from '../api';

// Types
import { PermissionName } from '../../../types/auth';

type FormFields = {
  rate: string;
};
const keyPrefix = 'views.settings.rates';

// Render
const RatesSettingsScreen = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();

  const { data = [], isFetching, refetch } = useGetContractViewfinderQuery();
  const [saveRate, { isLoading: isSaving }] = useSaveRateSettingsMutation();

  const { control, handleSubmit, watch, setValue, reset } = useForm<FormFields>(
    {
      defaultValues: {
        rate: formatToOnlyPositiveScaledIntegers(`${0}`),
      },
    },
  );

  const updateSettings = useMemo(
    () =>
      isUserAuthorizedTo(
        userData?.permissions || [],
        [PermissionName.updateSettings],
        true,
      ),
    [userData?.permissions],
  );

  const [rate] = watch(['rate']);

  const floatRate = useMemo(() => {
    let v = parseInt((rate?.trim() || '0').replace(/\D/g, '').trim(), 10) || 0;
    if (v <= 0) {
      return 0;
    }
    return v / 100;
  }, [rate]);

  const isSubmitEnabled = useMemo(() => {
    return floatRate > 0 && !isFetching && !isSaving;
  }, [floatRate, isFetching, isSaving]);

  const handleSave = useCallback(async () => {
    try {
      await saveRate(floatRate)?.unwrap();
      await refetch()?.unwrap();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${keyPrefix}.success`),
      });
    } catch (_error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${keyPrefix}.failed`),
      });
    }
  }, [floatRate, refetch, saveRate, t]);

  useEffect(() => {
    if (data.length) {
      reset({
        rate: formatToOnlyPositiveScaledIntegers(
          `${parseFloat(`${data[0].campusContract?.hourValue || 0}`) * 100}`,
        ),
      });
    }
  }, [data, reset, setValue]);

  return (
    <>
      <Card className="p-4">
        <Row className="mb-3">
          <Col xs={12} className="mb-3">
            <h3 className="fs-22 fw-700 text-primary text-uppercase">
              {t(`${keyPrefix}.title`)}
            </h3>
            <p className="fs-16 m-0">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} className="mb-3">
            <div className="w-100" style={{ maxWidth: 530 }}>
              <span className="w-100 d-block fs-16 mb-2 fw-700 text-primary text-uppercase">
                {t(`${keyPrefix}.rateValueCurrency`, {
                  currency: 'Pesos Chilenos',
                })}
              </span>
              <TextInput
                control={control}
                name="rate"
                disabled={isFetching || isSaving}
                formatter={formatToOnlyPositiveScaledIntegers}
                label={t(`${keyPrefix}.rateValuePlaceholder`)}
              />
            </div>
          </Col>
        </Row>
        {updateSettings && (
          <Row>
            <Col xs={12}>
              <div className="d-flex w-100 justify-content-end align-items-center">
                <Button
                  text={t(`${keyPrefix}.save`)}
                  disabled={!isSubmitEnabled}
                  loading={isFetching || isSaving}
                  onClick={handleSubmit(handleSave)}
                />
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default RatesSettingsScreen;
