import { Button, DisplayInfo, Table } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import DisplayError from '../../components/info/DisplayError';
import { OfferType } from '../../types/jobOffer';
import { useGetOffersQuery } from './api';
import { useOfferDraftColumns } from './hooks/useOfferDraftColumns';
import DraftOffersFilter, { OnFilterParams } from './parts/Filters';

const ITEMS_PER_PAGE = 10;

export default function DraftMaintainer() {
  const history = useHistory();
  const { t } = useTranslation();

  const prefix = 'views.draftMaintainer';

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<OnFilterParams>({});

  const offersData = useGetOffersQuery(
    {
      ...filters,
      itemsPerPage: ITEMS_PER_PAGE,
      page: currentPage - 1,
    },
    { refetchOnMountOrArgChange: true },
  );

  const totalPages = useMemo(
    () => offersData?.data?.totalPages || 0,
    [offersData?.data?.totalPages],
  );

  const totalOffers = useMemo(
    () => offersData?.data?.total || 0,
    [offersData?.data?.total],
  );

  const columns = useOfferDraftColumns();

  const goToCreateOffer = useCallback(
    async (scope: OfferType) => {
      history.push({
        pathname: `/offer-maintainer/create-offer`,
        search: `?scope=${scope}`,
      });
    },
    [history],
  );

  const handleFilter = useCallback(
    (params: OnFilterParams) => {
      setCurrentPage(1);
      setFilters(params);
    },
    [setFilters],
  );

  return (
    <Row className="py-5">
      <Col xs={12}>
        <Card className="p-4">
          <Col xs={12}>
            <DraftOffersFilter onFilter={handleFilter} />
          </Col>
          <Col xs={12}>
            <Button
              text={t(`${prefix}.actions.createPublicOffer`)}
              icon="plus"
              onClick={() => goToCreateOffer(OfferType.Public)}
              style={{ minHeight: '42px', fontSize: '0rem' }}
            />
            <Button
              text={t(`${prefix}.actions.createPrivateOffer`)}
              icon="plus"
              className="ml-2"
              outlined
              onClick={() => goToCreateOffer(OfferType.Private)}
              style={{ minHeight: '42px', fontSize: '0rem' }}
            />
          </Col>
          <Col xs={12} className="pt-4">
            <Table
              columns={columns}
              data={offersData?.data?.data || []}
              isLoadingResults={!!offersData?.isFetching}
              loadingView={{
                title: t(`common.loading.title`),
                subtitle: t(`common.loading.subtitle`),
              }}
              noResultsText={
                offersData?.error ? (
                  <DisplayError
                    typeError={(offersData?.error as any)?.status}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center no-result">
                    <DisplayInfo
                      title={t(`${prefix}.emptyOffersTitle`)}
                      textBody={t(`${prefix}.emptyOffersSubTitle`)}
                    />
                  </div>
                )
              }
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                onChangePage: (page) => setCurrentPage(page),
                totalItems: totalOffers,
                totalPages: totalPages,
              }}
            />
          </Col>
        </Card>
      </Col>
    </Row>
  );
}
