import { useCallback, useMemo } from 'react';

import { Button, ColumnTable, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { RequiredFile } from '../types';

const keyPrefix = 'views.hiring.requirements';

type UseRequirementsColumnsProps = {
  onPressDestroy?: (id: number) => void;
  onPressDownload?: (requiredFile: RequiredFile) => void;
  onPressUpload?: (requiredFile: RequiredFile) => void;
};

export default function useRequirementsColumns({
  onPressDownload,
  onPressUpload,
}: UseRequirementsColumnsProps) {
  const { t } = useTranslation('translation', { keyPrefix });

  const openUpload = useCallback(
    (props: RequiredFile) => {
      onPressUpload && onPressUpload(props);
    },
    [onPressUpload],
  );

  const handleDownload = useCallback(
    (props: RequiredFile) => {
      onPressDownload && onPressDownload(props);
    },
    [onPressDownload],
  );

  const columns = useMemo<ColumnTable<RequiredFile>[]>(() => {
    return [
      {
        columnName: 'displayName',
        headerText: t('documentType'),
        tdClassName: 'text-left',
        thClassName: 'text-left',
        cellFormat: ({ value }) => value,
      },
      {
        columnName: 'name',
        headerText: t('documentName'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) =>
          row?.url ? `${row?.name}.${row?.extension}` : '',
      },
      {
        columnName: 'url',
        headerText: t('documentOptions'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => {
          return (
            <div>
              <span className="mx-2" onClick={() => handleDownload(row)}>
                <Icon
                  name="download"
                  color={value?.trim() ? 'primary' : 'disabled'}
                  size={18}
                />
              </span>
            </div>
          );
        },
      },
      {
        columnName: 'createdAt',
        headerText: t('documentDate'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY') : '',
      },
      {
        columnName: 'action',
        headerText: t('documentUpload'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '200px',
        cellFormat: ({ row }) => {
          return (
            <div>
              <Button
                text={t('upload')}
                icon="upload"
                size="md"
                style={{ minWidth: 170 }}
                outlined
                onClick={() => openUpload(row)}
              />
            </div>
          );
        },
      },
    ];
  }, [t, handleDownload, openUpload]);

  return columns;
}
