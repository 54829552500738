import { IconNameType } from '@octano/global-ui';

/**
 * Las respuesta de la data que llena los formularios tienen nombres y tipos
 * comunes, por eso se define una interfaz generica para este propósito.
 * Esta interfaz puede ser utilizada directamente en los input de tipo check o input
 * de texto, mas nó en los selectores
 */
export interface Base {
  id?: number;
  name: string;
  icon?: IconNameType;
  description?: string;
  isMark?: boolean; // cuando se agrega desde el modal se da check automático
  nameInForm?: string;
}

export enum CheckNames {
  Benefits = 'benefits',
  Laws = 'laws',
  Educations = 'educations',
  Competences = 'competences',
  Certifications = 'certifications',
  LicenseTypes = 'licenseTypes',
  Requirements = 'requirements',
  Requests = 'requests',
  Questions = 'questions',
  ApplicationStages = 'applicationStages',
}

export type CheckNameType = `${CheckNames}`;
