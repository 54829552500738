// Components
import { Row, Col } from 'reactstrap';
import { TextInput } from '@octano/global-ui';

// I18n
import { useTranslation } from 'react-i18next';

// Types
const keyPrefix = 'views.academicAuthorization.postulantDetails';

interface Props {
  id?: string;
  names?: string;
  surnames?: string;
}

// Render
const PostulantDetails = ({ id, names, surnames }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Row className="mb-3">
      <Col xs={12} className="mb-3">
        <p className="fs-20 fw-600 text-primary text-uppercase">
          {t('postulantData')}
        </p>
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          name="rut"
          label={t('id')}
          value={id}
          disabled={true}
          onChange={() => {}}
        />
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          name="names"
          label={t('names')}
          value={names}
          disabled={true}
          onChange={() => {}}
        />
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          name="surnames"
          label={t('surnames')}
          value={surnames}
          disabled={true}
          onChange={() => {}}
        />
      </Col>
    </Row>
  );
};

export default PostulantDetails;
