import { useCallback, useMemo } from 'react';

// Components
import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

// Hooks
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { Base } from '../../../types/GenericFormTypes';
import { useGetFiltersFormQuery } from '../api';
import { GetDraftOfferFilters } from '../types';
const keyPrefix = 'views.academicAuthorization.postulantsAuthorization';

export type FilterFields = {
  offerName: string | null;
  campusId: SelectOptionType | null;
  careerId: SelectOptionType | null;
  workingDayId: SelectOptionType | null;
};

export type OnFilterParams = GetDraftOfferFilters;

export type FilterFieldsSubmit = {
  offerName: string | null;
  campusId: { value: string };
  careerId: { value: string };
  workingDayId: { value: string };
};

type PostulantsAuthorizationFiltersProps = {
  onFilter?: (params: OnFilterParams) => void;
};

// Render
const DraftOffersFilter = ({
  onFilter,
}: PostulantsAuthorizationFiltersProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { control, handleSubmit, reset } = useForm<FilterFields>({
    defaultValues: {
      offerName: null,
      campusId: null,
      careerId: null,
      workingDayId: null,
    },
  });

  const { data } = useGetFiltersFormQuery();

  const campuses = useMemo<Base[]>(
    () => data?.campuses || [],
    [data?.campuses],
  );
  const careers = useMemo<Base[]>(() => data?.careers || [], [data?.careers]);
  const workingDays = useMemo<Base[]>(
    () => data?.workingDays || [],
    [data?.workingDays],
  );

  const handleClear = useCallback(() => {
    reset();
    onFilter &&
      onFilter({
        offerName: null,
        campusId: null,
        careerId: null,
        workingDayId: null,
      });
  }, [onFilter, reset]);

  const handleSearch = useCallback(
    ({ offerName, campusId, careerId, workingDayId }: FilterFieldsSubmit) => {
      const payload: OnFilterParams = {
        offerName,
        campusId: null,
        careerId: null,
        workingDayId: null,
      };

      if (campuses?.length && campusId?.value) {
        payload.campusId = parseInt(`${campusId?.value}`, 10) || null;
      }
      if (careers?.length && careerId?.value) {
        payload.careerId = parseInt(`${careerId?.value}`, 10) || null;
      }
      if (workingDays?.length && workingDayId?.value) {
        payload.workingDayId = parseInt(`${workingDayId?.value}`, 10) || null;
      }

      onFilter && onFilter(payload);
    },
    [campuses?.length, careers?.length, onFilter, workingDays?.length],
  );

  return (
    <Row className="mb-3">
      <Col xs={12} md={4}>
        <TextOutlinedInput
          control={control}
          name="offerName"
          label={t('filters.name')}
          placeholder={t('filters.namePlaceholder')}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="campusId"
          label={t('filters.campus')}
          placeholder={t('filters.campusPlaceholder')}
          disabled={!campuses?.length}
          options={campuses?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="careerId"
          label={t('filters.career')}
          placeholder={t('filters.careerPlaceholder')}
          disabled={!careers?.length}
          options={careers?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="workingDayId"
          label={t('filters.workingDay')}
          placeholder={t('filters.workingDayPlaceholder')}
          disabled={!workingDays?.length}
          options={workingDays?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={5} />
      <Col className="d-flex justify-content-between pt-2" xs={12} md={3}>
        <Button
          className="my-auto"
          style={{ height: 40, minHeight: 20 }}
          text={t('filters.search')}
          onClick={handleSubmit(handleSearch)}
          fullwidth
        />
        <Button
          className="my-auto ml-2"
          style={{ height: 40, minHeight: 20 }}
          text={t('filters.clear')}
          outlined={true}
          onClick={handleClear}
          fullwidth
        />
      </Col>
    </Row>
  );
};

export default DraftOffersFilter;
