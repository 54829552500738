import { addToast, Button, ColumnTable } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFromBlob } from '../../../../utils/blob';
import { ActionType, getReportFiles, ReportType } from '../api';

const keyPrefix = 'views.settings.reports';

export interface ReportsItems {
  name: string;
  code: ReportType;
}
interface UseColumnParams {
  onPressUpload?: (reportName: string, reportCode: ReportType) => void;
}

export function errorToast(error: string) {
  addToast({
    icon: 'error',
    color: 'danger',
    text: error,
  });
}

export default function useColumns(params: UseColumnParams) {
  const { t } = useTranslation();
  const showError = useCallback(
    () => errorToast(t(`common.terms.unexpectedError`)),
    [t],
  );

  const handleDownload = useCallback(
    async (reportCode: ReportType, action: ActionType) => {
      const { data, error } = await getReportFiles(reportCode, action);
      if (error) {
        showError();
      }
      downloadFromBlob(data!, 'PlantillaDePlanificacionAcademica.xlsx');
    },
    [showError],
  );

  return useMemo<ColumnTable<ReportsItems>[]>(
    () => [
      {
        columnName: 'name',
        headerText: 'Reporte',
        thClassName: 'ml-5 pl-5',
        cellFormat: ({ value }) => (
          <div className="ml-5 text-capitalize">{value}</div>
        ),
      },
      {
        columnName: 'action',
        headerText: 'Acción',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="float-right">
            <Button
              className="ml-3  mb-3"
              icon="download"
              size="md"
              text={t(`${keyPrefix}.downloadBase`)}
              onClick={() => handleDownload(row.code, 'base')}
              outlined
              style={{ minWidth: '286px' }}
            />
            <Button
              className="ml-3 mb-3"
              icon="download"
              size="md"
              text={t(`${keyPrefix}.downloadTemplate`)}
              onClick={() => handleDownload(row.code, 'template')}
              outlined
              style={{ minWidth: '286px' }}
            />
            <Button
              className="ml-3 mb-3"
              icon="upload"
              size="md"
              text={t(`${keyPrefix}.upload`)}
              onClick={() => {
                params.onPressUpload &&
                  params.onPressUpload(row.name, row.code);
              }}
              style={{ minWidth: '286px' }}
            />
          </div>
        ),
      },
    ],
    [handleDownload, params, t],
  );
}
