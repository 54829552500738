// Components
import StepsHeader from './parts/StepsHeader';
import RenderComponent from './parts/RenderComponent';
import { Card } from 'reactstrap';

// Hooks
import useGetCreationScope from './hooks/useGetCreationScope';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Redux
import { useGetCreationStepsQuery } from './api';

// Types
type CreateOfferScreenParams = {
  offerDraftId?: string;
};

const keyPrefix = 'views.addOffer.stepsNames';

// Render
const CreateOfferScreen = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { offerDraftId } = useParams<CreateOfferScreenParams>();
  const { scope } = useGetCreationScope();

  const { data, refetch } = useGetCreationStepsQuery(
    { scope, offerDraftFlowId: offerDraftId },
    { refetchOnMountOrArgChange: true },
  );

  const handleRefresh = async () => {
    try {
      await refetch()?.unwrap();
    } catch (_error) {}
  };

  const steps = useMemo(() => data?.steps || [], [data?.steps]);
  const current = useMemo(
    () => steps?.find((e) => e?.code === data?.currentStep) || steps[0],
    [data?.currentStep, steps],
  );

  return (
    <>
      <StepsHeader
        steps={steps}
        current={current}
        renderName={(e) => t(e?.name)}
      />
      <Card className="mb-5">
        <RenderComponent
          scope={scope}
          offerDraftId={offerDraftId}
          current={current}
          onSave={handleRefresh}
        />
      </Card>
    </>
  );
};

export default CreateOfferScreen;
