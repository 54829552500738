// Components
import {
  Button,
  Dialog,
  DisplayInfo,
  Table,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import UseSelectedUsersColumns from './hooks/useSelectedUsersColumns';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useSaveSectionsMutation, useGetPostuationsQuery } from './api';
import { useGenerateExcelMutation } from '../contract/api';
import useQuery from '../../hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { OnFilterParams } from '../hiring/history/parts/Filters';

// Types
export type SectionItem = {
  postulationId: number;
  sectionId: string;
  name: string;
};

const keyPrefix = 'views.hiring.assingSections';

// Render
export default function AssignSectionsUsers() {
  const { t } = useTranslation();
  const history = useHistory();

  const [sections, setSections] = useState<SectionItem[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const [saveSections] = useSaveSectionsMutation();
  const [generateExcel] = useGenerateExcelMutation();

  const query = useQuery();
  const userIds = useMemo(() => {
    const ids = (query?.getParam('userIds') || '')
      ?.split(',')
      ?.map((e) => parseInt(`${e}`, 10));
    if (ids?.length === 1) {
      return ids?.concat(0);
    }
    return ids;
  }, [query]);

  const filters = useMemo(() => {
    const _filters: OnFilterParams = {};
    if (query?.getParam('statusId')) {
      _filters.statusId = query?.getParam('statusId');
    }
    if (query?.getParam('offerId')) {
      _filters.offerId = parseInt(`${query?.getParam('offerId')}`, 10);
    }
    if (query?.getParam('campusId')) {
      _filters.campusId = parseInt(`${query?.getParam('campusId')}`, 10);
    }
    if (query?.getParam('careerId')) {
      _filters.careerId = parseInt(`${query?.getParam('careerId')}`, 10);
    }
    if (query?.getParam('schoolId')) {
      _filters.schoolId = parseInt(`${query?.getParam('schoolId')}`, 10);
    }
    if (query?.getParam('workingDayId')) {
      _filters.workingDayId = parseInt(
        `${query?.getParam('workingDayId')}`,
        10,
      );
    }
    return _filters;
  }, [query]);

  const { data: postulations = [], isFetching } = useGetPostuationsQuery(
    [userIds, filters],
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleChangeSections = useCallback(
    (postulationId: number, nexts: SectionItem[] = []) => {
      let items = [...sections];
      items = items?.filter((e) => e?.postulationId !== postulationId);

      if (
        items.some((e) => nexts.map((e) => e?.sectionId).includes(e.sectionId))
      ) {
        return addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.generateModal.already`),
        });
      }
      setSections(items?.concat(nexts));
    },
    [sections, t],
  );

  const generatePayload = useCallback(() => {
    const items = [];
    for (let i = 0; i < postulations?.length; i++) {
      const postulation = postulations[i];
      const sectionsIds = sections
        ?.filter((e) => e?.postulationId === postulation?.id)
        ?.map((e) => e?.sectionId);
      items?.push({
        id: postulation?.id,
        sections: sectionsIds,
      });
    }
    return items;
  }, [postulations, sections]);

  const handleSubmit = useCallback(async () => {
    try {
      setSaving(true);
      const postulations = generatePayload();
      await saveSections({ postulations })?.unwrap();
      const url = await generateExcel(
        postulations?.map((e) => e?.id)?.concat([0]),
      )?.unwrap();
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'planilla.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${keyPrefix}.generateModal.success`),
      });
      setSaving(false);

      history?.goBack();
    } catch (_error) {
      setSaving(false);
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${keyPrefix}.generateModal.failed`),
      });
    }
  }, [generatePayload, saveSections, generateExcel, t, history]);

  const columns = UseSelectedUsersColumns({
    sections,
    onChangeSections: handleChangeSections,
  });

  const isSubmitEnabled = useMemo(() => {
    for (let i = 0; i < postulations?.length; i++) {
      const postulation = postulations[i];
      if (postulation) {
        if (!sections?.some((e) => e?.postulationId === postulation?.id)) {
          return false;
        }
      }
    }
    return true;
  }, [sections, postulations]);

  const handlePressGenerate = useCallback(() => {
    if (saving) {
      return;
    }
    if (!isSubmitEnabled) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${keyPrefix}.generateModal.requiredMin`),
      });
      return;
    }
    showDialogConfirm({
      title: t(`${keyPrefix}.generateModal.title`),
      subtitle: t(`${keyPrefix}.generateModal.subtitle`),
      btnClose: {
        text: t(`common.actions.cancel`),
      },
      btnConfirm: {
        text: t(`common.actions.confirm`),
        onConfirm: () => handleSubmit(),
      },
    });
  }, [saving, handleSubmit, isSubmitEnabled, t]);

  return (
    <>
      <Card className="p-4">
        <Row>
          <Col className="mb-4" xs={12}>
            <h3 className="text-primary fs-20 fw-700">
              {t(`${keyPrefix}.title`)}
            </h3>
            <p className="fs-16">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} className="pt-1 mb-5 g-table-hidden-x">
            <Table
              columns={columns}
              data={!isFetching ? postulations : []}
              isLoadingResults={isFetching}
              loadingView={{
                title: t(`common.loading.title`),
                subtitle: t(`common.loading.subtitle`),
              }}
              noResultsText={
                <div className="d-flex flex-column align-items-center no-result">
                  <DisplayInfo
                    title={t(`${keyPrefix}.empty.title`)}
                    textBody={t(`${keyPrefix}.empty.body`)}
                  />
                </div>
              }
            />
          </Col>
          {Boolean(postulations?.length && !isFetching) && (
            <Col xs={12}>
              <div className="w-100 d-flex flex-column align-items-end justify-content-center">
                <Button
                  loading={saving}
                  text={t(`${keyPrefix}.continue`)}
                  onClick={handlePressGenerate}
                />
                <span className="mt-3">{t(`${keyPrefix}.continueHint`)}</span>
              </div>
            </Col>
          )}
        </Row>
      </Card>
      <Dialog />
    </>
  );
}
