// Components
import Legend from '../../../../components/Legend/Legend';
import { UncontrolledTooltip } from 'reactstrap';

// Hooks
import { useCallback, useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { Button, ColumnTable, Icon } from '@octano/global-ui';
import { AuthorizedPostulantPostulationItem } from '../../types';
import { AuthorizationStatus } from '../../../academic-authorization/types';
import { useHistory } from 'react-router-dom';

export type UseAuthorizedPostulantsPostulationsColumnsProps = {
  userId?: string | number;
  generateEnabled?: boolean;
  onPressReject: (
    userId: string | number,
    postulationId: string | number,
  ) => void;
  onPressAuthorize: (
    userId: string | number,
    postulationId: string | number,
  ) => void;
  onPressGenerate: (userId: string | number) => void;
};

const keyPrefix = 'views.hiring.authorizedPostulants';

// Hook
const UseAuthorizedPostulantsPostulationsColumns = ({
  userId = 0,
  generateEnabled = false,
  onPressReject,
  onPressAuthorize,
  onPressGenerate,
}: UseAuthorizedPostulantsPostulationsColumnsProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const history = useHistory();

  const statuses: Record<
    AuthorizationStatus,
    { color: string; text: string; tooltip: string }
  > = useMemo(
    () => ({
      [AuthorizationStatus.NotAuthorizedForHiring]: {
        color: '#EE9EF8',
        text: t('authorizationStatuses.notAuthorizedForHiring'),
        tooltip: t('authorizationStatusesTooltips.notAuthorizedForHiring'),
      },
      [AuthorizationStatus.DiscardedInHiring]: {
        color: '#EE9EF8',
        text: t('authorizationStatuses.discardedInHiring'),
        tooltip: t('authorizationStatusesTooltips.discardedInHiring'),
      },
      [AuthorizationStatus.Hired]: {
        color: '#CEF0DA',
        text: t('authorizationStatuses.hired'),
        tooltip: t('authorizationStatusesTooltips.hired'),
      },
      [AuthorizationStatus.AuthorizedForHiring]: {
        color: '#CEF0DA',
        text: t('authorizationStatuses.authorizedForHiring'),
        tooltip: t('authorizationStatusesTooltips.authorizedForHiring'),
      },
      [AuthorizationStatus.Pending]: {
        color: '#FFF784',
        text: t('authorizationStatuses.pending'),
        tooltip: t('authorizationStatusesTooltips.pending'),
      },
    }),
    [t],
  );

  const goToPostulation = useCallback(
    (id: string | number) => {
      history.push(`/hiring/users/${userId}/postulations/${id}`);
    },
    [history, userId],
  );

  const columns = useMemo<ColumnTable<AuthorizedPostulantPostulationItem>[]>(
    () => [
      {
        columnName: 'offerName',
        headerText: t('table.postulationsTable.offer'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <div>
            <p>{value}</p>
          </div>
        ),
      },
      {
        columnName: 'authorizationStatus',
        headerText: t('table.postulationsTable.status'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => (
          <div>
            {row?.id !== 0 ? (
              <div className="d-flex align-items-center justify-content-center">
                <Legend
                  text={statuses[value as AuthorizationStatus].text}
                  color={statuses[value as AuthorizationStatus].color}
                />
                <div className="ml-2" id={`tooltip-auth-status-${row?.id}`}>
                  <Icon name="information" color="primary" />
                </div>
                <UncontrolledTooltip
                  placement="top"
                  target={`tooltip-auth-status-${row?.id}`}
                  fade={false}
                >
                  {statuses[value as AuthorizationStatus].tooltip}
                </UncontrolledTooltip>
              </div>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        columnName: 'id',
        headerText: t('table.postulationsTable.resumen'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => (
          <div>
            {row?.id !== 0 ? (
              <span onClick={() => goToPostulation(value)}>
                <Icon name="eye" size={18} />
              </span>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        columnName: 'id',
        headerText: t('table.postulationsTable.authorize'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '340px',
        cellFormat: ({ value, row }) => (
          <div className="d-flex flex-row">
            {row?.id !== 0 ? (
              <>
                <Button
                  style={{ height: 32, minHeight: 20 }}
                  color="danger"
                  size="sm"
                  className="mr-1 flex-fill"
                  outlined={true}
                  text={t('table.postulationsTable.discardPostulation')}
                  onClick={() => onPressReject(userId, value)}
                />
                <Button
                  className="ml-1 flex-fill"
                  size="sm"
                  disabled={
                    row?.authorizationStatus !==
                    AuthorizationStatus.AuthorizedForHiring
                  }
                  style={{ height: 32, minHeight: 20 }}
                  text={t('table.postulationsTable.markAsHired')}
                  onClick={() => onPressAuthorize(userId, value)}
                />
              </>
            ) : (
              <>
                <Button
                  className="flex-fill"
                  style={{ height: 40, minHeight: 20 }}
                  text={t('table.postulationsTable.generateContractDocument')}
                  disabled={!generateEnabled}
                  onClick={() => onPressGenerate(userId)}
                />
              </>
            )}
          </div>
        ),
      },
    ],
    [
      t,
      statuses,
      goToPostulation,
      generateEnabled,
      onPressReject,
      userId,
      onPressAuthorize,
      onPressGenerate,
    ],
  );

  return columns;
};

export default UseAuthorizedPostulantsPostulationsColumns;
