import { useCallback, useMemo } from 'react';

// Components
import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SelectOptionType,
} from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

// Hooks
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';
import { SectionFilters as SectionFiltersType } from '../type';
import { useSectionsLoader } from './Loaders/SectionsLoader';
import { useSectionsFilterLoader } from './Loaders/SectionsFilterLoader';

// Types
const keyPrefix = 'common.terms';

export type FilterFields = {
  subjectId: SelectOptionType | null;
  campusId: SelectOptionType | null;
  careerId: SelectOptionType | null;
  periodId: SelectOptionType | null;
  workingDayId: SelectOptionType | null;
};

export type FilterFieldsSubmit = {
  campusId: { value: string };
  careerId: { value: string };
  periodId: { value: string };
  subjectId: { value: string };
  workingDayId: { value: string };
};

// Render
function SectionFilters() {
  const { t } = useTranslation('translation', { keyPrefix });
  const { data } = useSectionsFilterLoader();
  const { updateQuery, query } = useSectionsLoader();
  const { control, handleSubmit, reset } = useForm<FilterFields>({
    defaultValues: {
      subjectId: null,
      campusId: null,
      periodId: null,
      careerId: null,
      workingDayId: null,
    },
  });

  const campuses = useMemo<OutlinedSelectOptionType[]>(
    () =>
      data?.campuses.map((c: any) => ({ label: c.name, value: c.id })) || [],
    [data?.campuses],
  );
  const careers = useMemo<OutlinedSelectOptionType[]>(
    () => data?.careers.map((c: any) => ({ label: c.name, value: c.id })) || [],
    [data?.careers],
  );
  const workingDays = useMemo<OutlinedSelectOptionType[]>(
    () =>
      data?.workingDays.map((c: any) => ({ label: c.name, value: c.id })) || [],
    [data?.workingDays],
  );
  const periods = useMemo<OutlinedSelectOptionType[]>(
    () => data?.periods.map((c: any) => ({ label: c.name, value: c.id })) || [],
    [data?.periods],
  );

  const subjects = useMemo<OutlinedSelectOptionType[]>(
    () =>
      data?.subjects.map((c: any) => ({ label: c.name, value: c.id })) || [],
    [data?.subjects],
  );

  const handleClear = useCallback(() => {
    reset();

    updateQuery({
      subjectId: null,
      periodId: null,
      campusId: null,
      careerId: null,
      workingDayId: null,
      page: 1,
      itemsPerPage: 10,
    });
  }, [reset, updateQuery]);

  const handleSearch = useCallback(
    ({
      subjectId,
      periodId,
      campusId,
      careerId,
      workingDayId,
    }: FilterFieldsSubmit) => {
      const payload: SectionFiltersType = {
        subjectId: null,
        periodId: null,
        campusId: null,
        careerId: null,
        workingDayId: null,
      };

      if (campuses?.length && campusId?.value) {
        payload.campusId = parseInt(`${campusId?.value}`, 10) || null;
      }
      if (careers?.length && typeof careerId?.value === 'number') {
        payload.careerId = parseInt(`${careerId?.value}`, 10);
      }
      if (workingDays?.length && workingDayId?.value) {
        payload.workingDayId = parseInt(`${workingDayId?.value}`, 10) || null;
      }
      if (periods?.length && periodId?.value) {
        payload.periodId = parseInt(`${periodId?.value}`, 10) || null;
      }
      if (subjects?.length && subjectId?.value) {
        payload.subjectId = parseInt(`${subjectId?.value}`, 10) || null;
      }

      console.log(payload);
      updateQuery({
        ...query,
        ...payload,
        page: 1,
      });
    },
    [
      campuses?.length,
      careers?.length,
      periods?.length,
      query,
      subjects?.length,
      updateQuery,
      workingDays?.length,
    ],
  );

  return (
    <Row className="mb-3">
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="periodId"
          label={t('period')}
          placeholder={t('period')}
          disabled={!periods?.length}
          options={periods}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="campusId"
          label={t('campus')}
          placeholder={t('campus')}
          disabled={!campuses?.length}
          options={campuses}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="careerId"
          label={t('career')}
          placeholder={t('career')}
          disabled={!careers?.length}
          options={careers}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="workingDayId"
          label={t('workingDay')}
          placeholder={t('workingDay')}
          disabled={!workingDays?.length}
          options={workingDays}
        />
      </Col>
      <Col xs={12} md={4}>
        <OutlinedSelect
          control={control}
          name="subjectId"
          label={t('subject')}
          placeholder={t('subject')}
          disabled={!subjects?.length}
          options={subjects}
        />
      </Col>
      <Col
        className="d-flex justify-content-between pt-2 ml-auto"
        xs={12}
        md={3}
      >
        <Button
          className="my-auto"
          style={{ height: 40, minHeight: 20 }}
          text={t('search')}
          onClick={handleSubmit(handleSearch)}
          fullwidth
        />
        <Button
          className="my-auto ml-2"
          style={{ height: 40, minHeight: 20 }}
          text={t('clear')}
          outlined={true}
          onClick={handleClear}
          fullwidth
        />
      </Col>
    </Row>
  );
}

export default SectionFilters;
