// Components
import { Icon, TextAreaInput } from '@octano/global-ui';
import { Col, UncontrolledTooltip } from 'reactstrap';

import LengthCounter from '../../../../../components/Form/LengthCounter';

// Hooks
import { useFormContext } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { OfferDetailsFormFields } from '../types';
import { TEXTAREA_MAXLENGTH } from '../../../../../config/constants';

const prefix = 'views.addOffer.offerDetails';

// Render
const LevelOfResponsibilitySection = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<OfferDetailsFormFields>();

  return (
    <>
      <Col xs={12} className="mt-5">
        <div className="d-flex align-conent-center align-items-center">
          <h5 className="text-primary fs-20 fw-700 mb-0 mr-2 text-uppercase">
            {t(`${prefix}.LevelOfResponsibilitySection.title`)}
          </h5>
          <span id="levelOfResponsibilitySectionInformation">
            <Icon name="information" />
          </span>
          <UncontrolledTooltip
            placement="right"
            target={'levelOfResponsibilitySectionInformation'}
            fade={false}
          >
            {t(`${prefix}.LevelOfResponsibilitySection.information`)}
          </UncontrolledTooltip>
        </div>
      </Col>
      <Col xs={12}>
        <TextAreaInput
          label=""
          name="levelResponsibility"
          placeholder={t(`${prefix}.addADescription`)}
          rules={{ maxLength: TEXTAREA_MAXLENGTH }}
          control={control}
          maxLength={TEXTAREA_MAXLENGTH}
        />
        <LengthCounter name="levelResponsibility" />
      </Col>
    </>
  );
};

export default LevelOfResponsibilitySection;
