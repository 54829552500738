// Components
import SelectedUserHeader from '../parts/SelectedUserHeader';
import SelectedUserRow from '../parts/SelectedUserRow';

// Hooks
import { useMemo } from 'react';

// Types
import { ColumnTable } from '@octano/global-ui';
import { AssingSectionPostulation } from '../types';
import { SectionItem } from '../index';

type UseSelectedUsersColumnsProps = {
  sections?: SectionItem[];
  onChangeSections?: (postulationId: number, next: SectionItem[]) => void;
};

// Hook
const UseSelectedUsersColumns = ({
  sections = [],
  onChangeSections,
}: UseSelectedUsersColumnsProps) => {
  const columns = useMemo<ColumnTable<AssingSectionPostulation>[]>(
    () => [
      {
        columnName: 'user',
        headerText: <SelectedUserHeader />,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <SelectedUserRow
            item={row}
            sections={sections?.filter((e) => e?.postulationId === row?.id)}
            onChangeSections={onChangeSections}
          />
        ),
      },
    ],
    [onChangeSections, sections],
  );

  return columns;
};

export default UseSelectedUsersColumns;
