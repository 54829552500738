import { Button, useMobile } from '@octano/global-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import HeaderSection from '../../../../components/steps/HeaderSection';
import { useStepState } from '../../../../hooks/useStepState';
import { useGetPostulationQuery } from '../../api';
import PostulationDetailsForm from './parts/PostulationDetailsForm';

type PostulationReviewParams = {
  postulationId: string;
  offerId: string;
};

type PostulationReviewProps = {
  hideNextButton?: boolean;
};

export default function PostulationReview({
  hideNextButton = false,
}: PostulationReviewProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const history = useHistory();
  const { nextStep } = useStepState();

  const { postulationId, offerId } = useParams<PostulationReviewParams>();

  const {
    data = {
      name: '',
      studyPlan: '',
      user: { name: '', rut: '' },
      educationalLevel: [],
      answer: [],
      certifications: [],
      curriculum: [],
      licenses: [],
      collegeDegrees: [],
      availabilityDate: '',
      availabilityTime: '',
      salaryPretension: undefined,
      laws: [],
      updatedAt: new Date(),
    },
    isFetching,
    error,
  } = useGetPostulationQuery(postulationId);

  const goBack = useCallback(
    () => history.push(`/recruitment/offer-detail/${offerId}`),
    [history, offerId],
  );

  if (error) {
    return <DisplayError typeError={(error as any)?.status} />;
  }

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Row>
        <HeaderSection updatedAt={data.updatedAt} onBack={goBack} />
        <Col xs={12}>
          <p className="text-primary fs-30 fw-700 mb-3 text-uppercase">
            {t('views.recruitment.review.summary')}
          </p>
        </Col>
      </Row>
      <PostulationDetailsForm
        data={data}
        isFetching={isFetching}
        error={error}
      />
      <Row>
        {!hideNextButton && (
          <Col xs={12} className="text-right mt-5">
            <Button
              text={t(`common.actions.next`)}
              style={{ width: isMobile ? '100%' : '246px' }}
              className="mb-3"
              onClick={nextStep}
            />
          </Col>
        )}
      </Row>
    </>
  );
}
