import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, handleSessionExpiredError } from '../../utils/auth';
import {
  GetPostulationSections,
  Section,
  AssingSectionPostulation,
} from './types';
import { GetHiringHistoryFilters } from '../hiring/types';

const filterAssocName: any = {
  name: 'name',
  offerId: 'offer',
  campusId: 'campus',
  careerId: 'career',
  workingDayId: 'workingDay',
  statusId: 'authorizationStatus',
  schoolId: 'school',
};

export const assignSectionApi = createApi({
  reducerPath: 'assignSectionApi',
  baseQuery: getBaseQuery('/mantainer-portal/assign-sections'),
  endpoints: (builder) => ({
    getPostuations: builder.query<
      AssingSectionPostulation[],
      [(string | number)[], GetHiringHistoryFilters | undefined]
    >({
      query: ([userIds, filters]) => {
        let url = '/postulations';
        userIds?.forEach((id, i) => {
          if (i === 0) {
            url += `?userIds=${id}`;
          } else {
            url += `&userIds=${id}`;
          }
        });
        Object.entries(filters ?? {})?.forEach(([key, value]) => {
          url += `&${filterAssocName[key]}=${value}`;
        });
        return {
          url,
        };
      },
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    postulationSections: builder.query<Section[], GetPostulationSections>({
      query: (params) => ({
        url: '/sections',
        params,
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    saveSections: builder.mutation<any, any>({
      query: (body) => ({
        method: 'PUT',
        url: '/sections',
        body,
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const {
  useGetPostuationsQuery,
  usePostulationSectionsQuery,
  useSaveSectionsMutation,
} = assignSectionApi;
