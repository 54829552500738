// Components
import { DetailBox, DetailBoxColumn } from '@octano/global-ui';

// Hooks
import { useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Type
const keyPrefix = 'views.academicAuthorization.postulantDetails';

export interface Props {
  id?: string;
  names?: string;
  surnames?: string;
}

export default function PostulantDetails({ id, names, surnames }: Props) {
  const { t } = useTranslation('translation', { keyPrefix });

  const columns = useMemo<DetailBoxColumn[]>(() => {
    let cols = [];
    if (id?.trim()) {
      cols.push({
        title: t(`id`),
        body: id,
        size: { xs: 'auto' },
      });
    }
    if (names?.trim()) {
      cols.push({
        title: t(`names`),
        body: names,
        size: { xs: 'auto' },
      });
    }

    if (surnames?.trim()) {
      cols.push({
        title: t(`surnames`),
        body: surnames,
        size: { xs: 'auto' },
      });
    }

    return cols;
  }, [id, names, surnames, t]);

  return <DetailBox columns={columns} />;
}
