// Components
import BottomButtons from '../parts/BottomButtons';
import { Dialog, TextInput, addToast, useMobile } from '@octano/global-ui';
import { Card, Col, Form, Row } from 'reactstrap';

// Hooks
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useValidations } from '../../../hooks/useValidations';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import clsx from 'clsx';

// Redux
import { useUpdatePasswordMutation } from '../api';

// Types
type FormFields = {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
};

// Render
const ChangePasswordScreen = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const {
    validateTextNotEmpty,
    validatePassword,
    validateRepeatPassword,
    msgValidations,
  } = useValidations();

  const [updatePassword, { isLoading: updatingPassword }] =
    useUpdatePasswordMutation();

  const methods = useForm<FormFields>();
  const { control, watch, reset } = methods;

  const isSubmitting = useMemo(
    () => methods?.formState?.isSubmitting || updatingPassword,
    [methods?.formState?.isSubmitting, updatingPassword],
  );

  const [newPassword] = watch(['password']);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      try {
        await updatePassword({
          oldPassword: values?.oldPassword,
          newPassword: values?.password,
        })?.unwrap();
        reset();
        addToast({
          icon: 'success',
          color: 'success',
          text: t('views.account.changePassword.success'),
        });
      } catch (_error: any) {
        addToast({
          icon: 'error',
          color: 'danger',
          text:
            _error?.data?.message ?? t('views.account.changePassword.failed'),
        });
      }
    },
    [reset, t, updatePassword],
  );

  return (
    <>
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <FormProvider {...methods}>
          <Form onSubmit={methods?.handleSubmit(onSubmit)}>
            <Row>
              <Col className="mb-4" xs={12}>
                <h1 className="fs-25 fw-700 text-primary">
                  {t(`views.account.changePassword.title`)}
                </h1>
                <p className="fs-18 mb-4">
                  {t(`views.account.changePassword.subtitle`)}
                </p>
              </Col>

              <Col className="mb-4" xs={12}>
                <TextInput
                  name="oldPassword"
                  control={control}
                  type="password"
                  label={t('common.terms.oldPassword')}
                  tooltip={msgValidations.invalidPassword}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validatePassword,
                    },
                  }}
                />
              </Col>

              <Col xs={12} sm={6}>
                <TextInput
                  name="password"
                  control={control}
                  type="password"
                  label={t('common.terms.newPassword')}
                  tooltip={msgValidations.invalidPassword}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validatePassword,
                    },
                  }}
                />
              </Col>

              <Col className="mb-4" xs={12} sm={6}>
                <TextInput
                  name="passwordConfirm"
                  control={control}
                  type="password"
                  label={t('common.terms.passwordConfirm')}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validateRepeatPassword(newPassword),
                    },
                  }}
                />
              </Col>

              <BottomButtons
                buttons={[
                  {
                    type: 'submit',
                    text: t('common.actions.changePassword'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
              />
            </Row>
          </Form>
        </FormProvider>
      </Card>
      <Dialog />
    </>
  );
};

export default ChangePasswordScreen;
