// Components
import { Col } from 'reactstrap';
import { useMobile } from '@octano/global-ui';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import clsx from 'clsx';

// Types
type JobDetailHeaderProps = {
  name: string;
  campus: string;
  career: string;
  workingDay: string;
  modality: string;
  period: string;
  subjectHours: number;
};

// Render
const JobDetailHeader = ({
  name,
  campus,
  career,
  workingDay,
  modality,
  period,
  subjectHours,
}: JobDetailHeaderProps) => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Col xs={12} className="mb-4">
      <h3
        className={clsx(
          'fw-700 text-primary my-4',
          isMobile ? 'fs-25' : 'fs-30',
        )}
      >
        {name}
      </h3>
      <ul>
        <li className={'fw-600 fs-20'}>{career}</li>
        <li className={'fw-600 fs-20'}>
          {t(`common.terms.campus`)} {campus}
        </li>
        <li className={'fw-600 fs-20'}>
          {t(`common.terms.schedule`)} {workingDay}
        </li>
        <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
          {t(`common.terms.modality`)} {modality}
        </li>
        <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
          {t(`common.terms.subjectHours`)}: {subjectHours}
        </li>
        <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
          {t(`common.terms.period`)} {period}
        </li>
      </ul>
    </Col>
  );
};

export default JobDetailHeader;
