export interface PostulationInformation {
  id: number;
  user: { id: number; name: string; rut: string };
  createdAt: string;
  currentStep: CurrentStep;
}

export enum CurrentStep {
  Received = 'received',
  Resume = 'resume',
  FileUpload = 'file-upload',
  Review = 'review',
  Discarded = 'discarded',
  Approved = 'approved',
  Hired = 'hired',
}

export interface BaseInformation {
  campus: string;
  school: string;
  career: string;
  subject: string;
  name: string;
}

export interface GetOffersParams {
  itemsPerPage?: number;
  page?: number;
  campus?: number;
  school?: number;
  career?: number;
  subject?: number;
}

export interface GetPostulationParams {
  itemsPerPage?: number;
  page?: number;
  offerId: string;
  idFilter?: null | string | number;
  steps?: CurrentStep[] | null;
}
