import { IconName } from '@octano/global-ui';
import { School } from '../../types/jobOffer';
import { Subject } from '../../types/jobOffer';
import { Career } from '../../types/jobOffer';
import { Campus } from '../../types/jobOffer';
import { CurrentStep } from '../recruitment/offer-detail/types';
import { Base } from '../../types/GenericFormTypes';

export interface PostulantToAuthorizeItem {
  user: {
    id: number;
    runOrPassport: string;
    name: string;
  };
  postulationCounter: {
    changedStatus: number;
    total: number;
  };
}

export enum AuthorizationStatus {
  //postulacion autorizada para contratacion
  AuthorizedForHiring = 'authorized-for-hiring',
  //postulacion no autorizada para contratacion
  NotAuthorizedForHiring = 'not-authorized-for-hiring',
  //postulación descartada para contratacion
  DiscardedInHiring = 'discarded-in-hiring',
  //postulación contratada
  Hired = 'hired',
  // Pendiente de contratacion
  Pending = 'pending',
}

export interface PostulantToAuthorizePostulationItem {
  id: number;
  currentStep: CurrentStep;
  offerName: string;
  authorizationStatus: AuthorizationStatus;
}

export interface PostulantToAuthorizeFiltersForm {
  campuses: Campus[];
  careers: Career[];
  offerStatus: {
    icon: IconName;
    id: number;
    name: string;
  }[];
  offers: {
    id: number;
    name: string;
  }[];
  schools: School[];
  subjects: Subject[];
  workingDays: Base[];
  authorizationStatus: {
    id: string;
    name: string;
  }[];
}

export interface GetPostulatsToAuthorizeFilters {
  name?: string | null;
  offerId?: number | null;
  campusId?: number | null;
  careerId?: number | null;
  statusId?: string | null;
  workingDayId?: number | null;
}

export interface GetPostulatsToAuthorizeParams
  extends GetPostulatsToAuthorizeFilters {
  itemsPerPage?: number;
  page?: number;
}

export interface UpdatePostulantPostulationsParams {
  userId: string | number;
  postulationIds: (string | number)[];
  authorizationStatus: AuthorizationStatus;
}

export interface GetPostulationBaseInformationParams {
  userId: string | number;
  postulationId: string | number;
}
