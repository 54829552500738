import { Dialog, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import useColumns, { ReportsItems } from './hooks/useColumns';
import ModalUpload, { ModalUploadMethods } from './hooks/parts/ModalUpload';
import { useCallback, useRef } from 'react';
import { ReportType } from './api';

const keyPrefix = 'views.settings.reports.academicPlanning';
function Reports() {
  const { t } = useTranslation();
  const modalUploadRef = useRef<ModalUploadMethods>(null);

  const handleRequestUpload = useCallback(
    (reportName: string, reportCode: ReportType) => {
      modalUploadRef?.current?.open(reportName, reportCode);
    },
    [],
  );

  const colums = useColumns({
    onPressUpload: handleRequestUpload,
  });

  const data: ReportsItems[] = [
    {
      name: t(`${keyPrefix}.title`),
      code: 'academicPlanning',
    },
  ];

  return (
    <>
      <ModalUpload ref={modalUploadRef} />
      <Card className="p-4">
        <Row className="mb-3">
          <Col xs={12} className="mb-3">
            <h3 className="fs-22 fw-700 text-primary text-uppercase">
              {t(`${keyPrefix}.title`)}
            </h3>
            <p className="fs-16 m-0">{t(`${keyPrefix}.description`)}</p>
          </Col>
          <Col xs={12}>
            <Table columns={colums} data={data} />
          </Col>
        </Row>
      </Card>
      <Dialog />
    </>
  );
}

export default Reports;
