// Redux
import { useGetCreationStepQuery } from '../api';

// Types
import { GetCreationStepReq, GetCreationStepRes } from '../types';

// Render
const useGetStepData = <T>(
  id?: GetCreationStepReq['id'],
  area?: GetCreationStepReq['area'],
): GetCreationStepRes<T> => {
  const { data, isFetching } = useGetCreationStepQuery<GetCreationStepRes<T>>(
    {
      id: id !== -1 ? id : undefined,
      area,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  return { data, isFetching };
};

export default useGetStepData;
