import React from 'react';
import { TEXTAREA_MAXLENGTH } from '../../config/constants';

import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

import { FormText } from 'reactstrap';

interface Props<TName> {
  name: TName;
  maxLength?: number;
}

export default function LengthCounter<
  TName extends FieldPath<FieldValues> = FieldPath<FieldValues>,
>({ name, maxLength = TEXTAREA_MAXLENGTH }: Props<TName>) {
  const { watch } = useFormContext();
  const value = watch(name);
  return (
    <FormText className="pl-2 fs-13 fw-400">
      {String(value ?? '').length}/{maxLength}
    </FormText>
  );
}
