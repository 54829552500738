import {
  addToast,
  Button,
  Dialog,
  DisplayInfo,
  OutlinedSelect,
  SelectOptionType,
  showDialogConfirm,
  Table,
  TextInput,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import DisplayError from '../../../../components/info/DisplayError';
import { useStepState } from '../../../../hooks/useStepState';
import {
  useGetPostulationManagmentDataQuery,
  useGetPostulationManagmentStagesQuery,
  useSavePostulationManagmentMutation,
} from '../../api';
import { CurrentStep } from '../../offer-detail/types';
import { PostulationApplicationStage, PostulationStageStep } from '../types';
import useStepColumns from './hooks/useStepColumns';

import Box from './parts/Box';
import ReviewStepModal, {
  ReviewStepModalMethods,
} from './parts/ReviewStepModal';

// Types
type PostulationManagementParams = {
  postulationId: string;
  offerId: string;
};

// Render
export default function PostulationManagement() {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { prevStep } = useStepState();
  const history = useHistory();

  const reviewStepRef = useRef<ReviewStepModalMethods>(null);
  const { postulationId, offerId } = useParams<PostulationManagementParams>();

  const [status, setStatus] = useState<CurrentStep>();

  const base = useGetPostulationManagmentDataQuery(postulationId);
  const stages = useGetPostulationManagmentStagesQuery(postulationId, {
    refetchOnMountOrArgChange: true,
  });
  const [savePostulation, { isLoading: saving }] =
    useSavePostulationManagmentMutation();

  const data = useMemo(
    () => Array.from(stages?.data || [])?.sort((a, b) => a.order - b.order),
    [stages?.data],
  );
  const minCalification = useMemo(
    () => base?.data?.gradesSettings?.minimunGrade,
    [base?.data?.gradesSettings?.minimunGrade],
  );
  const maxCalification = useMemo(
    () => base?.data?.gradesSettings?.maximunGrade,
    [base?.data?.gradesSettings?.maximunGrade],
  );
  const totalRegister = useMemo(
    () => stages?.data?.length || 0,
    [stages?.data],
  );
  const totalReviews = useMemo(
    () =>
      stages?.data?.filter(
        (e: PostulationApplicationStage) =>
          e?.status && e?.status !== PostulationStageStep.Pending,
      )?.length || 0,
    [stages?.data],
  );
  const totalApproves = useMemo(
    () =>
      stages?.data?.filter(
        (e: PostulationApplicationStage) =>
          e?.status === PostulationStageStep.Approved,
      )?.length || 0,
    [stages?.data],
  );

  const options: SelectOptionType[] = useMemo(
    () => [
      {
        label: t('views.recruitment.management.approveOptions.approve.label'),
        value: CurrentStep.Approved,
        icon: {
          name: 'check',
          color: 'success',
        },
      },
      {
        label: t('views.recruitment.management.approveOptions.reject.label'),
        value: CurrentStep.Discarded,
        icon: {
          name: 'error',
          color: 'danger',
        },
      },
      {
        label: t('views.recruitment.management.approveOptions.review.label'),
        value: CurrentStep.Review,
        icon: {
          name: 'warning',
          color: 'warning',
        },
      },
    ],
    [t],
  );

  const onClickReview = useCallback(
    (item: PostulationApplicationStage) => {
      if (saving) {
        return;
      }
      reviewStepRef?.current?.open(item);
    },
    [saving],
  );

  const columns = useStepColumns<PostulationApplicationStage>({
    maxCalification,
    onClickReview,
  });

  const handleSavePostulation = useCallback(
    async (statusKey: string) => {
      try {
        if (!status || saving) {
          return;
        }
        await savePostulation({
          id: postulationId,
          payload: { status },
        }).unwrap();
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${statusKey}.success`),
        });
        history.push(`/recruitment/offer-detail/${offerId}`);
      } catch (_error: any) {
        let errorMessage = t('views.recruitment.management.failed');
        if (_error?.status === 'FETCH_ERROR') {
          errorMessage = t('common.displayError.errorConnection');
        } else if (_error?.status === 'CONNECTION') {
          errorMessage = t(`common.displayError.errorConnection`);
        } else if (_error?.status === 'HTTP_ERROR') {
          errorMessage = t(`common.displayError.errorUnexpected`);
        }
        addToast({
          icon: 'error',
          color: 'danger',
          text: errorMessage,
        });
      }
    },
    [history, offerId, postulationId, savePostulation, saving, status, t],
  );

  const handleConfirm = useCallback(async () => {
    let statusKey = 'views.recruitment.management.approveOptions.';
    switch (status) {
      case CurrentStep.Approved:
        statusKey += 'approve';
        break;
      case CurrentStep.Discarded:
        statusKey += 'reject';
        break;
      case CurrentStep.Review:
        statusKey += 'review';
        break;
      default:
        return;
    }
    showDialogConfirm({
      title: t(`${statusKey}.title`),
      subtitle: t(`${statusKey}.subtitle`),
      btnClose: {
        text: t(`common.actions.cancel`),
      },
      btnConfirm: {
        text: t(`common.actions.confirm`),
        onConfirm: () => handleSavePostulation(statusKey),
      },
    });
  }, [handleSavePostulation, status, t]);

  return (
    <>
      <Row>
        <Col xs={12} md={5}>
          <Button
            icon="back"
            onClick={prevStep}
            outlined
            rounded
            size="sm"
            text={t(`common.actions.goBackToPreviousStep`)}
            className="mb-4"
          />
        </Col>
        <Col xs={12} md={7} className="mb-4">
          <Box
            information={{
              campus: base?.data?.campus?.trim() || '',
              name: base?.data?.user?.name?.trim() || '',
              career: base?.data?.career?.trim() || '',
              school: base?.data?.school?.trim() || '',
              subject: base?.data?.subject?.trim() || '',
            }}
          />
        </Col>

        <Col xs={12}>
          <p className="fs-20 fw-600 text-primary text-uppercase">
            {t('views.recruitment.management.candidateData')}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            name="rut"
            label="ID"
            value={base?.data?.user?.id}
            disabled={true}
            onChange={() => {}}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            name="names"
            label="NOMBRES"
            value={base?.data?.user?.name}
            disabled={true}
            onChange={() => {}}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            name="surnames"
            label={t('views.recruitment.management.suernames')}
            value={base?.data?.user?.surnames}
            disabled={true}
            onChange={() => {}}
          />
        </Col>
        <Col xs={12} className="mt-4">
          <p className="fs-16">
            {t('views.recruitment.management.description')}
          </p>
          <Table
            columns={columns}
            data={!stages?.isFetching ? data : []}
            isLoadingResults={stages?.isFetching}
            loadingView={{
              title: t(`common.loading.title`),
              subtitle: t(`common.loading.subtitle`),
            }}
            noResultsText={
              stages?.error ? (
                <DisplayError typeError={(stages?.error as any)?.status} />
              ) : (
                <div className="d-flex flex-column align-items-center no-result">
                  <DisplayInfo
                    title={t('views.recruitment.management.noResult')}
                    textBody={t(
                      'views.recruitment.management.noResultDescription',
                    )}
                  />
                </div>
              )
            }
          />
          {!!(stages?.data?.length && !stages?.isFetching) && (
            <div className="w-100 container-fluid">
              <div className="py-3 d-flex row alert alert-info border-0 rounded-0 mb-0">
                <strong className="flex-fill text-dark text-left">
                  {t('views.recruitment.management.reviwedStages')}
                </strong>
                <strong className="flex-fill text-dark text-right pr-4">
                  {`${totalReviews}/${totalRegister}`}
                </strong>
              </div>
              <div className="py-3 d-flex row alert alert-primary border-0 rounded-0">
                <strong className="flex-fill text-dark text-left">
                  {t('views.recruitment.management.approveStages')}
                </strong>
                <strong className="flex-fill text-dark text-right pr-4">
                  {`${totalApproves}/${totalRegister}`}
                </strong>
              </div>
            </div>
          )}
        </Col>

        <Col className="d-flex justify-content-end mt-5">
          <div
            className="status-wrapper-pr"
            style={{ width: isMobile ? '100%' : '246px' }}
          >
            <OutlinedSelect
              name="status"
              options={options}
              value={
                options?.find((o) => o?.value === status) || {
                  label: t('views.recruitment.management.applicationStatus'),
                  value: '',
                }
              }
              isClearable={false}
              isSearchable={false}
              onChange={(o) => setStatus(o?.value)}
            />
          </div>
          <Button
            text={t(`common.actions.confirm`)}
            style={{ width: isMobile ? '100%' : '246px' }}
            className="ml-3 mb-3"
            disabled={!status}
            loading={saving || stages?.isLoading}
            onClick={handleConfirm}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 7, offset: 5 }} className="text-right">
          <p>{t('views.recruitment.management.information')}</p>
        </Col>
      </Row>
      <ReviewStepModal
        ref={reviewStepRef}
        postulationId={postulationId}
        minCalification={minCalification}
        maxCalification={maxCalification}
        onConfirm={stages?.refetch}
      />
      <Dialog />
    </>
  );
}
