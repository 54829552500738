import { useMemo } from 'react';

// Types
type InvitedItemProps = {
  name: string;
  email: string;
  className?: string;
};

// Render
const InvitedItem = ({ name, email, className }: InvitedItemProps) => {
  const classes = useMemo(() => {
    let base = 'px-3 py-2 bg-tertiary text-primary border border-primary';
    if (className?.trim()) {
      base = `${base} ${className?.trim()}`;
    }
    return base;
  }, [className]);

  return (
    <div style={{ borderRadius: '8px' }} className={classes}>
      <div className="d-flex flex-column">
        <span className="w-100 text-left fs-16">{name}</span>
        <span className="w-100 text-left fs-14">{email}</span>
      </div>
    </div>
  );
};

export default InvitedItem;
