import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, handleSessionExpiredError } from '../../utils/auth';
import { RequiredFile } from './types';

export const requiredFilesApi = createApi({
  reducerPath: 'requiredFilesApi',
  baseQuery: axiosBaseQuery('/mantainer-portal/required-files'),
  endpoints: (builder) => ({
    // Required files
    getRequiredFiles: builder.query<RequiredFile[], string | number>({
      query: (id) => ({
        url: `/user/${id}/files`,
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    uploadFile: builder.mutation<void, FormData>({
      query: (body) => ({
        method: 'POST',
        url: `/user/${body?.get('userId')}/upload`,
        body,
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    deleteFile: builder.mutation<void, string | number>({
      query: (fileId) => ({
        method: 'DELETE',
        url: `/files/${fileId}`,
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const {
  useGetRequiredFilesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
} = requiredFilesApi;
