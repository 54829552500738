import { Icon } from '@octano/global-ui';
import { useMemo } from 'react';

// Types
type ContractViewfindersBadgeProps = {
  name: string;
  email: string;
  rut: string;
  className?: string;
  onRemove?: () => void;
};

// Render
const ContractViewfindersBadge = ({
  name,
  email,
  rut,
  className,
  onRemove,
}: ContractViewfindersBadgeProps) => {
  const classes = useMemo(() => {
    let base = 'px-3 py-2 bg-tertiary text-primary border border-primary';
    if (className?.trim()) {
      base = `${base} ${className?.trim()}`;
    }
    return base;
  }, [className]);

  return (
    <div
      style={{ borderRadius: '8px', minWidth: 200, maxWidth: 280 }}
      className={classes}
    >
      <div className="d-flex flex-column align-items-end justify-content-center">
        <span className="w-100 text-left fs-16">{name}</span>
        <span className="w-100 text-left fs-12">{email}</span>
        <span className="w-100 text-left fs-12">{rut}</span>
        <button
          type="button"
          style={{ position: 'absolute', marginRight: -14 }}
          className="btn"
          onClick={onRemove}
        >
          <Icon name="close" size={12} />
        </button>
      </div>
    </div>
  );
};

export default ContractViewfindersBadge;
