import { useCallback, useMemo } from 'react';

// Components
import { Button, OutlinedSelect, TextOutlinedInput } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

// Hooks
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';
import { GetPostulatsToAuthorizeFilters } from '../../types';
import { useGetFiltersFormQuery } from '../../api';

// Types
const keyPrefix = 'views.academicAuthorization.postulantsAuthorization';

type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  name: string | null;
  offerId: ItemSelect | null;
  campusId: ItemSelect | null;
  careerId: ItemSelect | null;
  statusId: ItemSelect | null;
  workingDayId: ItemSelect | null;
};

export type OnFilterParams = GetPostulatsToAuthorizeFilters;

export type FilterFieldsSubmit = {
  name: string | null;
  offerId: { value: string };
  campusId: { value: string };
  careerId: { value: string };
  statusId: { value: string };
  workingDayId: { value: string };
};

type PostulantsAuthorizationFiltersProps = {
  onFilter?: (params: OnFilterParams) => void;
};

// Render
const PostulantsAuthorizationFilters = ({
  onFilter,
}: PostulantsAuthorizationFiltersProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { control, handleSubmit, reset } = useForm<FilterFields>({
    defaultValues: {
      name: null,
      offerId: null,
      campusId: null,
      careerId: null,
      statusId: null,
      workingDayId: null,
    },
  });

  const { data } = useGetFiltersFormQuery();

  const workingDays = useMemo(
    () => data?.workingDays || [],
    [data?.workingDays],
  );
  const campuses = useMemo(() => data?.campuses || [], [data?.campuses]);
  const careers = useMemo(() => data?.careers || [], [data?.careers]);
  const statuses = useMemo(
    () => data?.authorizationStatus || [],
    [data?.authorizationStatus],
  );
  const offers = useMemo(() => data?.offers || [], [data?.offers]);

  const handleClear = useCallback(() => {
    reset();
    onFilter &&
      onFilter({
        name: null,
        offerId: null,
        campusId: null,
        careerId: null,
        statusId: null,
        workingDayId: null,
      });
  }, [onFilter, reset]);

  const handleSearch = useCallback(
    ({
      name,
      offerId,
      campusId,
      careerId,
      statusId,
      workingDayId,
    }: FilterFieldsSubmit) => {
      const payload: OnFilterParams = {
        name,
        offerId: null,
        campusId: null,
        careerId: null,
        statusId: null,
        workingDayId: null,
      };

      if (offers?.length && offerId?.value) {
        payload.offerId = parseInt(`${offerId?.value}`, 10) || null;
      }
      if (campuses?.length && campusId?.value) {
        payload.campusId = parseInt(`${campusId?.value}`, 10) || null;
      }
      if (careers?.length && careerId?.value) {
        payload.careerId = parseInt(`${careerId?.value}`, 10) || null;
      }
      if (statuses?.length && statusId?.value) {
        payload.statusId = statusId?.value || null;
      }
      if (workingDays?.length && workingDayId?.value) {
        payload.workingDayId = parseInt(`${workingDayId?.value}`, 10) || null;
      }

      onFilter && onFilter(payload);
    },
    [
      offers?.length,
      campuses?.length,
      careers?.length,
      statuses?.length,
      workingDays?.length,
      onFilter,
    ],
  );

  return (
    <Row>
      <Col xs={12} md={6} lg={3}>
        <TextOutlinedInput
          control={control}
          name="name"
          label={t('filters.name')}
          placeholder={t('filters.namePlaceholder')}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <OutlinedSelect
          control={control}
          name="offerId"
          label={t('filters.offer')}
          placeholder={t('filters.offerPlaceholder')}
          disabled={!offers?.length}
          options={offers?.map((e: any) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="campusId"
          label={t('filters.campus')}
          placeholder={t('filters.campusPlaceholder')}
          disabled={!campuses?.length}
          options={campuses?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="careerId"
          label={t('filters.career')}
          placeholder={t('filters.careerPlaceholder')}
          disabled={!careers?.length}
          options={careers?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="workingDayId"
          label={t('filters.workingDay')}
          placeholder={t('filters.workingDayPlaceholder')}
          disabled={!workingDays?.length}
          options={workingDays?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="statusId"
          label={t('filters.status')}
          placeholder={t('filters.statusPlaceholder')}
          disabled={!statuses?.length}
          options={statuses?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col
        className="d-flex justify-content-end align-items-center"
        xs={12}
        md={6}
      >
        <div className="d-flex align-items-center justify-content-end">
          <Button
            style={{ height: 40, minHeight: 20 }}
            text={t('filters.search')}
            onClick={handleSubmit(handleSearch)}
          />
          <Button
            className="ml-2"
            style={{ height: 40, minHeight: 20 }}
            text={t('filters.clear')}
            outlined={true}
            onClick={handleClear}
          />
        </div>
      </Col>
    </Row>
  );
};

export default PostulantsAuthorizationFilters;
