// Components
import { Steps } from '@octano/global-ui';

// Hooks
import { useMemo } from 'react';

// Types
import { CreateOfferStep } from '../types';

type stepsHeaderProps<T> = {
  current?: T | null;
  steps?: T[];
  renderName: (item: T) => string;
};

// Render
const StepsHeader = <T extends CreateOfferStep>({
  current,
  steps = [],
  renderName,
}: stepsHeaderProps<T>) => {
  const index = useMemo(
    () => steps?.findIndex((e) => e === current) || 0,
    [current, steps],
  );

  const names = useMemo<string[]>(
    () => steps?.map((e) => renderName(e)) || [],
    [renderName, steps],
  );

  return (
    <div className="w-100 mb-4 p-0">
      <Steps currentStep={index} steps={names} color="secondary" />
    </div>
  );
};

export default StepsHeader;
