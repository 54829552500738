import { SelectOptionType } from '@octano/global-ui';

import { Base, CheckNameType } from '../../../types/GenericFormTypes';

/**
 * Retorna un array con el campo nameInForm... para los checks
 * @param array
 * @param arrayName
 * @returns Base[]
 */
export const addNameToChecksInput = <T extends Base>(
  array: T[],
  arrayName: CheckNameType,
): T[] =>
  array.map((item) => ({
    ...item,
    nameInForm: `${arrayName}-${item.id}`,
  }));

/**
 * Si se han marcado los check de una seccion retorna un array
 * de lo contrario retorna undefined.
 * @param array
 * @param values
 * @returns result: number[] | undefined
 */

export const filterMarkOnSubmit = <T extends Base>(array: T[], values: any) => {
  const result = array
    .filter((item) => values[item.nameInForm!])
    .map((item) => item.id!);
  return result.length !== 0 ? result : undefined;
};

export const selectOptionArray = <T extends Base>(
  array: T[],
): SelectOptionType[] =>
  array.map(({ id, name }) => {
    return {
      value: id!,
      label: name,
    };
  });

export const setValues = (
  arr: number[] | null,
  name: CheckNameType,
  fillForm: any,
) => {
  if (arr) {
    return arr.forEach((item) => {
      fillForm[`${name}-${item}`] = true;
    });
  }
  return undefined;
};
