import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routes from '../../config/routes';
import { useEffect, useMemo } from 'react';
import useUserState from '../../hooks/useUserState';
import { isUserAuthorizedTo } from '../../utils/auth';
import { useHistory } from 'react-router-dom';

const AppRouter = () => {
  const { userData, isLogged } = useUserState();
  const history = useHistory();

  const validRoures = useMemo(() => {
    let _routes = Object.entries(routes)?.filter(([, value]) => {
      if (value?.requiredPermissions?.length) {
        return isUserAuthorizedTo(
          userData?.permissions || [],
          value?.requiredPermissions,
          value?.allPermissionsRequired,
        );
      }

      return true;
    });
    return _routes;
  }, [userData?.permissions]);

  const fallback = useMemo(() => {
    return validRoures?.filter(
      ([, value]) => !!(!value?.isAuthRoute || !isLogged),
    )[0];
  }, [isLogged, validRoures]);

  useEffect(() => {
    if (isLogged && userData && fallback) {
      const currentRoute = window?.location?.pathname?.split('/')[1];
      if (
        !validRoures?.some(([key]) => {
          let routeV = key?.replace('/', '');
          return routeV === currentRoute;
        })
      ) {
        const path = fallback[0] + fallback[1]?.defaultPath;
        const nextUrl = window?.location?.origin + path;
        window.location.replace(nextUrl);
        history?.push(fallback[0] + fallback[1]?.defaultPath);
      }
    }
  }, [fallback, history, isLogged, userData, validRoures]);

  return (
    <Router>
      <Switch>
        {Object.entries(routes)?.map(([key, value]) => {
          const Layout = value.layoutComponent;
          return (
            <Route
              key={key}
              path={key}
              render={(props) => <Layout {...props} />}
            />
          );
        })}

        {!!(fallback && fallback[0]) && <Redirect from="*" to={fallback[0]} />}
      </Switch>
    </Router>
  );
};

export default AppRouter;
