import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// Components
import {
  addToast,
  Button,
  Icon,
  Modal,
  Select,
  SelectOptionType,
  showDialogConfirm,
} from '@octano/global-ui';

// Hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  useGetPostulationVacantsQuery,
  useUpdatePostulantPostulationsMutation,
} from '../../api';
import { AuthorizationStatus } from '../../../academic-authorization/types';

// Types
export type AuthorizePostulationModalMethods = {
  open: (postulationId: string | number, userId: string | number) => void;
  close: () => void;
};

type AuthorizePostulationModalProps = {
  onConfirm?: () => void;
};

type FormFields = {
  sections: SelectOptionType;
};

const keyPrefix = 'views.hiring.authorizedPostulants.authorizeModal';

// Render
const AuthorizePostulationModal = (
  { onConfirm }: AuthorizePostulationModalProps,
  ref: React.Ref<AuthorizePostulationModalMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | string>(0);
  const [postulationId, setPostulationId] = useState<number | string>(0);

  const { data: vacantsData, isFetching } = useGetPostulationVacantsQuery(
    {
      userId,
      postulationId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !userId || !postulationId || !isOpen,
    },
  );

  const [updatePostulationsStatus, { isLoading: isSaving }] =
    useUpdatePostulantPostulationsMutation();

  const isLoading = useMemo(() => {
    return isSaving || isFetching;
  }, [isFetching, isSaving]);

  const { control, reset, watch, handleSubmit } = useForm<FormFields>();

  const items = useMemo(() => {
    let _availables: number[] = [];
    const max = vacantsData?.total || 0;
    for (let i = 0; i < max; i++) {
      _availables?.push(1 + i);
    }
    return _availables?.map((v) => ({
      label: `${v}`,
      value: `${v}`,
    }));
  }, [vacantsData?.total]);

  const [selected] = watch(['sections']);

  const showWarning = useMemo(() => {
    const current = selected?.value ? parseInt(`${selected?.value}`, 10) : 0;
    const max = vacantsData?.available || 0;
    return current > max;
  }, [selected?.value, vacantsData?.available]);

  const submitEnabled = useMemo(() => {
    return !!selected?.value;
  }, [selected?.value]);

  const handleOpen = useCallback(
    (uId: number | string, pId: number | string) => {
      reset();
      setIsOpen(true);
      setUserId(uId);
      setPostulationId(pId);
    },
    [reset],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setUserId(0);
    setPostulationId(0);
    reset();
  }, [reset]);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleConfirm = async (params: FormFields) => {
    try {
      if (!params?.sections?.value || isLoading) {
        return;
      }
      await updatePostulationsStatus({
        userId,
        postulationIds: [postulationId],
        authorizationStatus: AuthorizationStatus.Hired,
        selectedSections: parseInt(`${params?.sections?.value || 0}`, 10),
      });
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`success`),
      });

      handleClose();
      onConfirm && onConfirm();
    } catch (_error) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`failed`),
      });
    }
  };

  const handleActions = (params: FormFields) => {
    if (!params?.sections?.value || isLoading) {
      return;
    }
    if (showWarning) {
      showDialogConfirm({
        title: t(`titleConfirm`),
        subtitle: t(`subtitleConfirm`),
        btnClose: {
          text: t(`cancel`),
        },
        btnConfirm: {
          text: t(`confirm`),
          onConfirm: () => handleConfirm(params),
        },
      });
    } else {
      handleConfirm(params);
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-review d-flex w-100 flex-column align-items-center">
        <Icon name="warning" color="primary" size={52} />
        <span className="d-block w-100 text-dark fs-22 lh-30 text-center mt-4 mb-0">
          {t('title')}
        </span>
        <span className="d-block w-100 text-light fs-18 lh-30 text-center mb-4">
          {t('subtitle')}
        </span>
        <div className="w-100 mt-4">
          <Select
            label={t('sections')}
            options={items}
            name="sections"
            disabled={isLoading}
            control={control}
          />
          {!!showWarning && (
            <span className={`w-100 text-danger`}>
              {t('maxSections', {
                max: vacantsData?.available || 0,
              })}
            </span>
          )}
        </div>

        <div className="container-fluid px-0 mt-4">
          <div className="row wrap">
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleClose}
                text={t('cancel')}
                outlined={true}
                disabled={isLoading}
              />
            </div>
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleSubmit(handleActions)}
                text={t('confirmConfirm')}
                loading={isLoading}
                disabled={!submitEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(AuthorizePostulationModal);
