// Hooks
import useQuery from '../../../hooks/useQuery';
import { useMemo } from 'react';

// Types
import { OfferType } from '../../../types/jobOffer';

// Render
const useGetCreationScope = (): { scope: OfferType } => {
  const query = useQuery();
  const scope = useMemo<OfferType>(
    () => (query?.getParam('scope') || OfferType.Public) as OfferType,
    [query],
  );

  return { scope };
};

export default useGetCreationScope;
