export enum ViewfinderType {
  HumanResourcesViewer = 'human-resources-viewer',
  TeachingViewer = 'teaching-viewer',
  LegalRepresentative = 'legal-representative',
}
export interface ViewFinder {
  id?: number;
  name?: string;
  rut: string;
  email: string;
  type?: ViewfinderType;
}

export interface Campus {
  id: number;
  name: string;
  shortName: string;
  campusContract?: {
    id: number;
    hourValue?: number;
    viewfinders?: ViewFinder[];
  };
}
