export const formatToOnlyPositiveIntegers = (value: string) => {
  const salary = value.replace(/\D/g, '').trim();

  if (parseInt(salary) < 1) {
    return '';
  }

  return salary;
};

export const formatToOnlyPositiveScaledIntegers = (value: string) => {
  let salary: string | number = value.replace(/\D/g, '').trim();

  if (!salary?.trim()) {
    return '';
  }

  salary = parseInt(salary);
  if (salary < 1) {
    return '';
  }

  if (`${salary}`?.length === 1) {
    return `0,0${salary}`;
  }

  if (`${salary}`?.length === 2) {
    return `0,${salary}`;
  }

  const integers = `${salary}`?.slice(0, `${salary}`?.length - 2);
  const decimals = `${salary}`?.slice(-2);

  return `${integers},${decimals}`;
};

export const formatToCalificationInteger = (
  value: string,
  min: number,
  max: number,
) => {
  const next = parseInt(
    `${formatToOnlyPositiveIntegers(value)?.trim() || 0}`,
    10,
  );
  if (next <= 0) {
    return '';
  }
  if (next < min) {
    return `${min}`;
  }
  if (next > max) {
    return `${max}`;
  }
  return `${next}`;
};
