import { useCallback } from 'react';
import { createSubject, deleteSubject, updateSubject } from '../api';

interface UseActionsParams {
  createSubject: any;
  updateSubject: { subjectId: number; data: any };
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createSubjectAction = useCallback(
    async (data: UseActionsParams['createSubject']) => {
      const result = await createSubject(data);
      if (!result.error) {
        return props.onSuccess('create');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateSubjectAction = useCallback(
    async (params: UseActionsParams['updateSubject']) => {
      const result = await updateSubject(params.subjectId, params.data);
      if (!result.error) {
        return props.onSuccess('update');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteSubjectAction = useCallback(
    async (subjectId: number) => {
      const result = await deleteSubject(subjectId);
      if (!result.error) {
        return props.onSuccess('delete');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createSubject: createSubjectAction,
    updateSubject: updateSubjectAction,
    deleteSubject: deleteSubjectAction,
  };
}
