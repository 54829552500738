import {
  Button,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Section } from '../../type';
import { useSectionsFilterLoader } from '../Loaders/SectionsFilterLoader';

interface Props {
  actions: {
    createSection: (data: any) => Promise<void>;
    updateSection: (params: { sectionId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Section) => void;
}
interface FormData {
  name: string;
  shortName: string;
  subject: SelectOptionType | null;
  period: SelectOptionType | null;
  workingDay: SelectOptionType | null;
  campus: SelectOptionType | null;
}
const optionFormat = (option: { id: number; name: string }) => ({
  label: option.name,
  value: option.id,
});

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const { data } = useSectionsFilterLoader();
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Section | null>(null);

    const subjects = useMemo(
      () => data?.subjects.map((c) => ({ label: c.name, value: c.id })) || [],
      [data?.subjects],
    );
    const periods = useMemo(
      () => data?.periods.map((p) => ({ label: p.name, value: p.id })) || [],
      [data?.periods],
    );
    const workingDays = useMemo(
      () =>
        data?.workingDays.map((w) => ({ label: w.name, value: w.id })) || [],
      [data?.workingDays],
    );
    const campuses = useMemo(
      () => data?.campuses.map((c) => ({ label: c.name, value: c.id })) || [],
      [data?.campuses],
    );

    useEffect(() => {
      if (formData) {
        reset({
          name: formData.name,
          shortName: formData.shortName,
          subject: formData.subject ? optionFormat(formData.subject) : null,
          period: formData.period ? optionFormat(formData.period) : null,
          campus: formData.campus ? optionFormat(formData.campus) : null,
          workingDay: formData.workingDay
            ? optionFormat(formData.workingDay)
            : null,
        });
      } else
        reset({
          name: undefined,
          shortName: undefined,
          subject: null,
          period: null,
          campus: null,
          workingDay: null,
        });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setFormData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        name: values.name,
        shortName: values.shortName,
        subjectId: parseInt(values.subject?.value as string),
        periodId: parseInt(values.period?.value as string),
        workingDayId: parseInt(values.workingDay?.value as string),
        campusId: parseInt(values.campus?.value as string),
      };
      if (formData) {
        await actions.updateSection({
          sectionId: formData.id,
          data,
        });
      } else {
        await actions.createSection(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            Ingresar nueva sección
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="shortName"
                  label={'Sigla'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="subject"
                  options={subjects}
                  label={'Módulo'}
                  placeholder={'Módulo'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="period"
                  options={periods}
                  label={'Periodo'}
                  placeholder={'Periodo'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="workingDay"
                  options={workingDays}
                  label={'Jornada'}
                  placeholder={'Jornada'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="campus"
                  options={campuses}
                  label={'Sede'}
                  placeholder={'Sede'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
