// Components
import Badge from '../parts/Badge';

// Hooks
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import dayjs from 'dayjs';
import { format } from 'rut.js';

// Types
import { ColumnTable, Icon } from '@octano/global-ui';
import { HiringHistoryItem } from '../../types';
import { AuthorizationStatus } from '../../../academic-authorization/types';

const keyPrefix = 'views.hiring.history';

// Hook
const UseHiringHistoryColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix });
  const history = useHistory();

  const goToPostulation = useCallback(
    (item: HiringHistoryItem) => {
      history.push(`/hiring/users/${item?.user?.id}/postulations/${item?.id}`);
    },
    [history],
  );

  const statuses: Record<AuthorizationStatus, { color: string; text: string }> =
    useMemo(
      () => ({
        [AuthorizationStatus.NotAuthorizedForHiring]: {
          color: '#FFBD71',
          text: t('authorizationStatuses.notAuthorizedForHiring'),
        },
        [AuthorizationStatus.DiscardedInHiring]: {
          color: '#F2C0BD',
          text: t('authorizationStatuses.discardedInHiring'),
        },
        [AuthorizationStatus.Hired]: {
          color: '#CEF0DA',
          text: t('authorizationStatuses.hired'),
        },
        [AuthorizationStatus.AuthorizedForHiring]: {
          color: '#DDEFFF',
          text: t('authorizationStatuses.authorizedForHiring'),
        },
        [AuthorizationStatus.Pending]: {
          color: '#FFE6A1',
          text: t('authorizationStatuses.pending'),
        },
      }),
      [t],
    );

  const columns = useMemo<ColumnTable<HiringHistoryItem>[]>(
    () => [
      {
        columnName: 'user',
        headerText: t('table.postulant'),
        tdClassName: 'text-left pl-5',
        thClassName: 'text-left pl-5',
        width: '200px',
        cellFormat: ({ value }) => (
          <div className="text-center">
            <span className="text-center">
              {`${value?.name || ''} ${value?.paternalLastName || ''}`.trim()}
            </span>
            <br />
            <span className="text-center">
              {format(`${value?.rut || value?.passport || ''}`.trim())}
            </span>
          </div>
        ),
      },
      {
        columnName: 'offer',
        headerText: t('table.offer'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '230px',
        cellFormat: ({ value }) => value?.name,
      },
      {
        columnName: 'offer',
        headerText: t('table.campus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.campus?.name,
      },
      {
        columnName: 'offer',
        headerText: t('table.workingDay'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.workingDay?.name,
      },
      {
        columnName: 'authorizationStatus',
        headerText: t('table.status'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <div>
            <Badge
              text={statuses[value as AuthorizationStatus].text}
              color={statuses[value as AuthorizationStatus].color}
            />
          </div>
        ),
      },
      {
        columnName: 'updatedAt',
        headerText: t('table.updatedDate'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD-MM-YYYY HH:mm'),
      },
      {
        columnName: 'viewMore',
        headerText: t('table.viewMore'),
        tdClassName: 'text-right pr-5',
        thClassName: 'text-right pr-5',
        cellFormat: ({ row }) => (
          <span onClick={() => goToPostulation(row)}>
            <Icon name="eye" size={18} />
          </span>
        ),
      },
    ],
    [goToPostulation, statuses, t],
  );

  return columns;
};

export default UseHiringHistoryColumns;
