// Components
import { DateInput, TextInput } from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import { useFormContext } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { formatToOnlyPositiveIntegers } from '../../../../../utils/formatter';

// Types
import { OfferDetailsFormFields } from '../types';

const prefix = 'views.addOffer.offerDetails.vacanciesAndContractsSection';

// Render
const VacanciesAndContractsSection = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<OfferDetailsFormFields>();
  const initialDate = watch('initialDate');

  return (
    <>
      <Col xs={12} className="mt-5">
        <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {t(`${prefix}.title`)}
        </h5>
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          label={t(`${prefix}.vacanciesNumber`)}
          name="vacanciesNumber"
          formatter={formatToOnlyPositiveIntegers}
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <DateInput
          label={t(`${prefix}.initialDate`)}
          name="initialDate"
          control={control}
          rules={{ required: t('common.validations.required') }}
          minDate={new Date()}
        />
      </Col>
      <Col xs={12} md={4}>
        <DateInput
          label={t(`${prefix}.finishDate`)}
          name="finishDate"
          control={control}
          rules={{ required: t('common.validations.required') }}
          minDate={initialDate ? new Date(initialDate) : new Date()}
        />
      </Col>
    </>
  );
};

export default VacanciesAndContractsSection;
