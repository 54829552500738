import { addToast, Button, Table } from '@octano/global-ui';
import { useRef } from 'react';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import { useSectionsLoader } from './Loaders/SubjectLoader';
import ConfirmDeleteModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';
import { Subject } from '../type';

function SectionsTable() {
  //request para obtener secciones
  const { data, query, updateQuery, loading, refresh } = useSectionsLoader();

  //request para crear,editar o eliminar secciones
  const actions = useActions({
    onSuccess: refresh,
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  //Mandamos data por referencia al modal
  const ConfirmModalRef = useRef<ConfirmModalRef | undefined>();
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef | undefined>();

  const onClickEdit = (row: Subject) => {
    createOrUpdateModalRef.current?.openModal(row);
  };
  const onClickRemove = (row: Subject) => {
    ConfirmModalRef.current?.openModal(row);
  };

  //desde la tabla solo se permite editar o eliminar una seccion
  const columns = useColumns({ onClickEdit, onClickRemove });

  return (
    <>
      <Button
        text="Nuevo módulo"
        color="primary"
        icon="plus"
        size="md"
        className="mb-3"
        style={{ minWidth: '254px' }}
        onClick={() => createOrUpdateModalRef.current?.openModal()}
      />
      <Table
        columns={columns}
        data={data?.data ?? []}
        isLoadingResults={loading}
        loadingView={{
          title: 'Cargando secciones...',
          subtitle: 'Espere un momento',
        }}
        pagination={{
          currentPage: query?.page ?? 1,
          itemsPerPage: query.itemsPerPage ?? 10,
          totalPages: data?.totalPages ?? 0,
          totalItems: data?.total ?? 0,
          onChangePage: (page) => {
            updateQuery({ ...query, page });
          },
        }}
      />
      <CreateOrUpdateModal
        ref={createOrUpdateModalRef as React.Ref<CreateOrUpdateRef>}
        actions={actions}
      />
      <ConfirmDeleteModal
        onConfirm={(row) => {
          actions.deleteSubject(row.id);
        }}
        ref={ConfirmModalRef as React.Ref<ConfirmModalRef>}
      />
    </>
  );
}

export default SectionsTable;
