// Components
import { Card, Row, Col } from 'reactstrap';
import {
  Loading,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsList,
} from '@octano/global-ui';
import HeaderSection from '../../../components/steps/HeaderSection';
import PostulantDetailsForm from './parts/PostulantDetails';

// Hooks
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useGetUserBaseInformationQuery } from '../api';

// Utils
import { format } from 'rut.js';
import PostulationResumenTab from './parts/PostulationResumenTab';
import PostulationStepsTab from './parts/PostulationStepsTab';

// Types
type PostulantDetailsParams = {
  userId: string;
  postulationId: string;
};
const keyPrefix = 'views.academicAuthorization.postulantDetails';

// Render
const PostulantDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { postulationId, userId } = useParams<PostulantDetailsParams>();

  const [updatedAt, setUpdatedAt] = useState<Date>();

  const base = useGetUserBaseInformationQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  const surnames = useMemo(() => {
    let items: string[] = [];
    if (base?.data?.paternalLastName?.trim()) {
      items?.push(base?.data?.paternalLastName?.trim());
    }
    if (base?.data?.maternalLastName?.trim()) {
      items?.push(base?.data?.maternalLastName?.trim());
    }
    return items?.join(' ');
  }, [base?.data?.maternalLastName, base?.data?.paternalLastName]);

  const maxCalification = useMemo(
    () => base?.data?.gradesSettings?.maximunGrade,
    [base?.data?.gradesSettings?.maximunGrade],
  );

  const goBack = useCallback(() => history.goBack(), [history]);

  return (
    <>
      <Card className="p-4">
        <Row className="mb-3">
          <HeaderSection
            updatedAt={updatedAt}
            buttonText={t(`${keyPrefix}.goBack`)}
            onBack={goBack}
          />
          <Col xs={12} className="mb-3">
            <p className="fs-30 fw-700 text-primary text-uppercase">
              {t(`${keyPrefix}.title`)}
            </p>
          </Col>
        </Row>
        {base?.isLoading ? (
          <Loading textBody={t(`${keyPrefix}.loading`)} />
        ) : (
          <>
            <PostulantDetailsForm
              id={format(base?.data?.rut || base?.data?.passport)}
              names={base?.data?.name?.trim()}
              surnames={surnames?.trim()}
            />
            <Row className="mt-3">
              <Col xs={12} className="pt-1 mb-5 g-table-hidden-x">
                <Tabs size="md">
                  <TabsList>
                    <Tab>{t(`${keyPrefix}.postulationResumen.title`)}</Tab>
                    <Tab>{t(`${keyPrefix}.postulationSteps.title`)}</Tab>
                  </TabsList>
                  <TabPanels>
                    <TabPanel>
                      <PostulationResumenTab
                        userId={userId}
                        postulationId={postulationId}
                        onChangeDate={setUpdatedAt}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PostulationStepsTab
                        userId={userId}
                        postulationId={postulationId}
                        maxCalification={maxCalification}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  );
};

export default PostulantDetails;
