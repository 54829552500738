import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardFooter,
  CardHeader,
  CardLink,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';

interface Props {
  title: string;
  subtitle: string;
  percent?: number;
  items?: { label: string; value: number | string }[];
  onClick: () => void;
}

function Item({ children }: { children: ReactNode }) {
  return (
    <ListGroupItem className="d-flex py-0 border-0">{children}</ListGroupItem>
  );
}
export default function CustomCard(props: Props) {
  const { t } = useTranslation();
  const { title, subtitle, percent = 0, items, onClick } = props;

  return (
    <Card
      className="my-2 w-100 border border-primary"
      style={{
        width: '18rem',
        height: '100%',
      }}
    >
      <CardHeader className="bg-white border-bottom-0 text-primary">
        <p className="p-0 m-0 fw-700">{title}</p>
        <p className="p-0 m-0">{subtitle}</p>
      </CardHeader>
      <Row className="m-0">
        <Col xs={8} className="p-0">
          <ListGroup flush>
            {Array.isArray(items) &&
              items.map(({ label, value }, i) => (
                <Item key={i}>
                  <p className="w-75">{label}</p>
                  {value}
                </Item>
              ))}
          </ListGroup>
        </Col>
        <Col xs={4} className="d-flex">
          <div className="m-auto text-center">
            <p className="h1 text-primary">
              {parseInt(percent?.toFixed(2), 10)}%
            </p>
            <p className="fs-12">
              {t('views.recruitment.card.vacantSelected')}
            </p>
          </div>
        </Col>
      </Row>
      <CardFooter
        className="bg-white border-primary text-primary mt-auto"
        style={{ height: 45 }}
      >
        <CardLink onClick={onClick}>Ver listado de postulaciones</CardLink>
      </CardFooter>
    </Card>
  );
}
