// Components
import AuthorizedPostulantRow from '../parts/AuthorizedPostulantRow';
import AuthorizedPostulantHeader from '../parts/AuthorizedPostulantHeader';

// Hooks
import { useMemo } from 'react';
import { UseAuthorizedPostulantsPostulationsColumnsProps } from './useAuthorizedPostulantsPostulationsColumns';

// Types
import { ColumnTable } from '@octano/global-ui';
import { AuthorizedPostulantItem } from '../../types';
import { OnFilterParams } from '../parts/Filters';

// Hook
type UseAuthorizedPostulantsColumnsProps =
  UseAuthorizedPostulantsPostulationsColumnsProps & {
    isAllSelected: boolean;
    filters?: OnFilterParams;
    onToggleAll?: () => void;
  };
const UseAuthorizedPostulantsColumns = (
  props: UseAuthorizedPostulantsColumnsProps,
) => {
  const columns = useMemo<ColumnTable<AuthorizedPostulantItem>[]>(
    () => [
      {
        columnName: 'user',
        headerText: (
          <AuthorizedPostulantHeader
            isAllSelected={props?.isAllSelected}
            onToggleAll={props?.onToggleAll}
          />
        ),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <AuthorizedPostulantRow
            {...props}
            item={row}
            userId={row?.user?.id}
          />
        ),
      },
    ],
    [props],
  );

  return columns;
};

export default UseAuthorizedPostulantsColumns;
