import { DisplayInfo, Table } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import DisplayError from '../../../../components/info/DisplayError';
import { useGetPostulationsQuery } from '../../api';
import usePostulantColumns from '../hooks/usePostulantColumns';
import { CurrentStep } from '../types';

const ITEMS_PER_PAGE = 9;

interface Props {
  title: string;
  stepFilter: CurrentStep[];
  idFilter?: string | number | null;
  offerId: string;
  offerName: string;
  isSelectedsTable?: boolean;
  emptyTitle?: string | null
  emptyDescription?: string | null
}

export default function Applications({
  title,
  stepFilter,
  idFilter,
  offerId,
  offerName,
  emptyTitle,
  emptyDescription,
  isSelectedsTable = false,
}: Props) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const columns = usePostulantColumns({ offerId, offerName, isSelectedsTable });
  const {
    data: postulationsData,
    isFetching,
    error,
  } = useGetPostulationsQuery(
    {
      page: currentPage - 1,
      itemsPerPage: 10,
      offerId,
      idFilter,
      steps: stepFilter,
    },
    { refetchOnMountOrArgChange: true },
  );

  const totalPages = useMemo(
    () => postulationsData?.totalPages || 1,
    [postulationsData?.totalPages],
  );
  const totalPostulations = useMemo(
    () => postulationsData?.total || 0,
    [postulationsData?.total],
  );
  const postulations = useMemo(
    () => postulationsData?.data || [],
    [postulationsData?.data],
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <p className="fs-20 fw-600 text-uppercase text-primary">{title}</p>
        </Col>
        <Col xs={12} className="pt-1 mb-5">
          <Table
            columns={columns}
            data={!isFetching ? postulations : []}
            isLoadingResults={isFetching}
            loadingView={{
              title: t(`common.loading.title`),
              subtitle: t(`common.loading.subtitle`),
            }}
            noResultsText={
              (error as any) ? (
                <DisplayError typeError={(error as any)?.status} />
              ) : (
                <div className="d-flex flex-column align-items-center no-result">
                  <DisplayInfo
                    title={emptyTitle?.trim() || t('views.recruitment.detail.noResult')}
                    textBody={emptyDescription?.trim() || t('views.recruitment.detail.noResultDescription')}
                  />
                </div>
              )
            }
            pagination={{
              currentPage: currentPage,
              itemsPerPage: ITEMS_PER_PAGE,
              onChangePage: (page: number) => setCurrentPage(page),
              totalItems: totalPostulations,
              totalPages: totalPages,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
