// Components
import { Row, Col } from 'reactstrap';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { GetPostulatsToAuthorizeFilters } from '../../types';
const keyPrefix = 'views.academicAuthorization.postulantsAuthorization';

type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  name: string | null;
  offerId: ItemSelect | null;
  campusId: ItemSelect | null;
  careerId: ItemSelect | null;
  statusId: ItemSelect | null;
};

export type OnFilterParams = GetPostulatsToAuthorizeFilters;

export type FilterFieldsSubmit = {
  name: string | null;
  offerId: { value: string };
  campusId: { value: string };
  careerId: { value: string };
  statusId: { value: string };
};

// Render
const PostulantsAuthorizationHeader = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Row className="mx-0">
      <Col>
        <div className="w-100 text-center">{t('table.postulantName')}</div>
      </Col>
      <Col>
        <div className="w-100 text-center">{t('table.postulantId')}</div>
      </Col>
      <Col>
        <div className="w-100 text-center">{t('table.postulationsCount')}</div>
      </Col>
      <Col>
        <div className="w-100 text-center">
          {t('table.authorizationsCount')}
        </div>
      </Col>
      <Col xs={1}>
        <div className="w-100 text-center">{t('table.viewMore')}</div>
      </Col>
    </Row>
  );
};

export default PostulantsAuthorizationHeader;
