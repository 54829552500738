// Components
import { Row, Col } from 'reactstrap';
import { Button, Select, SelectOptionType } from '@octano/global-ui';

// Hooks
import { useFormContext } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
type LanguagesComponentProps = {
  languageLength?: number;
  languages?: SelectOptionType[];
  languageLevels?: SelectOptionType[];
  onAddLanguage?: () => void;
  onRemoveLanguage?: (index: number) => void;
};

const keyPrefix = 'views.addOffer.offerRequirements';

// Render
const LanguagesComponent = ({
  languageLength = 0,
  languages = [],
  languageLevels = [],
  onAddLanguage,
  onRemoveLanguage,
}: LanguagesComponentProps) => {
  const { control } = useFormContext<any>();
  const { t } = useTranslation();

  return (
    <>
      <Col xs={12} className="mt-5">
        <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {t(`${keyPrefix}.language.title`)}
        </h5>
        <p className="fs-16 m-0"> {t(`${keyPrefix}.language.subtitle`)}</p>
      </Col>
      {Array.from({ length: languageLength }).map((_, index) => {
        return (
          <Col xs={12} key={`lang-${index}`}>
            <Row>
              <Col xs={12} md={6}>
                <Select
                  label={t(`${keyPrefix}.language.selectLanguage`)}
                  placeholder={t(`${keyPrefix}.language.selectLanguage`)}
                  options={languages}
                  name={`languages-${index}`}
                  control={control}
                  rules={{ required: t(`common.validations.required`) }}
                />
              </Col>
              <Col xs={9} md={5}>
                <Select
                  label={t(`${keyPrefix}.language.selectLevel`)}
                  placeholder={t(`${keyPrefix}.language.selectLevel`)}
                  options={languageLevels}
                  name={`languageLevels-${index}`}
                  control={control}
                  rules={{ required: t(`common.validations.required`) }}
                />
              </Col>
              {index === languageLength - 1 && (
                <Col xs={3} md={1}>
                  <Button
                    className="w-100"
                    icon="trash"
                    outlined
                    onClick={() => onRemoveLanguage && onRemoveLanguage(index)}
                  />
                </Col>
              )}
            </Row>
          </Col>
        );
      })}
      <Col xs={12}>
        <Button
          text={t(`${keyPrefix}.language.addLanguage`)}
          outlined
          size="sm"
          style={{ minWidth: 199.81 }}
          icon="plus"
          className="float-right"
          onClick={onAddLanguage}
        />
      </Col>
    </>
  );
};

export default LanguagesComponent;
