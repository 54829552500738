import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { PathsLayouts } from '../../config/routes';
import Sections from './sections';
import Subjects from './subjects';
import { useLocation, useHistory } from 'react-router-dom';

interface TabComponentProps {
  currentTab: string;
  onTabChange: (tabId: string | number) => void;
}

const TabComponent = memo(({ currentTab, onTabChange }: TabComponentProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.maintainers',
  });

  return (
    <Card className="mt-5">
      <Tabs defaultTab={currentTab} onSelection={onTabChange}>
        <TabsList>
          <Tab key="subjects" id="subjects">
            {t('subjects.title')}
          </Tab>
          <Tab key="sections" id="sections">
            {t('sections.title')}
          </Tab>
        </TabsList>
        <TabPanels>
          <TabPanel key="sections" id="sections">
            <Sections />
          </TabPanel>
          <TabPanel key="subjects" id="subjects">
            <Subjects />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
});

function Maintainers() {
  const location = useLocation();
  const history = useHistory();

  const getTabFromPath = useCallback(() => {
    return location.pathname.split('/').pop() || 'sections';
  }, [location.pathname]);

  const [currentTab, setCurrentTab] = useState(getTabFromPath);

  const handleTabChange = useCallback(
    (tabId: string | number) => {
      if (tabId !== currentTab) {
        const routeToGo = `${PathsLayouts.maintainer}/${tabId}`;
        history.replace(routeToGo);
        setCurrentTab(tabId as string);
      }
    },
    [currentTab, history],
  );

  useEffect(() => {
    setCurrentTab(getTabFromPath());
  }, [location.pathname, getTabFromPath]);

  return <TabComponent currentTab={currentTab} onTabChange={handleTabChange} />;
}

export default Maintainers;
