import { ColumnTable, Icon } from '@octano/global-ui';

export default function useColumns({
  onClickEdit,
  onClickRemove: onRemove,
}: {
  onClickEdit: (row: any) => void;
  onClickRemove: (row: any) => void;
}): ColumnTable[] {
  return [
    {
      columnName: 'name',
      headerText: 'Nombre',
      tdClassName: 'text-left pl-5',
      thClassName: 'text-center',
    },
    {
      columnName: 'shortName',
      headerText: 'Sigla',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'subject.name',
      headerText: 'Módulo',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat(options) {
        return options.row.subject?.name;
      },
    },
    {
      columnName: 'subject.carrer.name',
      headerText: 'Carrera',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat(options) {
        return options.row.subject?.career?.name;
      },
    },
    {
      columnName: 'workingDay.name',
      headerText: 'Jornada',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat(options) {
        return options.row.workingDay?.name;
      },
    },
    {
      columnName: 'campus.name',
      headerText: 'Sede',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat(options) {
        return options.row.campus?.name;
      },
    },
    {
      columnName: 'Acciones',
      headerText: 'Acciones',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button className="btn" onClick={() => onRemove(row)}>
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    },
  ];
}
