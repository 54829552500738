import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { HiringSliceState } from './types';
// import {AppState} from '@store/store';
// import {HiringSliceState} from '@store/types/types.auth';

const hiringSliceSelector = (state: AppState): HiringSliceState => state.hiring;

export const selectedsSelector = createSelector(
  hiringSliceSelector,
  (state: HiringSliceState) => state?.selecteds || [],
);
