import { CheckNames } from '../../../../types/GenericFormTypes';
import { CurrentStep } from '../../../../types/stepsTypes';
import { setValues } from '../utils';

export const createFillFormObject = (offerData: CurrentStep['offer']) => {
  const fillForm: { [key: string]: any } = {};
  setValues(
    offerData?.applicationStages,
    CheckNames.ApplicationStages,
    fillForm,
  );
  return fillForm;
};
