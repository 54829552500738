import { ColumnTable, Icon } from '@octano/global-ui';
import { Subject } from '../type';

interface Props {
  onClickEdit: (row: Subject) => void;
  onClickRemove: (row: Subject) => void;
}

export default function useColumns({
  onClickEdit,
  onClickRemove,
}: Props): ColumnTable<Subject>[] {
  return [
    {
      columnName: 'name',
      headerText: 'Nombre',
      tdClassName: 'text-left pl-5',
      thClassName: 'text-center',
    },
    {
      columnName: 'career.name',
      headerText: 'Carrera',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat(options) {
        return options.row.career?.name;
      },
    },

    {
      columnName: 'Acciones',
      headerText: 'Acciones',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button className="btn" onClick={() => onClickRemove(row)}>
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    },
  ];
}
