import { useCallback } from 'react';
import { createSection, deleteSection, updateSection } from '../api';

interface UseActionsParams {
  createSection: any;
  updateSection: { sectionId: number; data: any };
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createSectionAction = useCallback(
    async (data: UseActionsParams['createSection']) => {
      const result = await createSection(data);
      if (!result.error) {
        return props.onSuccess('create');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateSectionAction = useCallback(
    async (params: UseActionsParams['updateSection']) => {
      const result = await updateSection(params.sectionId, params.data);
      if (!result.error) {
        return props.onSuccess('update');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteSectionAction = useCallback(
    async (sectionId: number) => {
      const result = await deleteSection(sectionId);
      if (!result.error) {
        return props.onSuccess('delete');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createSection: createSectionAction,
    updateSection: updateSectionAction,
    deleteSection: deleteSectionAction,
  };
}
