import request from '../../../api/request';
import {
  SectionFiltersQuery,
  SubjectFiltersResponse,
  SectionsResponse,
} from './type';

const SUBJECT_MAINTAINER = {
  FORM_DATA: '/mantainer-portal/maintainers/subjects/form-data',
  GET: '/mantainer-portal/maintainers/subjects',
  POST: '/mantainer-portal/maintainers/subjects',
  PUT: (sectionId: number) =>
    `/mantainer-portal/maintainers/subjects/${sectionId}`,
  DELETE: (sectionId: number) =>
    `/mantainer-portal/maintainers/subjects/${sectionId}`,
};

export function getSubjectsList(params: SectionFiltersQuery) {
  return request<SectionsResponse>(SUBJECT_MAINTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}
export function getFormData() {
  return request<SubjectFiltersResponse>(SUBJECT_MAINTAINER.FORM_DATA, {
    method: 'GET',
  });
}

export function createSubject(data: any) {
  return request(SUBJECT_MAINTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateSubject(subjectId: number, data: any) {
  return request(SUBJECT_MAINTAINER.PUT(subjectId), {
    method: 'PATCH',
    data,
  });
}

export function deleteSubject(subjectId: number) {
  return request(SUBJECT_MAINTAINER.DELETE(subjectId), {
    method: 'DELETE',
  });
}
