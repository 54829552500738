import { Button, Icon, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Subject } from '../../type';

interface Props {
  onConfirm: (data: Subject) => void;
}

export interface ConfirmModalRef {
  openModal: (row: Subject) => void;
  closeModal: () => void;
}

const ConfirmDeleteModal = forwardRef<ConfirmModalRef, Props>(
  ({ onConfirm }, ref) => {
    const [data, setData] = useState<Subject>();

    const [modal, setModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal(row: Subject) {
        setData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="md">
        <Container fluid className="text-center">
          <Icon name="information" size={60} />
          <h1 className="text-dark fs-20 mt-3 mb-4">
            ¿Realmente quieres eliminar este módulo?
          </h1>

          <Row className="d-flex justify-content-around mt-5">
            <Col xs={12} md={6}>
              <Button
                text="Aceptar"
                onClick={() => {
                  onConfirm(data!);
                  setModal(false);
                }}
                fullwidth
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                outlined
                text="Cancelar"
                onClick={() => {
                  setModal(false);
                }}
                fullwidth
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  },
);

export default ConfirmDeleteModal;
