import { Button, ColumnTable } from '@octano/global-ui';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Legend from '../../../../components/Legend/Legend';
import { CurrentStep, PostulationInformation } from '../types';

const keyPrefix = 'views.recruitment.detail';

export default function usePostulantColumns({
  offerId,
  offerName,
  isSelectedsTable = false,
}: {
  offerId: string;
  offerName: string;
  isSelectedsTable?: boolean;
}) {
  const { t } = useTranslation('translation', { keyPrefix });

  const history = useHistory();

  const steps: Record<CurrentStep, { color: string; text: string }> = useMemo(
    () => ({
      [CurrentStep.Discarded]: {
        color: '#F2C0BD',
        text: t('legends.discarded'),
      },
      [CurrentStep.Review]: {
        color: '#FFE6A1',
        text: t('legends.inReview'),
      },
      [CurrentStep.Approved]: {
        color: '#CEF0DA',
        text: t('legends.approved'),
      },
      [CurrentStep.Hired]: {
        color: '#FFFFFF',
        text: t('legends.hired'),
      },
      [CurrentStep.FileUpload]: {
        color: '#FFFFFF',
        text: t('legends.fileUpload'),
      },
      [CurrentStep.Resume]: {
        color: '#FFFFFF',
        text: t('legends.resume'),
      },
      [CurrentStep.Received]: {
        color: '#ABCCE9',
        text: t('legends.received'),
      },
    }),
    [t],
  );

  const columns = useMemo<ColumnTable<PostulationInformation>[]>(() => {
    return [
      {
        columnName: 'offerName',
        headerText: t('offer'),
        tdClassName: 'pl-5',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <p className=" m-0">{offerName}</p>
          </div>
        ),
      },
      {
        columnName: 'createdAt',
        headerText: t('applicationDate'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'user.name',
        headerText: t('postulant'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <p className=" m-0">{row.user.name}</p>
            <p>{row.user.rut}</p>
          </div>
        ),
      },
      {
        columnName: 'action',
        headerText: t('applicationState'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <Legend
            color={steps[row.currentStep].color}
            text={steps[row.currentStep].text}
          />
        ),
      },
      {
        columnName: 'action',
        headerText: t('action'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <Button
              onClick={() => {
                history.push(
                  !isSelectedsTable
                    ? `/recruitment/offer-detail/${offerId}/postulation/${row.id}`
                    : `/recruitment/offer-detail/${offerId}/postulation/${row.id}/details`,
                );
              }}
              text={t(
                !isSelectedsTable ? 'evaluateApplication' : 'showPostulation',
              )}
              size="sm"
            />
          </div>
        ),
      },
    ];
  }, [history, isSelectedsTable, offerId, offerName, steps, t]);

  return columns;
}
