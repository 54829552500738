import { useEffect } from 'react';
import PostulationDetailsForm from '../../../recruitment/postulation-review/review/parts/PostulationDetailsForm';
import { useGetPostulationBaseInformationQuery } from '../../api';

// Types
type PostulationResumenTabProps = {
  userId: string | number;
  postulationId: string | number;
  onChangeDate?: (next?: Date) => void;
};

// Render
const PostulationResumenTab = ({
  userId,
  postulationId,
  onChangeDate,
}: PostulationResumenTabProps) => {
  const {
    data = {
      name: '',
      studyPlan: '',
      user: { name: '', rut: '' },
      educationalLevel: [],
      laws: [],
      answer: [],
      certifications: [],
      curriculum: [],
      licenses: [],
      collegeDegrees: [],
      availabilityDate: '',
      availabilityTime: '',
      salaryPretension: undefined,
      updatedAt: undefined,
    },
    isFetching,
    error,
  } = useGetPostulationBaseInformationQuery(
    {
      postulationId,
      userId,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    onChangeDate && onChangeDate(data?.updatedAt);
  }, [data?.updatedAt, onChangeDate]);

  return (
    <div className="w-100 py-4">
      <PostulationDetailsForm
        data={{
          ...data,
          updatedAt: data?.updatedAt ? data?.updatedAt : new Date(),
        }}
        isFetching={isFetching}
        error={error}
      />
    </div>
  );
};

export default PostulationResumenTab;
