// Components
import { Collapse, OutlinedSelect } from '@octano/global-ui';
import { Col } from 'reactstrap';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { AssingSectionPostulation } from '../types';
import { useCallback, useState } from 'react';
import { usePostulationSectionsQuery } from '../api';
import { SectionItem } from '../index';

// Types
type SelectedUserRowProps = {
  item: AssingSectionPostulation;
  sections?: SectionItem[];
  onChangeSections?: (postulationId: number, next: SectionItem[]) => void;
};

const keyPrefix = 'views.hiring.assingSections';

// Render
const SelectedUserRow = (props: SelectedUserRowProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { item, sections = [], onChangeSections } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: items = [] } = usePostulationSectionsQuery(
    {
      subjectId: item?.offer?.subjectId,
      periodId: item?.offer?.periodId,
      campusId: item?.offer?.campusId,
      workingDayId: item?.offer?.workingDayId,
      schoolId: item?.offer?.schoolId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !item?.offer?.subjectId || !isOpen,
    },
  );

  const handleChangeSections = useCallback(
    (nexts: SectionItem[] = []) => {
      if (nexts?.length) {
        onChangeSections && onChangeSections(item?.id, nexts);
      } else {
        onChangeSections && onChangeSections(item?.id, []);
      }
    },
    [item?.id, onChangeSections],
  );

  return (
    <Collapse
      renderTitle={() => (
        <>
          <Col>
            <span className="text-center w-100">{item?.user?.name}</span>
            <br />
            <span className="text-center w-100">{item?.user?.id}</span>
          </Col>
          <Col>
            <span className="text-center w-100">{item?.offer?.name}</span>
          </Col>
          <Col>
            <span className="text-center w-100">
              {`${sections?.length || 0}/${item?.offer?.vacant?.total || 0}`}
            </span>
          </Col>
        </>
      )}
      headerClassName="row"
      renderBtn={({ isOpened, toggle }) => (
        <>
          <Col xs={1}>
            <div className="w-100">
              <Collapse.ToggleButton
                iconName={isOpened ? 'minus' : 'plus'}
                toggle={() => {
                  toggle();
                  setIsOpen(!isOpened);
                }}
                btnClassName="bg-primary mx-auto"
                iconColor="white"
              />
            </div>
          </Col>
        </>
      )}
    >
      <div className="w-100">
        <OutlinedSelect
          isMulti={true}
          name="sections"
          placeholder={t('table.search.label')}
          value={
            sections?.map((e) => ({
              label: e?.name,
              value: e?.sectionId,
            })) as any
          }
          options={items?.map((e) => ({
            label: e?.name,
            value: e?.id,
          }))}
          onChange={(nexts) =>
            handleChangeSections(
              nexts?.map(
                (e: any): SectionItem => ({
                  postulationId: item?.id,
                  sectionId: e?.value,
                  name: e?.label,
                }),
              ),
            )
          }
        />
      </div>
    </Collapse>
  );
};

export default SelectedUserRow;
