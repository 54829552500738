// Components
import HiringHistoryFilters, {
  OnFilterParams as Filters,
} from './parts/Filters';
import DisplayError from '../../../components/info/DisplayError';
import { Dialog, DisplayInfo, Table } from '@octano/global-ui';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import UseHiringHistoryColumns from './hooks/useHiringHistoryColumns';

// Redux
import { useGetHistoryDataQuery } from '../api';

// Types
const ITEMS_PER_PAGE = 10;
const keyPrefix = 'views.hiring.history';

const HiringhistoryScreen = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  const {
    data: postulantsData,
    isFetching,
    error,
  } = useGetHistoryDataQuery(
    {
      ...filters,
      page: currentPage - 1,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const postulants = useMemo(
    () => postulantsData?.data || [],
    [postulantsData?.data],
  );
  const totalPostulants = useMemo(
    () => postulantsData?.total || 0,
    [postulantsData?.total],
  );
  const totalPages = useMemo(
    () => postulantsData?.totalPages || 0,
    [postulantsData?.totalPages],
  );

  const handleFilter = useCallback(
    (params: Filters) => {
      setCurrentPage(1);
      setFilters(params);
    },
    [setFilters],
  );

  const columns = UseHiringHistoryColumns();

  return (
    <>
      <Card className="p-4">
        <Row>
          <Col className="mb-4" xs={12}>
            <p className="fs-20 fw-700 text-primary">
              {t(`${keyPrefix}.title`)}
            </p>
            <p className="fs-16">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>
        <HiringHistoryFilters onFilter={handleFilter} />
        <Row className="mt-3">
          <Col xs={12} className="pt-1 mb-5 g-table-hidden-x">
            <Table
              columns={columns}
              data={!isFetching ? postulants : []}
              isLoadingResults={isFetching}
              loadingView={{
                title: t(`common.loading.title`),
                subtitle: t(`common.loading.subtitle`),
              }}
              noResultsText={
                (error as any) ? (
                  <DisplayError typeError={(error as any)?.status} />
                ) : (
                  <div className="d-flex flex-column align-items-center no-result">
                    <DisplayInfo
                      title={t(`${keyPrefix}.empty.title`)}
                      textBody={t(`${keyPrefix}.empty.body`)}
                    />
                  </div>
                )
              }
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                onChangePage: (page: number) => {
                  setCurrentPage(page);
                },
                totalItems: totalPostulants,
                totalPages: totalPages,
              }}
            />
          </Col>
        </Row>
      </Card>
      <Dialog />
    </>
  );
};

export default HiringhistoryScreen;
