import { createSlice } from '@reduxjs/toolkit';

// Types
import { PayloadAction } from '@reduxjs/toolkit';
import { HiringSliceState } from './types';

const initialState: HiringSliceState = {
  selecteds: [],
};

export const hiringSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSelectsItems(state, action: PayloadAction<number[]>) {
      state.selecteds = action.payload;
    },
  },
});
