// Components
import { Row, Col } from 'reactstrap';
import { RemoteSelect } from '@octano/global-ui';

// Hooks
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

// Types
import { ViewFinder } from '../../types';
import { useSearchViewfindersMutation } from '../../../account/api';

type ContractViewfindersSearchProps = {
  title?: string;
  keyPrefix?: string;
  onPushItem?: (item: ViewFinder) => void;
};

// Render
const ContractViewfindersSearch = ({
  title,
  keyPrefix,
  onPushItem,
}: ContractViewfindersSearchProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const [searchContractviewfinders] = useSearchViewfindersMutation();

  const handleSearch = useCallback(
    async (search: string) => {
      try {
        if (search?.trim()) {
          const response = await searchContractviewfinders(
            search?.trim(),
          )?.unwrap();
          return (
            response?.map((item) => ({
              label: `${item?.fullName} - ${item?.rut} (${item?.email})`,
              value: `${item?.id}|${item?.fullName}|${item?.rut}|${item?.email}`,
            })) || []
          );
        }
        return [];
      } catch (_error) {
        return [];
      }
    },
    [searchContractviewfinders],
  );

  const handlePushItem = useCallback(
    (next?: ViewFinder | null) => {
      if (next?.id) {
        onPushItem && onPushItem(next);
      }
    },
    [onPushItem],
  );

  return (
    <Row className="mx-2">
      {Boolean(title?.trim()?.length) && (
        <Col xs={12} className="mb-1">
          <span className="w-100 d-block fs-16 fw-700 text-primary text-uppercase">
            {title?.trim()}
          </span>
        </Col>
      )}
      <Col xs={12} className="mb-1">
        <RemoteSelect
          value={{ value: '', label: '' }}
          name="search"
          label={t('searchByPlaceholder')}
          loadingMessage={t('loading')}
          noOptionsMessage={t('noResults')}
          placeholder={t('searchByPlaceholder')}
          placeholderSearchText={t('searchByPlaceholder')}
          filterOptions={handleSearch}
          onChange={(next) =>
            handlePushItem({
              id: parseInt(next?.value?.split('|')[0], 10),
              name: next?.value?.split('|')[1],
              rut: next?.value?.split('|')[2],
              email: next?.value?.split('|')[3],
            })
          }
        />
      </Col>
    </Row>
  );
};

export default ContractViewfindersSearch;
