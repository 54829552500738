// Components
import { Button, Collapse, addToast } from '@octano/global-ui';
import { Card, Row, Col } from 'reactstrap';
import ContractViewfindersSearch from './parts/ContractViewfindersSearch';
import ContractViewfindersBadge from './parts/ContractViewfindersBadge';

// Hooks
import useUserState from '../../../hooks/useUserState';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'rut.js';
import {
  useGetContractViewfinderQuery,
  useSaveContractViewfinderSettingMutation,
} from '../api';
import {
  useGetViewfindersQuery,
  useSaveContractviewfindersUsersMutation,
} from '../../account/api';

// Utils
import { isUserAuthorizedTo } from '../../../utils/auth';

// Types
import { ViewFinder, ViewfinderType } from '../types';
import { PermissionName } from '../../../types/auth';
import ContractViewfindersRowInputs from './parts/ContractViewfindersRowInputs';

export type Viewer = {
  campusId?: number;
  title?: string;
  viewfinder?: ViewFinder;
};

export type FormFields = {
  othersViewers: Viewer[];
  teachingViewers: Viewer[];
};

const keyPrefix = 'views.settings.contractViewfinders';

// Render
const ContractViewfindersScreen = () => {
  const { t } = useTranslation();

  const { userData } = useUserState();

  //obtiene un array de sedes con sus visadores y valor hora
  const {
    data: contractData = [],
    isFetching,
    refetch,
  } = useGetContractViewfinderQuery();

  //obtiene los usuarios con rol visadores
  const { data: viewfindersData = [] } = useGetViewfindersQuery();

  const [saveViewfinders, { isLoading: isSaving }] =
    useSaveContractViewfinderSettingMutation();

  const [saveViewfindersUsers, { isLoading: isSavingUsers }] =
    useSaveContractviewfindersUsersMutation();

  const updateSettings = useMemo(
    () =>
      isUserAuthorizedTo(
        userData?.permissions || [],
        [PermissionName.updateSettings],
        true,
      ),
    [userData?.permissions],
  );

  const { control, handleSubmit, reset } = useForm<FormFields>({
    mode: 'onChange',
  });

  const [viewfinders, setViewfinders] = useState<ViewFinder[]>([]);

  useEffect(() => {
    if (viewfindersData.length) {
      setViewfinders(viewfindersData);
    }
  }, [viewfindersData]);

  useEffect(() => {
    if (contractData?.length) {
      const teachingViewers = contractData.map<Viewer>(
        ({ id, name, campusContract }) => {
          return {
            campusId: id,
            title: name,
            viewfinder:
              campusContract?.viewfinders?.find(
                (e) => e?.type === ViewfinderType.TeachingViewer,
              ) || ({ type: ViewfinderType.TeachingViewer } as ViewFinder),
          };
        },
      );

      const othersViewers = [
        {
          type: ViewfinderType.HumanResourcesViewer,
          title: t(`${keyPrefix}.rrhhRole`),
        },
        {
          type: ViewfinderType.LegalRepresentative,
          title: t(`${keyPrefix}.legalRole`),
        },
      ].map<Viewer>((e) => {
        const a = contractData[0].campusContract?.viewfinders?.find(
          (a) => a.type === e.type,
        );
        return {
          title: e.title,
          viewfinder: a || ({ type: e.type } as ViewFinder),
        };
      });

      reset({ teachingViewers, othersViewers });
    }
  }, [contractData, reset, t]);

  const handleSave = useCallback(
    async (payload: FormFields) => {
      if (isFetching || isSaving || isSavingUsers) {
        return;
      }
      if (!viewfinders?.length) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.academicsRequireds`),
        });
        return;
      }

      const result = payload.teachingViewers.map((e) => {
        return {
          campusId: e.campusId!,
          viewfinders: [
            e.viewfinder!,
            ...payload.othersViewers.map((e) => e.viewfinder!),
          ],
        };
      });

      try {
        await Promise.all([
          saveViewfindersUsers(viewfinders.map((e) => e?.id!))?.unwrap(),
          saveViewfinders(result)?.unwrap(),
        ]);
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${keyPrefix}.success`),
        });
        await refetch()?.unwrap();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.failed`),
        });
      }
    },
    [
      isFetching,
      isSaving,
      isSavingUsers,
      refetch,
      saveViewfinders,
      saveViewfindersUsers,
      t,
      viewfinders,
    ],
  );

  const handlePushItem = useCallback(
    (next: ViewFinder) => {
      if (!viewfinders?.some((i) => i?.id === next?.id)) {
        setViewfinders((prev) => prev?.concat([next]));
      }
    },
    [viewfinders],
  );

  const handleRemoveItem = useCallback((next: ViewFinder) => {
    setViewfinders((prev) => prev?.filter((e) => e.id !== next?.id));
  }, []);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Card>
        <Row className="mb-3 mt-4 mx-2">
          <Col xs={12} className="mb-3">
            <h3 className="fs-22 fw-700 text-primary text-uppercase">
              {t(`${keyPrefix}.title`)}
            </h3>
            <p className="fs-16 m-0">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>

        <Collapse
          headerClassName="mx-2"
          renderTitle={() => (
            <div>
              <span className="w-100 d-block fs-16 fw-700 text-primary text-uppercase">
                {t(`${keyPrefix}.teachingRole`)}
              </span>
              <span>
                Presiona la flecha hacia abajo para consultar los roles de
                visador docente.
              </span>
            </div>
          )}
          renderBtn={({ isOpened, toggle }) => (
            <Collapse.ToggleButton
              iconName={isOpened ? 'chevron_up' : 'chevron_down'}
              toggle={toggle}
              btnClassName="bg-white pt-0"
              btnStyles={{ width: 50 }}
              iconColor="primary"
            />
          )}
        >
          <ContractViewfindersRowInputs
            name="teachingViewers"
            control={control}
          />
        </Collapse>

        <ContractViewfindersRowInputs name="othersViewers" control={control} />

        <ContractViewfindersSearch
          title={t(`${keyPrefix}.academicsRoles`)}
          keyPrefix={`${keyPrefix}.search`}
          onPushItem={handlePushItem}
        />

        {Boolean(viewfinders.length) && (
          <Row className="mx-2">
            <Col xs={12}>
              <div className="d-flex flex-wrap w-100 align-items-center">
                {viewfinders?.map((item: any, i) => (
                  <ContractViewfindersBadge
                    key={`item-${i}`}
                    name={item.name}
                    email={item.email}
                    rut={format(item.rut)}
                    className="mx-2"
                    onRemove={() => handleRemoveItem(item)}
                  />
                ))}
              </div>
            </Col>
          </Row>
        )}

        {updateSettings && (
          <Row className="mx-2 mb-4">
            <Col xs={12}>
              <div className="d-flex w-100 justify-content-end align-items-center">
                <Button
                  type="submit"
                  loading={isFetching || isSaving || isSavingUsers}
                  text={t(`${keyPrefix}.save`)}
                />
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </form>
  );
};

export default ContractViewfindersScreen;
