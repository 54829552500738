// Components
import { Row, Col } from 'reactstrap';
import { CheckInput } from '@octano/global-ui';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { GetAuthorizedPostulatsFilters } from '../../types';
const keyPrefix = 'views.hiring.authorizedPostulants';

type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  name: string | null;
  offerId: ItemSelect | null;
  campusId: ItemSelect | null;
  careerId: ItemSelect | null;
  statusId: ItemSelect | null;
};

export type OnFilterParams = GetAuthorizedPostulatsFilters;

export type FilterFieldsSubmit = {
  name: string | null;
  offerId: { value: string };
  campusId: { value: string };
  careerId: { value: string };
  statusId: { value: string };
};

// Render
const AuthorizedPostulantHeader = ({
  isAllSelected,
  onToggleAll,
}: {
  isAllSelected: boolean;
  onToggleAll?: () => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Row className="mx-0">
      <Col>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: 38.5 }}
        >
          {t('table.postulantName')}
        </div>
      </Col>
      <Col>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: 38.5 }}
        >
          {t('table.postulantId')}
        </div>
      </Col>
      <Col>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: 38.5 }}
        >
          {t('table.postulationsCount')}
        </div>
      </Col>
      <Col>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: 38.5 }}
        >
          {t('table.authorizationsCount')}
        </div>
      </Col>
      <Col>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: 38.5 }}
        >
          {t('table.hiringRequires')}
        </div>
      </Col>
      <Col style={{ maxWidth: 125 }}>
        <div
          className="w-100 d-flex align-items-center"
          style={{ minHeight: 38.5 }}
        >
          <span className="mx-auto">{t('table.viewMore')}</span>
          <CheckInput
            name="check"
            label=""
            value={isAllSelected}
            onChange={onToggleAll}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AuthorizedPostulantHeader;
