import { Container } from 'reactstrap';
import SubjectFiltersLoader from './parts/Loaders/SubjectFiltersLoader';
import SubjectLoader from './parts/Loaders/SubjectLoader';
import SubjectsFilter from './parts/SubjectsFilter';
import SubjectsTable from './parts/SubjectsTable';
function Sections() {
  return (
    <Container fluid className="mt-4">
      <SubjectFiltersLoader>
        <SubjectLoader filterComponent={<SubjectsFilter />}>
          <SubjectsTable />
        </SubjectLoader>
      </SubjectFiltersLoader>
    </Container>
  );
}
export default Sections;
