import { Base } from '../../../../types/GenericFormTypes';

export function mapResumeItems<T = Base>(arr: T[], property: string): string {
  return arr
    .map((item: any) => {
      return item[property];
    })
    .join(', ');
}

export function mapLanguageItems<T = Base>(
  property: string,
  arr?: T[],
): Base[] {
  if (!arr) return [];

  return arr.map(({ language, languageLevel }: any) => {
    return {
      name: `${language[property]} - ${languageLevel[property]}`,
    } as Base;
  });
}
