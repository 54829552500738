// Components
import PostulantsAuthorizationRow from '../parts/PostulantToAuthorizeRow';
import PostulantsAuthorizationHeader from '../parts/PostulantToAuthorizeHeader';

// Hooks
import { useMemo } from 'react';

// Types
import { ColumnTable } from '@octano/global-ui';
import { PostulantToAuthorizeItem } from '../../types';
import { UsePostulantsAuthorizationPostulationsColumnsProps } from './usePostulantsAuthorizationPostulationsColumns';

// Hook
const UsePostulantsAuthorizationColumns = (
  props: UsePostulantsAuthorizationPostulationsColumnsProps,
) => {
  const columns = useMemo<ColumnTable<PostulantToAuthorizeItem>[]>(
    () => [
      {
        columnName: 'user',
        headerText: <PostulantsAuthorizationHeader />,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <PostulantsAuthorizationRow {...props} item={row} />
        ),
      },
    ],
    [props],
  );

  return columns;
};

export default UsePostulantsAuthorizationColumns;
