import PostulationReview from '../postulation-review/review';
import { Card } from 'reactstrap';

function PostulationDetail() {
  return (
    <Card className="p-4 mb-4">
      <PostulationReview hideNextButton={true} />
    </Card>
  );
}
export default PostulationDetail;
