// Components
import AddNewItemModal, {
  AddNewItemModalMethods,
} from '../../parts/AddNewItemModal';
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import RequirementStepComponent from './parts/RequirementStepComponent';
import Loading from '../../../../components/info/Loading';

import { Form, Row } from 'reactstrap';
import { useMobile } from '@octano/global-ui';

// Hooks
import useGetStepData from '../../hooks/useGetStepData';
import { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { Base } from '../../../../types/GenericFormTypes';
import { CreateOfferStepArea, OfferCreationStepProps } from '../../types';
import { CurrentStep } from '../../../../types/stepsTypes';
import { RequirementApplicantStepForm } from './types';

type RequirementApplicantStepProps = OfferCreationStepProps & {};
type RequirementApplicantStepData = {
  questions: Base[];
  requests: Base[];
};

const keyPrefix = 'views.addOffer.offerQuestions';
const area = CreateOfferStepArea.RequirementApplicant;

// Render
const RequirementApplicantStep = ({
  backRequireConfimation = false,
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: RequirementApplicantStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const isMobile = useMobile();

  const stepWrapperRef = useRef<StepsWrappeMethods>(null);
  const addItemRef = useRef<AddNewItemModalMethods>(null);

  const { data: offerData, isFetching: isFetchingOffer } =
    useGetStepData<CurrentStep>(offerDraftId);

  const { data: questionsData, isFetching: isFetchingQuestions } =
    useGetStepData<RequirementApplicantStepData>(offerDraftId, area);

  const methods = useForm<RequirementApplicantStepForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { getValues, reset, clearErrors } = methods;

  const { append: AppendQuestion } = useFieldArray({
    control: methods.control,
    name: 'questions',
  });

  const { append: AppendRequest } = useFieldArray({
    control: methods.control,
    name: 'requests',
  });

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (onSubmit) {
        const values = getValues();

        await onSubmit({
          area,
          nextStep,
          payload: {
            questions: values.questions
              .filter((q) => Boolean(q.isMark))
              .map((q) => q.id),
            requests: values.requests
              .filter((q) => Boolean(q.isMark))
              .map((r) => r.id),
          },
          next,
          successMsg: t('onSave.success'),
          failedMsg: t('onSave.error'),
        });
      }
    },
    [getValues, nextStep, onSubmit, t],
  );

  useEffect(() => {
    if (questionsData && offerData) {
      reset({
        requests: questionsData.requests.map((r) => {
          return {
            ...r,
            isMark: offerData?.offer.requests?.some((o) => o === r.id),
          };
        }),
        questions: questionsData.questions.map((q) => {
          return {
            ...q,
            isMark: offerData?.offer.questions?.some((o) => o === q.id),
          };
        }),
      });
    }
  }, [
    offerData,
    questionsData,
    questionsData?.questions,
    questionsData?.requests,
    reset,
  ]);

  if (isFetchingQuestions || isFetchingOffer) {
    return <Loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={methods?.handleSubmit(handleSave)}
          onChange={() => clearErrors(['questions', 'requests'])}
        >
          <StepsWrapper
            keyPrefix={keyPrefix}
            ref={stepWrapperRef}
            offerDraftId={offerDraftId}
            onSave={onSave}
            prevStep={prevStep}
            backRequireConfimation={backRequireConfimation}
            description={t('description')}
            buttonText={t('goBackToPrevious')}
          >
            <Row>
              <RequirementStepComponent
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
              />
              <BottomButtons
                buttons={[
                  {
                    outlined: true,
                    color: 'danger',
                    text: t('actions.cancel'),
                    onClick: () => stepWrapperRef?.current?.prev(prevStep),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                  },
                  {
                    outlined: true,
                    text: t('actions.save'),
                    onClick: () => handleSave({ next: false }),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                    disabled: isSubmitting,
                  },
                  {
                    type: 'submit',
                    text: t('actions.next'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
                hint={t('infoOnClickNext')}
              />
            </Row>
          </StepsWrapper>
        </Form>
      </FormProvider>
      <AddNewItemModal
        ref={addItemRef}
        onSuccess={(type, item) => {
          if (type === 'request') {
            AppendRequest(item);
          }
          if (type === 'question') {
            AppendQuestion(item);
          }
        }}
      />
    </>
  );
};

export default RequirementApplicantStep;
