import { addToast, Button, Modal, TextInput } from '@octano/global-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { sendActivationEmail } from '../../../api/requests/auth';
import { useValidations } from '../../../hooks/useValidations';
import { AccountValidationFields } from '../../../types/auth';
import AccountContainer from '../shared/AccountContainer';

interface Props {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export default function ActivateAccountModal({
  isOpen,
  onSuccess,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.activate`;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<AccountValidationFields>({ mode: 'onChange' });

  const { validateTextNotEmpty, validateEmail } = useValidations();

  const handleSubmitValidation = async (values: AccountValidationFields) => {
    const { error } = await sendActivationEmail(values.email);

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      onSuccess();
    }
    reset({ email: undefined });
  };

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <AccountContainer
        icon="mailing"
        title={t(`${prefix}.title`)}
        subTitle={t(`${prefix}.description`)}
        showLogo={false}
      >
        <form onSubmit={handleSubmit(handleSubmitValidation)}>
          <Row>
            <Col xs={12} className="pb-3">
              <TextInput
                name="email"
                type="text"
                control={control}
                label={t('common.terms.email')}
                rules={{
                  validate: {
                    empty: validateTextNotEmpty,
                    email: validateEmail,
                  },
                }}
              />
            </Col>
            <Col sm={12} md={6} className="mb-4">
              <Button
                type="button"
                text={t(`common.actions.cancel`)}
                onClick={onClose}
                fullwidth
                outlined
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.activate`)}
                fullwidth
              />
            </Col>
          </Row>
        </form>
      </AccountContainer>
    </Modal>
  );
}
