import {
  ColumnTable,
  Icon,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { OfferDetail, OfferType } from '../../../../types/jobOffer';

export function useOfferColumns(
  values: OutlinedSelectOptionType[],
  updateStateFunction: (...args: any) => void | Promise<void>,
  onRequestClone?: (item: OfferDetail) => void,
) {
  const { t } = useTranslation();

  const history = useHistory();

  const goToOffer = useCallback(
    (offerId: number) =>
      history.push(`/offer-maintainer/published-offers/${offerId}`),
    [history],
  );
  const cloneOffer = useCallback(
    (item: OfferDetail) => onRequestClone && onRequestClone(item),
    [onRequestClone],
  );

  const columns = useMemo<ColumnTable<OfferDetail>[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: t(`common.terms.offerName`),
        tdClassName: 'pl-5',
        thClassName: 'text-center',
      },
      {
        columnName: 'campus',
        headerText: t(`common.terms.campus`),
        thClassName: 'text-center',
        width: '200px',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.campus?.name}
          </div>
        ),
      },
      {
        columnName: 'career',
        headerText: t(`common.terms.career`),
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.career?.name}
          </div>
        ),
      },
      {
        columnName: 'workingDay',
        headerText: t(`common.terms.workingDay`),
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.workingDay?.name}
          </div>
        ),
      },
      {
        columnName: 'updatedAt',
        headerText: t(`common.terms.updateAt`),
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'type',
        headerText: t(`common.terms.offerType`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <div className="d-flex justify-content-center align-center flex-row align-items-center">
            {value === OfferType.Public && (
              <Icon name="lock_open_right" size={18} color="primary" />
            )}
            {value === OfferType.Private && (
              <Icon name="padlock" size={18} color="primary" />
            )}
            <p className="ml-2 mr-0 my-0">
              {t(`views.draftMaintainer.offerTypeStatuses.${value}`)}
            </p>
          </div>
        ),
      },
      {
        columnName: 'offerState',
        headerText: t(`common.terms.offerState`),

        cellFormat: ({ row }) => {
          const defaultValue = values.find(
            (state) => state.value === row.statusOffer.id,
          );
          return (
            <div
              style={{ width: '200px', textAlign: 'center' }}
              className="mx-auto"
            >
              <OutlinedSelect
                name="offerState"
                options={values}
                value={defaultValue}
                isClearable={false}
                isSearchable={false}
                onChange={(e) =>
                  updateStateFunction({ statusId: e.value }, row.id)
                }
              />
            </div>
          );
        },
        thClassName: 'text-center',
      },
      {
        columnName: 'viewOffer',
        headerText: 'Ver oferta',
        cellFormat: ({ row }) => {
          return (
            <div>
              <span onClick={() => goToOffer(row.id)}>
                <Icon name="eye" size={18} />
              </span>
            </div>
          );
        },
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'action',
        headerText: t(`common.terms.action`),
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex justify-content-center" style={{ gap: 12 }}>
              {/* <span onClick={() => goToOffer(row.id)}>
                <Icon name="edit" />
              </span> */}
              <span onClick={() => cloneOffer(row)}>
                <Icon name="certificates_requests" />
              </span>
              {/* <span onClick={() => goToOffer(row.id)}>
                <Icon name="trash" />
              </span> */}
            </div>
          );
        },
        thClassName: 'text-center',
      },
    ];
  }, [goToOffer, cloneOffer, t, updateStateFunction, values]);

  return columns;
}
