import { createContext } from 'react';
import { AuthorizedPostulantsProviderValue } from '../views/hiring/types';

export const AuthorizedPostulantsContext =
  createContext<AuthorizedPostulantsProviderValue>({
    selecteds: [],
    onToggle: (id: number) => {},
  });

type AuthorizedPostulantsProviderProps = {
  children?: React.ReactNode;
  value: AuthorizedPostulantsProviderValue;
};

const AuthorizedPostulantsProvider = ({
  children,
  value,
}: AuthorizedPostulantsProviderProps) => {
  return (
    <AuthorizedPostulantsContext.Provider value={value}>
      {children}
    </AuthorizedPostulantsContext.Provider>
  );
};

export default AuthorizedPostulantsProvider;
