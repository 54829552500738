import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';

// Services
import { offerDraftApi } from '../views/draftMaintainer/api';
import { offersMaintainerApi } from '../views/offers-maintainer/api';
import { recruitmentApi } from '../views/recruitment/api';
import { academicAuthorizationApi } from '../views/academic-authorization/api';
import { hiringApi } from '../views/hiring/api';
import { requiredFilesApi } from '../views/required-files/api';
import { assignSectionApi } from '../views/assign-section/api';
import { settingsApi } from '../views/settings/api';
import { createOfferApi } from '../views/createOffers/api';
import { accountApi } from '../views/account/api';

// Slices
import { hiringSlice } from '../views/hiring/slice';
import { contractApi } from '../views/contract/api';

const store = configureStore({
  reducer: combineReducers({
    // Add the generated reducer as a specific top-level slice
    // Slices
    hiring: hiringSlice.reducer,
    // Services
    [accountApi.reducerPath]: accountApi.reducer,
    [assignSectionApi.reducerPath]: assignSectionApi.reducer,
    [academicAuthorizationApi.reducerPath]: academicAuthorizationApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [createOfferApi.reducerPath]: createOfferApi.reducer,
    [offerDraftApi.reducerPath]: offerDraftApi.reducer,
    [recruitmentApi.reducerPath]: recruitmentApi.reducer,
    [offersMaintainerApi.reducerPath]: offersMaintainerApi.reducer,
    [hiringApi.reducerPath]: hiringApi.reducer,
    [requiredFilesApi.reducerPath]: requiredFilesApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
  }),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(accountApi.middleware)
      .concat(assignSectionApi.middleware)
      .concat(academicAuthorizationApi.middleware)
      .concat(contractApi.middleware)
      .concat(createOfferApi.middleware)
      .concat(offerDraftApi.middleware)
      .concat(recruitmentApi.middleware)
      .concat(offersMaintainerApi.middleware)
      .concat(hiringApi.middleware)
      .concat(requiredFilesApi.middleware)
      .concat(settingsApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;

export default store;
