import { Base } from '../../../../types/GenericFormTypes';
import { CurrentStep } from '../../../../types/stepsTypes';

interface List {
  educations: Base[];
  competences: Base[];
  certifications: Base[];
  licenseTypes: Base[];
  requirements: Base[];
}

interface FillFormObject {
  [key: string]: any;
}

export const createFillFormObject = (
  offerData: CurrentStep['offer'],
  list: List,
): FillFormObject => {
  const fillForm: FillFormObject = { knowledge: offerData?.knowledge };

  const mapWithMark = (
    list: Base[],
    dataKey: keyof CurrentStep['offer'],
  ): Base[] =>
    list.map((r) => ({
      ...r,
      isMark: Boolean(offerData?.[dataKey]?.some((o: any) => o === r.id)),
    }));

  fillForm['educations'] = mapWithMark(list.educations, 'educations');
  fillForm['competences'] = mapWithMark(list.competences, 'competences');
  fillForm['licenseTypes'] = mapWithMark(list.licenseTypes, 'licenseTypes');
  fillForm['requirements'] = mapWithMark(list.requirements, 'requirements');
  fillForm['certifications'] = mapWithMark(
    list.certifications,
    'certifications',
  );

  //faltaría llevar los lenguajes a la forma correcta (useFieldsArray)
  if (offerData?.languageLevels) {
    offerData.languageLevels.forEach((item: any, index: number) => {
      fillForm[`languages-${index}`] = item.language;
      fillForm[`languageLevels-${index}`] = item.languageLevel;
    });
  }
  return fillForm;
};

export const filterSelectedLanguages = (
  languageLength: number,
  values: FillFormObject,
): { language: string; languageLevel: string }[] => {
  return Array.from({ length: languageLength })
    .map((_, index) => {
      const language = values[`languages-${index}`];
      const languageLevel = values[`languageLevels-${index}`];
      if (language && languageLevel) return { language, languageLevel };
      return undefined;
    })
    .filter(
      (item): item is { language: string; languageLevel: string } =>
        !!item && !!item.language && !!item.languageLevel,
    );
};

export function filterAndMapIds(items: Base[]) {
  return items.filter((e) => Boolean(e.isMark)).map((e) => e.id);
}
