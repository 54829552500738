import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Row } from 'reactstrap';

import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import Description from '../../../components/steps/Description';
import List from '../../../components/steps/List';
import { useGetOfferDetailDataQuery } from '../api';
import HeaderDetail from './parts/HeaderDetail';
import { mapLanguageItems } from './parts/utils';
import InvitedsBox from './parts/InvitedsBox';

interface ParamsType {
  offerId: string;
}

const prefix = 'views.offersMaintainer.viewOffer';

export default function JobDetailRoute() {
  const { offerId } = useParams<ParamsType>();
  const { t } = useTranslation();

  const {
    data: offer,
    error,
    isLoading,
  } = useGetOfferDetailDataQuery(offerId, { refetchOnMountOrArgChange: true });

  const languages = useMemo(
    () => mapLanguageItems('name', offer?.offerLanguageLevels),
    [offer?.offerLanguageLevels],
  );

  if (error) {
    return (
      <DisplayError
        title={t('views.recruitment.list.filters.empty')}
        body=" "
        insideCard
        typeError={''}
      />
    );
  }

  if (isLoading) {
    return <Loading insideCard />;
  }

  return (
    <>
      <Card className="p-5 my-4">
        <Row>
          <HeaderDetail
            career={`${offer!.school.name}, ${offer!.career.name}`}
            period={offer!.period.name}
            subjectHours={offer!.subjectHours}
            name={offer!.name}
            campus={offer!.campus.name}
            working_day={offer!.workingDay.name}
            modality={offer!.modality.name}
            updatedAt={offer!.updatedAt}
            status={offer!.statusOffer}
          />
        </Row>
        {Boolean(offer?.offerGuestQueue?.length) && (
          <Row className="bg-light">
            <InvitedsBox
              title={t(`${prefix}.invitedsTitle`)}
              subtitle={t(`${prefix}.invitedsDescription`)}
              inviteds={offer?.offerGuestQueue}
            />
          </Row>
        )}
      </Card>
      <Card className="mt-4 p-5">
        <Row>
          <Description
            title={t(`${prefix}.description`)}
            description={offer!.description}
            item={Boolean(offer!.description)}
          />
          <Description
            title={t(`${prefix}.levelResponsibility`)}
            description={offer!.levelResponsibility}
            item={Boolean(offer!.levelResponsibility)}
          />
          <List title={t(`${prefix}.educations`)} list={offer!.educations} />
          <List
            title={t(`${prefix}.competencies`)}
            list={offer!.competencies}
          />
          <List
            title={t(`${prefix}.certifications`)}
            list={offer!.certifications}
          />
          <List title={t(`${prefix}.licenses`)} list={offer!.licenseTypes} />
          <List
            title={t(`${prefix}.requirements`)}
            list={offer!.requirements}
          />
          <List title={t(`${prefix}.languages`)} list={languages} />
          <Description
            title={t(`${prefix}.knowledge`)}
            description={offer!.knowledge}
            item={Boolean(offer!.knowledge)}
          />
          <List title={t(`${prefix}.benefits`)} list={offer!.benefits} />
          <List
            title={t(`${prefix}.applicationStages.title`)}
            subtitle={t(`${prefix}.applicationStages.description`)}
            list={offer!.applicationStages}
          />
          {offer?.laws.map((law, index) => (
            <Description
              key={index}
              title={law.name}
              description={law.description}
              item={true}
            />
          ))}
        </Row>
      </Card>
    </>
  );
}
