// Hooks
import useGetStepData from './useGetStepData';
import { useMemo } from 'react';

// Utils
import { mapNameAndIdToLabelAndValue } from '../../../utils/mapNameAndIdToLabelAndValue';

// Types
import { SelectOptionType } from '@octano/global-ui';
import { Benefit, Law, OfferCreationOptions } from '../../../types/jobOffer';
import { CreateOfferStepArea } from '../types';

export type ListsDictionary = {
  campus: SelectOptionType[];
  schools: SelectOptionType[];
  contractTypes: SelectOptionType[];
  modalities: SelectOptionType[];
  workingDays: SelectOptionType[];
  specialtyArea: SelectOptionType[];
  subjects: SelectOptionType[];
  periods: SelectOptionType[];
  benefits: Benefit[];
  laws: Law[];
};

// Render
const useGetCreationLists = (): {
  lists: ListsDictionary;
  isLoading: boolean;
} => {
  const { data: creationOptions, isFetching: isLoading } =
    useGetStepData<OfferCreationOptions>(-1, CreateOfferStepArea.OfferDetails);

  const campus = useMemo(
    () => creationOptions?.campus || [],
    [creationOptions?.campus],
  );
  const schools = useMemo(
    () => creationOptions?.schools || [],
    [creationOptions?.schools],
  );
  const contractTypes = useMemo(
    () => creationOptions?.contractTypes || [],
    [creationOptions?.contractTypes],
  );
  const modalities = useMemo(
    () => creationOptions?.modalities || [],
    [creationOptions?.modalities],
  );
  const workingDays = useMemo(
    () => creationOptions?.workingDays || [],
    [creationOptions?.workingDays],
  );
  const specialtyArea = useMemo(
    () => creationOptions?.specialtyAreas || [],
    [creationOptions?.specialtyAreas],
  );
  const subjects = useMemo(
    () => creationOptions?.subjects || [],
    [creationOptions?.subjects],
  );
  const periods = useMemo(
    () => creationOptions?.periods || [],
    [creationOptions?.periods],
  );
  const benefits = useMemo(
    () => creationOptions?.benefits || [],
    [creationOptions?.benefits],
  );
  const laws = useMemo(
    () => creationOptions?.laws || [],
    [creationOptions?.laws],
  );

  const lists = useMemo<ListsDictionary>(() => {
    return {
      campus: campus?.map(mapNameAndIdToLabelAndValue) || [],
      schools: schools?.map(mapNameAndIdToLabelAndValue) || [],
      contractTypes: contractTypes?.map(mapNameAndIdToLabelAndValue) || [],
      modalities: modalities?.map(mapNameAndIdToLabelAndValue) || [],
      workingDays: workingDays?.map(mapNameAndIdToLabelAndValue) || [],
      specialtyArea: specialtyArea?.map(mapNameAndIdToLabelAndValue) || [],
      subjects: subjects?.map(mapNameAndIdToLabelAndValue) || [],
      periods: periods?.map(mapNameAndIdToLabelAndValue) || [],
      benefits,
      laws,
    };
  }, [
    benefits,
    campus,
    contractTypes,
    laws,
    modalities,
    periods,
    schools,
    specialtyArea,
    subjects,
    workingDays,
  ]);

  return { lists, isLoading };
};

export default useGetCreationLists;
