// Components
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import Loading from '../../../../components/info/Loading';

import { Col, Form, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { CheckInput, Icon, useMobile } from '@octano/global-ui';

// Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { CheckNames } from '../../../../types/GenericFormTypes';
import { addNameToChecksInput, filterMarkOnSubmit } from '../utils';
import { createFillFormObject } from './utils';
import { CreateOfferStepArea, OfferCreationStepProps } from '../../types';
import { CurrentStep } from '../../../../types/stepsTypes';
import { Stage } from '../../../../types/offerStageTypes';
import useGetStepData from '../../hooks/useGetStepData';

type StagesProcessStepProps = OfferCreationStepProps & {};
type StagesProcessStepData = Stage[];

const keyPrefix = 'views.addOffer.offerStages';
const area = CreateOfferStepArea.StagesProcess;

// Render
const StagesProcessStep = ({
  backRequireConfimation = false,
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: StagesProcessStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const isMobile = useMobile();

  const stepWrapperRef = useRef<StepsWrappeMethods>(null);

  const [stages, setStages] = useState<Stage[]>([]);
  const orderedStages = useMemo(
    () => stages.sort((s1, s2) => s1.order - s2.order),
    [stages],
  );

  const { data: offerData, isFetching: isFetchingOffer } =
    useGetStepData<CurrentStep>(offerDraftId);
  const { data: stagesData, isFetching: isFetchingStep } =
    useGetStepData<StagesProcessStepData>(offerDraftId, area);

  const methods = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });
  const {
    getValues,
    reset,
    control,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (onSubmit) {
        const values = getValues();
        await onSubmit({
          area,
          nextStep,
          payload: {
            applicationStages: filterMarkOnSubmit(stages, values),
          },
          next,
          successMsg: t('stage.onSave.success'),
          failedMsg: t('stage.onSave.error'),
        });
      }
    },
    [getValues, nextStep, onSubmit, stages, t],
  );

  useEffect(() => {
    if (stagesData) {
      setStages(addNameToChecksInput(stagesData, CheckNames.ApplicationStages));
    }
  }, [stagesData]);

  useEffect(() => {
    if (offerData?.offer) {
      reset(createFillFormObject(offerData?.offer));
    }
  }, [offerData?.offer, reset]);

  if (isFetchingStep || isFetchingOffer) {
    return <Loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={methods?.handleSubmit(handleSave)}
          onChange={() => clearErrors(['educations', 'requirements'])}
        >
          <StepsWrapper
            keyPrefix={keyPrefix}
            ref={stepWrapperRef}
            offerDraftId={offerDraftId}
            onSave={onSave}
            prevStep={prevStep}
            backRequireConfimation={backRequireConfimation}
            buttonText={t('goBackToPrevious')}
          >
            <Row>
              <Col xs={12} md={10}>
                <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
                  {t(`stage.title`)}
                </h5>
                <p className="fs-16">{t(`stage.subtitle`)}</p>
              </Col>
              <Col xs={12}>
                <ListGroup>
                  {orderedStages.map((stage, index) => {
                    return (
                      <ListGroupItem
                        className="d-flex justify-content-between align-items-center py-0"
                        key={index}
                      >
                        <div className="d-flex align-items-center">
                          <CheckInput
                            name={`${CheckNames.ApplicationStages}-${stage.id}`}
                            label=""
                            defaultValue={Boolean(stage.isMark)}
                            control={control}
                          />
                          {index + 1}. {stage.name}
                        </div>
                        <div>
                          <span
                            onClick={() => {
                              if (index > 0) {
                                stage.order = index;
                                orderedStages[index - 1].order = index + 1;
                                setStages([...orderedStages]);
                              }
                            }}
                          >
                            <Icon name="chevron_up" />
                          </span>
                          <span
                            className="ml-4"
                            onClick={() => {
                              if (index < orderedStages.length - 1) {
                                stage.order = index + 2;
                                orderedStages[index + 1].order = index + 1;
                                setStages([...orderedStages]);
                              }
                            }}
                          >
                            <Icon name="chevron_down" />
                          </span>
                        </div>
                      </ListGroupItem>
                    );
                  })}
                  {errors?.applicationStages && (
                    <div className="fs-16 text-danger mb-0">
                      {errors?.applicationStages.message}
                    </div>
                  )}
                </ListGroup>
              </Col>
              <BottomButtons
                buttons={[
                  {
                    outlined: true,
                    color: 'danger',
                    text: t('actions.cancel'),
                    onClick: () => stepWrapperRef?.current?.prev(prevStep),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                  },
                  {
                    outlined: true,
                    text: t('actions.save'),
                    onClick: () => handleSave({ next: false }),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                    disabled: isSubmitting,
                  },
                  {
                    type: 'submit',
                    text: t('actions.next'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
                hint={t('infoOnClickNext')}
              />
            </Row>
          </StepsWrapper>
        </Form>
      </FormProvider>
    </>
  );
};

export default StagesProcessStep;
