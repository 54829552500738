import { Button, OutlinedSelect, TextOutlinedInput } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Container, Col, Row } from 'reactstrap';
import Applications from './parts/Applications';
import BaseLoader from './parts/BaseLoader';
import Box from './parts/Box';
import { CurrentStep } from './types';

type FilterFields = {
  id: number | string | null;
  step: CurrentStep | null;
};

export default function OfferDetail() {
  const { t } = useTranslation();
  const history = useHistory();

  const [idFilter, setIdFilter] = useState<string | number | null>(null);
  const [setpFilter, setStepFilter] = useState<CurrentStep[] | null>(null);

  const { offerId } = useParams<{ offerId: string }>();

  const { control, handleSubmit, reset } = useForm<FilterFields>({
    defaultValues: {
      id: '',
      step: null,
    },
  });

  const steps = useMemo(
    () => [
      {
        value: CurrentStep.Received,
        label: t('views.recruitment.detail.legends.received'),
      },
      {
        value: CurrentStep.Review,
        label: t('views.recruitment.detail.legends.inReview'),
      },
      {
        value: CurrentStep.Discarded,
        label: t('views.recruitment.detail.legends.discarded'),
      },
      {
        value: CurrentStep.Approved,
        label: t('views.recruitment.detail.legends.approved'),
      },
    ],
    [t],
  );

  const goBack = useCallback(() => {
    history?.push('/recruitment/offer-list');
  }, [history]);

  const handleClear = useCallback(() => {
    reset();
    setIdFilter(null);
    setStepFilter(null);
  }, [reset]);

  const handleSearch = useCallback((params: FilterFields) => {
    setIdFilter(`${params?.id || ''}`.trim() || null);
    setStepFilter(params?.step ? [(params?.step as any)?.value] : null);
  }, []);

  const regularStages = useMemo(() => {
    let stages = [
      CurrentStep.Received,
      CurrentStep.Discarded,
      CurrentStep.Review,
    ];
    if (setpFilter) {
      stages = setpFilter?.filter((e) => stages?.some((s) => s === e));
    }
    if (!stages?.length) {
      stages = ['none' as CurrentStep];
    }
    return stages;
  }, [setpFilter]);

  const selectedStages = useMemo(() => {
    let stages = [CurrentStep.Approved];
    if (setpFilter) {
      stages = setpFilter?.filter((e) => stages?.some((s) => s === e));
    }
    if (!stages?.length) {
      stages = ['none' as CurrentStep];
    }
    return stages;
  }, [setpFilter]);

  return (
    <Card className="p-4">
      <BaseLoader offerId={offerId}>
        {(base) => (
          <>
            <Container fluid className="p-0 mb-4">
              <Row className="align-items-center">
                <Col xs={12} md={5}>
                  <Button
                    icon="back"
                    onClick={goBack}
                    outlined
                    rounded
                    size="sm"
                    text={t(`common.actions.goBackToPreviousStep`)}
                    className="mb-4"
                  />
                </Col>
                <Col xs={12} md={7}>
                  <Box information={base} />
                </Col>
              </Row>
            </Container>
            <Container fluid className="p-0 mb-4">
              <Row>
                <Col className="mb-4" xs={12}>
                  <p className="fs-16">
                    {t('views.recruitment.detail.description')}
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <div className="w-100 align-items-center d-flex justify-content-start">
                    <div className="w-100" style={{ maxWidth: 230 }}>
                      <TextOutlinedInput
                        name="id"
                        control={control}
                        label={t('views.recruitment.detail.filters.nameOrId')}
                        placeholder={t(
                          'views.recruitment.detail.filters.searchByNameOrId',
                        )}
                      />
                    </div>
                    <div className="w-100 ml-2" style={{ maxWidth: 230 }}>
                      <OutlinedSelect
                        control={control}
                        name="step"
                        label={t('views.recruitment.detail.filters.state')}
                        options={steps}
                      />
                    </div>
                  </div>
                </Col>
                <Col className="justify-content-end" xs={12} md={6}>
                  <div className="w-100 d-flex align-items-center justify-content-end">
                    <Button
                      style={{ height: 40, minHeight: 20 }}
                      text={t('views.recruitment.detail.filters.search')}
                      onClick={handleSubmit(handleSearch)}
                    />
                    <Button
                      className="ml-2"
                      style={{ height: 40, minHeight: 20 }}
                      text={t('views.recruitment.detail.filters.clear')}
                      outlined={true}
                      onClick={handleClear}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
            <Applications
              title={t('views.recruitment.detail.postulants')}
              offerName={base.name}
              offerId={offerId}
              idFilter={idFilter}
              stepFilter={regularStages}
            />
            <Applications
              title={t('views.recruitment.detail.selecteds')}
              emptyTitle={t('views.recruitment.detail.noResultSelecteds')}
              emptyDescription={t(
                'views.recruitment.detail.noResultDescriptionSelecteds',
              )}
              offerName={base.name}
              offerId={offerId}
              isSelectedsTable={true}
              idFilter={idFilter}
              stepFilter={selectedStages}
            />
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
