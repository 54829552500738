import { useCallback, useMemo } from 'react';

// Components
import { Button, OutlinedSelect } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

// Hooks
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';
import { useGetOffersDataQuery } from '../../api';

// Types
type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  campusId: ItemSelect | null;
  schoolId: ItemSelect | null;
  careerId: ItemSelect | null;
  subjectId: ItemSelect | null;
  workingDayId: ItemSelect | null;
};

export type OnFilterParams = {
  campusId: number | null;
  schoolId: number | null;
  careerId: number | null;
  subjectId: number | null;
  workingDayId: number | null;
};

export type FilterFieldsSubmit = {
  campusId: { value: string };
  schoolId: { value: string };
  careerId: { value: string };
  subjectId: { value: string };
  workingDayId: { value: string };
};

type OffersFiltersProps = {
  onFilter?: (params: OnFilterParams) => void;
};

// Render
const OffersFilters = ({ onFilter }: OffersFiltersProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset, watch } = useForm<FilterFields>({
    defaultValues: {
      campusId: null,
      schoolId: null,
      careerId: null,
      subjectId: null,
      workingDayId: null,
    },
  });
  const [campusId, schoolId, careerId] = watch([
    'campusId',
    'schoolId',
    'careerId',
  ]);

  const { data } = useGetOffersDataQuery();

  const workingDays = useMemo(
    () => data?.workingDays || [],
    [data?.workingDays],
  );

  const campuses = useMemo(() => data?.campuses || [], [data?.campuses]);

  const schools = useMemo(
    () =>
      data?.schools?.filter(
        (e) => !campusId?.value || `${e?.campusId}` === campusId?.value,
      ) || [],
    [campusId, data?.schools],
  );

  const careers = useMemo(
    () =>
      data?.careers?.filter(
        (e) => !schoolId?.value || `${e?.schoolId}` === `${schoolId?.value}`,
      ) || [],
    [data?.careers, schoolId],
  );

  const subjects = useMemo(
    () =>
      data?.subjects?.filter(
        (e) =>
          !careerId?.value ||
          !e?.careerId ||
          `${e?.careerId}` === `${careerId?.value}`,
      ) || [],
    [data?.subjects, careerId],
  );

  const handleClear = useCallback(() => {
    reset();
    onFilter &&
      onFilter({
        campusId: null,
        schoolId: null,
        careerId: null,
        subjectId: null,
        workingDayId: null,
      });
  }, [onFilter, reset]);

  const handleSearch = useCallback(
    ({
      campusId,
      schoolId,
      careerId,
      subjectId,
      workingDayId,
    }: FilterFieldsSubmit) => {
      const payload: OnFilterParams = {
        campusId: null,
        schoolId: null,
        careerId: null,
        subjectId: null,
        workingDayId: null,
      };

      if (campuses?.length && campusId?.value) {
        payload.campusId = parseInt(`${campusId?.value}`, 10) || null;
      }
      if (schools?.length && schoolId?.value) {
        payload.schoolId = parseInt(`${schoolId?.value}`, 10) || null;
      }
      if (careers?.length && careerId?.value) {
        payload.careerId = parseInt(`${careerId?.value}`, 10) || null;
      }
      if (subjects?.length && subjectId?.value) {
        payload.subjectId = parseInt(`${subjectId?.value}`, 10) || null;
      }
      if (workingDays?.length && workingDayId?.value) {
        payload.workingDayId = parseInt(`${workingDayId?.value}`, 10) || null;
      }

      onFilter && onFilter(payload);
    },
    [
      onFilter,
      campuses?.length,
      careers?.length,
      schools?.length,
      subjects?.length,
      workingDays?.length,
    ],
  );

  return (
    <Row>
      <Col xs={12} md={6} lg={3}>
        <OutlinedSelect
          control={control}
          name="campusId"
          label={t('views.recruitment.list.filters.campus')}
          placeholder={t('views.recruitment.list.filters.campusPlaceholder')}
          disabled={!campuses?.length}
          options={campuses?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <OutlinedSelect
          control={control}
          name="schoolId"
          label={t('views.recruitment.list.filters.school')}
          placeholder={t('views.recruitment.list.filters.schoolPlaceholder')}
          disabled={!schools?.length}
          options={schools?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="careerId"
          label={t('views.recruitment.list.filters.career')}
          placeholder={t('views.recruitment.list.filters.careerPlaceholder')}
          disabled={!careers?.length}
          options={careers?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="subjectId"
          label={t('views.recruitment.list.filters.subject')}
          placeholder={t('views.recruitment.list.filters.subjectPlaceholder')}
          disabled={!subjects?.length}
          options={subjects?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <OutlinedSelect
          control={control}
          name="workingDayId"
          label={t('views.recruitment.list.filters.workingDay')}
          placeholder={t(
            'views.recruitment.list.filters.workingDayPlaceholder',
          )}
          disabled={!workingDays?.length}
          options={workingDays?.map((e) => ({
            value: `${e?.id}`,
            label: e?.name,
          }))}
        />
      </Col>
      <Col
        className="d-flex justify-content-end align-items-center"
        xs={12}
        md={9}
        lg={9}
      >
        <div className="w-100 d-flex align-items-center justify-content-end">
          <Button
            style={{ height: 40, minHeight: 20 }}
            text={t('views.recruitment.detail.filters.search')}
            onClick={handleSubmit(handleSearch)}
          />
          <Button
            className="ml-2"
            style={{ height: 40, minHeight: 20 }}
            text={t('views.recruitment.detail.filters.clear')}
            outlined={true}
            onClick={handleClear}
          />
        </div>
      </Col>
    </Row>
  );
};

export default OffersFilters;
