import { ToastProvider } from '@octano/global-ui';
import AppVersion from './components/AppVersion/AppVersion';
import AppRouter from './components/AppRouter/AppRouter';
import UserStateProvider from './providers/UserStateProvider';
import { Provider } from 'react-redux';
import store from './store/index';

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <UserStateProvider>
          <AppVersion />
          <AppRouter />
        </UserStateProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
