import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, handleSessionExpiredError } from '../../utils/auth';
import {
  AuthorizedPostulantItem,
  AuthorizedPostulantPostulationItem,
  AuthorizedPostulantsFiltersForm,
  GetAuthorizedPostulatsFilters,
  GetAuthorizedPostulatsParams,
  GetHistoryDataParams,
  GetPostulationBaseInformationParams,
  HiringHistoryItem,
  UpdatePostulantPostulationsParams,
} from './types';
import { PaginationRequestType } from '../../types/pagination';
import { PostulationApplicationStage } from '../recruitment/postulation-review/types';

export const hiringApi = createApi({
  reducerPath: 'hiringApi',
  baseQuery: getBaseQuery('/mantainer-portal/hiring'),
  endpoints: (builder) => ({
    getAuthorizedPostulants: builder.query<
      PaginationRequestType<AuthorizedPostulantItem>,
      GetAuthorizedPostulatsParams
    >({
      query: (params) => ({
        url: '/users',
        params: {
          user: params?.name?.trim() || '',
          career: params?.careerId || undefined,
          school: params?.schoolId || undefined,
          campus: params?.campusId || undefined,
          authorizationStatus: params?.statusId || undefined,
          offer: params?.offerId || undefined,
          workingDay: params?.workingDayId || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getFiltersForm: builder.query<
      AuthorizedPostulantsFiltersForm,
      string | undefined
    >({
      query: (from) => ({
        url: '/form',
        params: from
          ? {
              from,
            }
          : undefined,
      }),
    }),
    updatePostulationsStatus: builder.query<
      AuthorizedPostulantsFiltersForm,
      void
    >({
      query: () => '/form',
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getAuthorizedPostulantPostulations: builder.query<
      AuthorizedPostulantPostulationItem[],
      [string | number, GetAuthorizedPostulatsFilters | undefined]
    >({
      query: ([id, params]) => ({
        url: `/users/${id}/postulations`,
        params: {
          ...params,
          career: params?.careerId || undefined,
          campus: params?.campusId || undefined,
          school: params?.schoolId || undefined,
          authorizationStatus: params?.statusId || undefined,
          offer: params?.offerId || undefined,
          workingDay: params?.workingDayId || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    updatePostulantPostulations: builder.mutation<
      void,
      UpdatePostulantPostulationsParams
    >({
      query: (payload) => ({
        method: 'PUT',
        url: `/users/${payload?.userId}/postulations`,
        body: {
          selectedSections: payload?.selectedSections,
          postulationIds: payload?.postulationIds,
          authorizationStatus: payload?.authorizationStatus,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getUserBaseInformation: builder.query<any, string | number>({
      query: (id) => `/users/${id}/base-information`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulationVacants: builder.query<
      { total: number; available: number },
      GetPostulationBaseInformationParams
    >({
      query: ({ userId, postulationId }) =>
        `/users/${userId}/postulations/${postulationId}/vacants`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulationBaseInformation: builder.query<
      any,
      GetPostulationBaseInformationParams
    >({
      query: ({ userId, postulationId }) =>
        `/users/${userId}/postulations/${postulationId}`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulationStages: builder.query<
      PostulationApplicationStage[],
      GetPostulationBaseInformationParams
    >({
      query: ({ userId, postulationId }) =>
        `/users/${userId}/postulations/${postulationId}/stages`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getHistoryData: builder.query<
      PaginationRequestType<HiringHistoryItem>,
      GetHistoryDataParams
    >({
      query: (params) => ({
        url: '/history',
        params: {
          ...params,
          user: params?.name?.trim() || '',
          career: params?.careerId || undefined,
          campus: params?.campusId || undefined,
          authorizationStatus: params?.statusId || undefined,
          offer: params?.offerId || undefined,
          workingDay: params?.workingDayId || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const {
  useGetFiltersFormQuery,
  useGetAuthorizedPostulantsQuery,
  useGetAuthorizedPostulantPostulationsQuery,
  useUpdatePostulantPostulationsMutation,
  useGetPostulationVacantsQuery,
  useGetUserBaseInformationQuery,
  useGetPostulationStagesQuery,
  useGetPostulationBaseInformationQuery,
  useGetHistoryDataQuery,
} = hiringApi;
