import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, handleSessionExpiredError } from '../../utils/auth';

export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: getBaseQuery('/mantainer-portal/contract'),
  endpoints: (builder) => ({
    generateExcel: builder.mutation<any, number[]>({
      query: (postulationsIds) => {
        let url = '';
        for (let i = 0; i < postulationsIds.length; i++) {
          if (i === 0) {
            url += `?postulationIds=${postulationsIds[i]}`;
          } else {
            url += `&postulationIds=${postulationsIds[i]}`;
          }
        }
        return {
          url,
          responseHandler: (response: any) =>
            response.blob().then((blob: any) => URL.createObjectURL(blob)),
        };
      },
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const { useGenerateExcelMutation } = contractApi;
