// Components
import { Col, Row } from 'reactstrap';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import File from './File';
import Section from './Section';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { format } from 'rut.js';

// Types
import { ReviewRequest } from '../types';

type PostulationReviewProps = {
  data: ReviewRequest;
  isFetching?: boolean;
  error?: any;
};

const PostulationDetailsForm = ({
  data,
  isFetching = false,
  error,
}: PostulationReviewProps) => {
  const { t } = useTranslation();

  if (error) {
    return <DisplayError typeError={(error as any)?.status} />;
  }

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Row>
      <Col xs={12} className="mb-4">
        <p className="text-primary fs-30 fw-700 mb-2">{data.name}</p>
        <p className="text-primary fs-25 mb-5">{data.studyPlan}</p>
      </Col>

      <Col xs={12} className="mb-4">
        <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {t('views.recruitment.review.applicantData')}
        </p>
        <p className="fs-16 mb-1">
          <strong> {t('views.recruitment.review.applicantName')}</strong>
          {data.user.name}
        </p>
        <p className="fs-16 ">
          <strong> {t('views.recruitment.review.applicantId')}</strong>
          {format(data.user.rut)}
        </p>
      </Col>

      <Section
        title={t('views.recruitment.review.educationalLevel')}
        description={data.educationalLevel}
      />
      <Section
        title={t('views.recruitment.review.salaryPretension')}
        description={data.salaryPretension}
        isVisible={Boolean(data.salaryPretension)}
      />
      <Section
        title={t('views.recruitment.review.startAvailability')}
        description={data.availabilityDate}
        isVisible={typeof data.availabilityDate === 'string'}
      />
      <Section
        title={t('views.recruitment.review.scheduleAvailability')}
        description={data.availabilityTime}
        isVisible={typeof data.availabilityTime === 'string'}
      />
      <Section
        title={t('views.recruitment.review.hasAdministrativeInabilities')}
        subtitle={t(
          'views.recruitment.review.hasAdministrativeInabilitiesSubtitle',
        )}
        description={
          data?.hasAdministrativeInabilities
            ? t('views.recruitment.review.yes')
            : t('views.recruitment.review.no')
        }
        isVisible={typeof data?.hasAdministrativeInabilities === 'boolean'}
      />
      <Section
        title={t('views.recruitment.review.hasInstitutionalInabilities')}
        subtitle={t(
          'views.recruitment.review.hasInstitutionalInabilitiesSubtitle',
        )}
        description={
          data?.hasInstitutionalInabilities
            ? t('views.recruitment.review.yes')
            : t('views.recruitment.review.no')
        }
        isVisible={typeof data?.hasInstitutionalInabilities === 'boolean'}
      />
      {Boolean(data?.answer?.length) && (
        <Section
          title={t('views.recruitment.review.questions')}
          answer={data.answer}
        />
      )}

      {data?.laws?.map((l) => (
        <Section
          title={l.name}
          subtitle={l.question}
          description={
            l.answer
              ? 'Confirmo haber leído y estar de acuerdo.'
              : 'No confirmo haber leído y estar de acuerdo.'
          }
        />
      ))}

      {data.curriculum.length > 0 && (
        <Col xs={12} sm={7} className="mb-4">
          <File
            name={data.curriculum[0].name}
            url={data.curriculum[0].url}
            documentName={'CURRICULUM'}
          />
        </Col>
      )}

      {data.collegeDegrees.length > 0 && (
        <>
          <Col xs={12} sm={7}>
            <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">
              {t('views.recruitment.review.collegeDegrees')}
            </p>
          </Col>
          {data.collegeDegrees.map((c, i) => (
            <Col key={`col-${i}`} xs={12} sm={7} className="mb-4">
              <File name={c.name} url={c.url} documentName={c.documentName!} />
            </Col>
          ))}
        </>
      )}

      {data.certifications.length > 0 &&
        data.certifications.map((c, i) => (
          <Col key={`col-${i}`} xs={12} sm={7} className="mb-4">
            <File name={c.name} url={c.url} documentName={c.documentName!} />
          </Col>
        ))}

      {data.licenses.length > 0 &&
        data.licenses.map((l, i) => (
          <Col key={`col-${i}`} xs={12} sm={7} className="mb-4">
            <File name={l.name} url={l.url} documentName={l.documentName!} />
          </Col>
        ))}
    </Row>
  );
};

export default PostulationDetailsForm;
