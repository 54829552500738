import { Box, Button, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { StatusOffer } from '../../../../types/jobOffer';

interface HeaderDetailProps {
  career: string;
  name: string;
  campus?: string;
  working_day?: string;
  modality?: string;
  period?: string;
  subjectHours?: number;
  updatedAt?: string;
  status: StatusOffer;
}

export default function HeaderDetail({
  career,
  name,
  campus,
  working_day,
  modality,
  period,
  subjectHours,
  updatedAt,
}: HeaderDetailProps) {
  const prefix = 'views.offersMaintainer.viewOffer';
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMobile();

  const timeFromNow = dayjs(updatedAt)
    .fromNow()
    .replace(/^./, dayjs(updatedAt).fromNow()[0].toUpperCase());

  const goBackToList = () => {
    history.push('/public/jobs');
  };

  return (
    <>
      <Col xs={12}>
        <Row>
          <Col xs={12} sm={6}>
            <Button
              className="mb-3"
              icon="back"
              onClick={goBackToList}
              outlined
              rounded
              size="sm"
              text={t(`${prefix}.goBack`)}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Box
              className="float-right"
              body={timeFromNow}
              color="secondary"
              title={t(`${prefix}.updateDate`)}
              variant="outlined"
              fullwidth={isMobile}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={12} className="mt-4">
        <h3
          className={clsx(
            'fw-700 text-primary mb-4',
            isMobile ? 'fs-25' : 'fs-30',
          )}
        >
          {name}
        </h3>
        <ul className="p-0 pl-4 fw-600 fs-20">
          <li>{career}</li>
          <li>
            {t(`common.terms.campus`)} {campus}
          </li>
          <li>
            {t(`common.terms.schedule`)} {working_day}
          </li>
          <li className={clsx(isMobile && 'mb-4')}>
            {t(`common.terms.modality`)} {modality}
          </li>
          <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
            {t(`common.terms.subjectHours`)}: {subjectHours}
          </li>
          <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
            {t(`common.terms.period`)} {period}
          </li>
        </ul>
      </Col>
    </>
  );
}
