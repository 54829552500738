// Components
import { Col } from 'reactstrap';
import { TextAreaInput } from '@octano/global-ui';

// Hooks
import { useFormContext } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';
import LengthCounter from '../../../../../components/Form/LengthCounter';
import { TEXTAREA_MAXLENGTH } from '../../../../../config/constants';

// Types
const keyPrefix = 'views.addOffer.offerRequirements';

// Render
const SpecificKnowledgeComponent = () => {
  const { control } = useFormContext<any>();
  const { t } = useTranslation();

  return (
    <>
      <Col xs={12} className="mt-5">
        <h5 className="text-primary fs-20 fw-700 mb-0 mr-2 text-uppercase">
          {t(`${keyPrefix}.specificKnowledge.title`)}
        </h5>
        <TextAreaInput
          label=""
          name="knowledge"
          control={control}
          rules={{ required: t(`common.validations.required`) }}
          maxLength={TEXTAREA_MAXLENGTH}
        />
        <LengthCounter name="knowledge" />
      </Col>
    </>
  );
};

export default SpecificKnowledgeComponent;
