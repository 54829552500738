// Components
import Loading from '../../../../components/info/Loading';
import AddNewItemModal, {
  AddNewItemModalMethods,
  ItemType,
} from '../../parts/AddNewItemModal';
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import LanguagesComponent from './parts/LanguagesComponent';
import SpecificKnowledgeComponent from './parts/SpecificKnowledgeComponent';

import { SelectOptionType, useMobile } from '@octano/global-ui';
import { Form, Row } from 'reactstrap';

// Hooks
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import useGetStepData from '../../hooks/useGetStepData';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import ChecklistSection2 from '../../../../components/steps/ChecklistSection2';
import { Base, CheckNames } from '../../../../types/GenericFormTypes';
import { CurrentStep } from '../../../../types/stepsTypes';
import { CreateOfferStepArea, OfferCreationStepProps } from '../../types';
import { selectOptionArray } from '../utils';
import {
  createFillFormObject,
  filterAndMapIds,
  filterSelectedLanguages,
} from './utils';

type RequirementStepProps = OfferCreationStepProps & {};
type RequirementStepData = {
  educations: Base[];
  competences: Base[];
  certifications: Base[];
  licenseTypes: Base[];
  requirements: Base[];
  languages: Base[];
  languageLevels: Base[];
};

const keyPrefix = 'views.addOffer.offerRequirements';
const area = CreateOfferStepArea.Requirement;

// Render
const RequirementStep = ({
  backRequireConfimation = false,
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: RequirementStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { t: checklistTranslation } = useTranslation('translation', {
    keyPrefix: 'views.addOffer.offerRequirements',
  });

  const [languages, setLanguages] = useState<SelectOptionType[]>([]);
  const [languageLevels, setLanguageLevels] = useState<SelectOptionType[]>([]);
  const [languageLength, setLanguageLength] = useState<number>(0);

  const isMobile = useMobile();
  const stepWrapperRef = useRef<StepsWrappeMethods>(null);
  const addItemRef = useRef<AddNewItemModalMethods>(null);

  const { data: offerData, isFetching: isFetchingOffer } =
    useGetStepData<CurrentStep>(offerDraftId);
  const { data: requirementsData, isFetching: isFetchingQuestions } =
    useGetStepData<RequirementStepData>(-1, area);

  const methods = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });
  const { unregister, getValues, reset, clearErrors, control } = methods;

  const { append: addEducations } = useFieldArray({
    control,
    name: 'educations',
  });
  const { append: addCompetences } = useFieldArray({
    control,
    name: 'competences',
  });
  const { append: addCertifications } = useFieldArray({
    control,
    name: 'certifications',
  });
  const { append: addLicenseTypes } = useFieldArray({
    control,
    name: 'licenseTypes',
  });
  const { append: addRequirements } = useFieldArray({
    control,
    name: 'requirements',
  });

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (onSubmit) {
        const values = getValues();
        const selectedLanguages = filterSelectedLanguages(
          languageLength,
          values,
        );
        await onSubmit({
          area,
          nextStep,
          payload: {
            educations: filterAndMapIds(values.educations),
            competences: filterAndMapIds(values.competences),
            certifications: filterAndMapIds(values.certifications),
            licenseTypes: filterAndMapIds(values.licenseTypes),
            requirements: filterAndMapIds(values.requirements),
            knowledge: values?.knowledge,
            languageLevels: selectedLanguages?.length
              ? selectedLanguages
              : undefined,
          },
          next,
          successMsg: t('onSave.success'),
          failedMsg: t('onSave.error'),
        });
      }
    },
    [getValues, languageLength, nextStep, onSubmit, t],
  );

  const handleAddItem = useCallback(
    (type: ItemType, item: Base) => {
      switch (type) {
        case 'education':
          addEducations(item);
          break;
        case 'competence':
          addCompetences(item);
          break;
        case 'certification':
          addCertifications(item);
          break;
        case 'license-type':
          addLicenseTypes(item);
          break;
        case 'requirement':
          addRequirements(item);
          break;
        default:
          break;
      }
    },
    [
      addEducations,
      addCompetences,
      addCertifications,
      addLicenseTypes,
      addRequirements,
    ],
  );

  useEffect(() => {
    if (requirementsData?.languages) {
      setLanguages(selectOptionArray(requirementsData?.languages));
    }
    if (requirementsData?.languageLevels) {
      setLanguageLevels(selectOptionArray(requirementsData?.languageLevels));
    }
  }, [requirementsData?.languageLevels, requirementsData?.languages]);

  useEffect(() => {
    const fillObject = {
      educations: requirementsData?.educations ?? [],
      competences: requirementsData?.competences ?? [],
      certifications: requirementsData?.certifications ?? [],
      requirements: requirementsData?.requirements ?? [],
      licenseTypes: requirementsData?.licenseTypes ?? [],
    };
    if (offerData?.offer) {
      setLanguageLength(offerData?.offer?.languageLevels?.length ?? 1);
      reset(createFillFormObject(offerData?.offer, fillObject));
    } else if (!offerDraftId) {
      reset(fillObject);
    }
  }, [offerData?.offer, offerDraftId, requirementsData, reset]);

  if (isFetchingQuestions || isFetchingOffer) {
    return <Loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={methods?.handleSubmit(handleSave)}
          onChange={() => clearErrors(['educations', 'requirements'])}
        >
          <StepsWrapper
            keyPrefix={keyPrefix}
            ref={stepWrapperRef}
            offerDraftId={offerDraftId}
            onSave={onSave}
            prevStep={prevStep}
            backRequireConfimation={backRequireConfimation}
            description={t('description')}
            buttonText={t('goBackToPrevious')}
          >
            <Row>
              <ChecklistSection2
                name={CheckNames.Educations}
                type="education"
                title={checklistTranslation(`education.title`)}
                subtitle={checklistTranslation(`education.subtitle`)}
                buttonTitle={checklistTranslation(`education.addNew.title`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={true}
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
                required="some"
              />
              <ChecklistSection2
                name={CheckNames.Competences}
                type="competence"
                title={checklistTranslation(`competence.title`)}
                subtitle={checklistTranslation(`competence.subtitle`)}
                buttonTitle={checklistTranslation(`competence.addNew.title`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={true}
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
              />
              <ChecklistSection2
                name={CheckNames.Certifications}
                type="certification"
                title={checklistTranslation(`certification.title`)}
                subtitle={checklistTranslation(`certification.subtitle`)}
                buttonTitle={checklistTranslation(`certification.addNew.title`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={true}
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
              />
              <ChecklistSection2
                name={CheckNames.LicenseTypes}
                type="license-type"
                title={checklistTranslation(`licenseType.title`)}
                subtitle={checklistTranslation(`licenseType.subtitle`)}
                buttonTitle={checklistTranslation(`licenseType.addNew.title`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={true}
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
              />
              <ChecklistSection2
                name={CheckNames.Requirements}
                type="requirement"
                title={checklistTranslation(`requirement.title`)}
                subtitle={checklistTranslation(`requirement.subtitle`)}
                buttonTitle={checklistTranslation(`requirement.addNew.title`)}
                hasIcon={false}
                hasDescription={true}
                canAddItems={true}
                required="some"
                onClickAddButton={(t) => addItemRef?.current?.open(t)}
              />
              <LanguagesComponent
                languages={languages}
                languageLevels={languageLevels}
                languageLength={languageLength}
                onAddLanguage={() => setLanguageLength((prev) => prev + 1)}
                onRemoveLanguage={(index) =>
                  setLanguageLength((prev) => {
                    unregister([
                      `languageLevels-${index}`,
                      `languages-${index}`,
                    ]);
                    return prev - 1;
                  })
                }
              />
              <SpecificKnowledgeComponent />
              <BottomButtons
                buttons={[
                  {
                    outlined: true,
                    color: 'danger',
                    text: t('actions.cancel'),
                    onClick: () => stepWrapperRef?.current?.prev(prevStep),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                  },
                  {
                    outlined: true,
                    text: t('actions.save'),
                    onClick: () => handleSave({ next: false }),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                    disabled: isSubmitting,
                  },
                  {
                    type: 'submit',
                    text: t('actions.next'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
                hint={t('infoOnClickNext')}
              />
            </Row>
          </StepsWrapper>
        </Form>
      </FormProvider>
      <AddNewItemModal ref={addItemRef} onSuccess={handleAddItem} />
    </>
  );
};

export default RequirementStep;
