import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UncontrolledTooltip } from 'reactstrap';
import { ColumnTable, Icon } from '@octano/global-ui';
import { OfferDetail, OfferType } from '../../../types/jobOffer';

const computedOfferName = (offer: OfferDetail) => {
  return [offer?.subject.name, offer?.specialtyArea.name]
    .filter(Boolean)
    .join(' - ');
};

export function useOfferDraftColumns() {
  const { t } = useTranslation();
  const prefix = 'views.draftMaintainer';
  const history = useHistory();

  const goToEdit = useCallback(
    (offerDetailId: number) => {
      history.push({
        pathname: `/offer-maintainer/create-offer/${offerDetailId}`,
      });
    },
    [history],
  );

  const columns = useMemo<ColumnTable<OfferDetail>[]>(() => {
    return [
      {
        columnName: 'offerName',
        headerText: t(`common.terms.offerName`),
        tdClassName: 'pl-5',
        thClassName: 'text-center',
        cellFormat: ({ row }) => {
          return computedOfferName(row);
        },
      },
      {
        columnName: 'campus',
        headerText: t(`common.terms.campus`),
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.campus?.name}
          </div>
        ),
      },
      {
        columnName: 'career',
        headerText: t(`common.terms.career`),
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.career?.name}
          </div>
        ),
      },
      {
        columnName: 'workingDay',
        headerText: t(`common.terms.workingDay`),
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row?.workingDay?.name?.trim() || ''}
          </div>
        ),
      },
      {
        columnName: 'updatedAt',
        headerText: t(`common.terms.updateAt`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'type',
        headerText: t(`common.terms.offerType`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <div className="d-flex justify-content-center align-center flex-row align-items-center">
            {value === OfferType.Public && (
              <Icon name="lock_open_right" size={18} color="primary" />
            )}
            {value === OfferType.Private && (
              <Icon name="padlock" size={18} color="primary" />
            )}
            <p className="ml-2 mr-0 my-0">
              {t(`${prefix}.offerTypeStatuses.${value}`)}
            </p>
          </div>
        ),
      },
      {
        columnName: 'action',
        headerText: t(`common.terms.actions`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ data, index }) => (
          <div>
            <span
              id={`offer-${index}`}
              onClick={() => goToEdit(data![index].draftFlowId)}
            >
              <Icon name="edit" size={15} />
            </span>
            <UncontrolledTooltip
              placement="bottom"
              target={`offer-${index}`}
              fade={false}
            >
              {t(`${prefix}.actions.editOffer`)}
            </UncontrolledTooltip>
          </div>
        ),
      },
    ];
  }, [goToEdit, t]);

  return columns;
}
