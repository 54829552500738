/**
 * Queda pendiente envolver el resto de controladores
 * bajo /mantainer-portal/ lueego en de esto se debe
 * modificar el archivo src\api\api.ts
 */

export const AUTH = {
  SEND_ACTIVATION_EMAIL: '/manage-account/send-activation-mail',
  CHECK_ACTIVATION_TOKEN: '/manage-account/check-token-validity',
  ACTIVATE_ACCOUNT: '/manage-account/activate',
  LOGIN: '/mantainer-portal/auth/login',
  SEND_PASSWORD_RECOVERY_MAIL: '/recovery-password/send',
  VALIDATE_RECOERY_TOKEN: '/recovery-password/validate',
  PASSWORD_CHANGE: '/recovery-password/restore',
};

//EDPOINTS CORRESPONDIENTES A LA CREACIÓN DE UNA OFERTA
export const CREATE_OFFER = {
  //STEP 1
  DETAIL: {
    GET_FORM_DATA: 'offer-draft-flow/detail',
    CREATE_BENEFIST: '/offer-draft-flow/detail/benefit',
    LIST_SUBJECTS_BY_CAREER: (careerId: number) =>
      `offer-draft-flow/detail/${careerId}/subjects`,
    LIST_CAREER_BY_SCHOOL: (schoolId: number) =>
      `offer-draft-flow/detail/${schoolId}/careers`,
    SAVE: (offerDraftId: number) => `/offer-draft-flow/detail/${offerDraftId}`,
    SAVE_AND_NEXT: (offerDraftId: number) =>
      `/offer-draft-flow/detail/next/${offerDraftId}`,
  },
  //STEP 2
  REQUIREMENT: {
    GET_FORM_DATA: '/offer-draft-flow/requirement',
    CREATE_EDUCATION: '/offer-draft-flow/requirement/education',
    CREATE_COMPETENCE: '/offer-draft-flow/requirement/competence',
    CREATE_CERTIFICATION: '/offer-draft-flow/requirement/certification',
    CREATE_LICENSE_TYPE: '/offer-draft-flow/requirement/license-type',
    CREATE_REQUIREMENT: '/offer-draft-flow/requirement/requirement',
    SAVE: (offerDraftId: number) =>
      `/offer-draft-flow/requirement/${offerDraftId}`,
    SAVE_AND_NEXT: (offerDraftId: number) =>
      `/offer-draft-flow/requirement/next/${offerDraftId}`,
  },
  //STEP 3
  QUESTIONS: {
    GET_FORM_DATA: (offerDraftId: number) =>
      `/offer-draft-flow/questions/${offerDraftId}`,
    CREATE_QUESTION: '/offer-draft-flow/questions/question',
    CREATE_REQUIREMENT: '/offer-draft-flow/questions/request',
    SAVE: (offerDraftId: number) =>
      `/offer-draft-flow/questions/${offerDraftId}`,
    SAVE_AND_NEXT: (offerDraftId: number) =>
      `/offer-draft-flow/questions/next/${offerDraftId}`,
  },
  //STEP 4
  STAGES: {
    GET_FORM_DATA: (offerDraftId: number) =>
      `/offer-draft-flow/application-stages/${offerDraftId}`,
    SAVE: (offerDraftId: number) =>
      `/offer-draft-flow/application-stages/${offerDraftId}`,
    SAVE_AND_NEXT: (offerDraftId: number) =>
      `/offer-draft-flow/application-stages/next/${offerDraftId}`,
  },
  //STEP 5
  REVIEW: {
    GET_REVIEW_DATA: (offerDraftFlowId: number) =>
      `/offer-draft-flow/review/${offerDraftFlowId}`,
    CREATE_OFFER: (offerDraftFlowId: number) =>
      `/offer-draft-flow/review/${offerDraftFlowId}`,
  },
};

export const CREATION_FLOW = {
  GET_FLOW_BY_ID: (offerDraftId: number) => `/offer-draft-flow/${offerDraftId}`,
};

export const OFFERS = {
  CURRENT_OFFERS: `/offers`,
  DRAFT_OFFER_LIST: `offer-draft-flow`, //listado de borradores
  START_OFFER_DRAFT_FLOW: '/offer-draft-flow', //inicia el proceso de creaccion
  POSIBLE_OFFER_STATE_LIST: '/offers/update-status', //estados que puede tener una oferta
  UPDATE_OFFER_STATE: (offerId: number) => `/offers/update-status/${offerId}`, // actualiza el estado de una oferta. de esto se debería encargar el endpoint del botón siguiente de cada paso
};

export const RECRUITMENT = {
  OFFER_LIST: '/mantainer-portal/recruitment',
  BASE_INFORMATION: (offerId: number | string) =>
    `/mantainer-portal/recruitment/base-information/${offerId}`,
  POSTULATIONS: (offerId: number | string) =>
    `/mantainer-portal/recruitment/postulations/${offerId}`,
  REVIEW: {
    GET_FORM_DATA: (postulationId: number | string) =>
      `/mantainer-portal/recruitment/review/${postulationId}`,
  },
  MANAGMENT: {
    BASE_INFORMATION: (postulationId: number | string) =>
      `/mantainer-portal/recruitment/management/base-information/${postulationId}`,
    STAGES: (postulationId: number | string) =>
      `/mantainer-portal/recruitment/management/${postulationId}`,
    SAVE_STAGE: (postulationId: number | string) =>
      `/mantainer-portal/recruitment/management/${postulationId}`,
    SAVE_POSTULATION: (postulationId: number | string) =>
      `/mantainer-portal/recruitment/management/${postulationId}`,
  },
};
