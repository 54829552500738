// Components
import { Row, Col } from 'reactstrap';

// I18n
import { useTranslation } from 'react-i18next';

// Types
const keyPrefix = 'views.hiring.assingSections';

// Render
const SelectedUserHeader = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Row className="mx-0">
      <Col>
        <div className="w-100 text-center">{t('table.postulantName')}</div>
      </Col>
      <Col>
        <div className="w-100 text-center">{t('table.offer')}</div>
      </Col>
      <Col>
        <div className="w-100 text-center">{t('table.vacancies')}</div>
      </Col>
      <Col xs={1}>
        <div className="w-100 text-center">{t('table.sections')}</div>
      </Col>
    </Row>
  );
};

export default SelectedUserHeader;
