import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// Components
import {
  addToast,
  Button,
  Icon,
  Modal,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { PostulationApplicationStage, PostulationStageStep } from '../../types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSavePostulationManagmentStageMutation } from '../../../api';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import { formatToOnlyPositiveIntegers } from '../../../../../utils/formatter';
import { useForm } from 'react-hook-form';

// Types
export type ReviewStepModalMethods = {
  open: (props: PostulationApplicationStage) => void;
  close: () => void;
};

type ReviewStepModalProps = {
  maxCalification?: number;
  minCalification?: number;
  postulationId: string | number;
  onConfirm?: () => void;
};

type ReviewPayload = {
  observation: string | null;
  calification?: string;
  status: PostulationStageStep;
};

const keyPrefix = 'views.recruitment.management';

// Render
const ReviewStepModal = (
  {
    postulationId,
    maxCalification = 0,
    minCalification = 0,
    onConfirm,
  }: ReviewStepModalProps,
  ref: React.Ref<ReviewStepModalMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const [saveStage, { isLoading }] = useSavePostulationManagmentStageMutation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<PostulationApplicationStage>();

  const { control, watch, reset, setValue, handleSubmit } =
    useForm<ReviewPayload>({
      defaultValues: {
        observation: '',
        calification: undefined,
        status: PostulationStageStep.Pending,
      },
    });

  const [calification, status] = watch(['calification', 'status']);

  const calificationInt = useMemo(
    () => parseInt(`${calification?.trim() || 0}`, 10),
    [calification],
  );

  const calificationHasError = useMemo(
    () =>
      (calificationInt > 0 && calificationInt < minCalification) ||
      calificationInt > maxCalification,
    [calificationInt, maxCalification, minCalification],
  );

  const submitEnabled = useMemo(() => {
    if (step?.hasCalification) {
      if (calification?.trim()) {
        return !calificationHasError && calificationInt >= minCalification;
      }
      return false;
    }
    return true;
  }, [calification, calificationHasError, calificationInt, minCalification, step?.hasCalification]);

  const handleOpen = useCallback(
    (props: PostulationApplicationStage) => {
      setIsOpen(true);
      setStep(props);
      setValue(
        'calification',
        props?.calification ? `${props?.calification}` : undefined,
      );
      setValue('observation', props?.observation?.trim() || '');
      setValue('status', props?.status || PostulationStageStep.Pending);
    },
    [setValue],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setStep(undefined);
    reset();
  }, [reset]);

  const handleToggle = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsOpen((prev) => !prev);
  }, [isLoading]);

  const handleConfirm = async (params: ReviewPayload) => {
    if (isLoading || !step?.id) {
      return;
    }
    try {
      await saveStage({
        id: postulationId,
        payload: {
          ...params,
          calification: step?.hasCalification
            ? parseInt(`${params?.calification || 0}`, 10)
            : undefined,
          applicationStageId: step?.id,
          postulantApplicationStageId: step?.postulantApplicationStageId,
        },
      }).unwrap();

      addToast({
        icon: 'check',
        color: 'success',
        text: t('updated'),
      });

      handleClose();
      onConfirm && onConfirm();
    } catch (_error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('failed'),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-review d-flex w-100 flex-column align-items-center">
        <Icon name="warning" color="primary" size={52} />
        <span className="d-block w-100 text-dark fs-22 lh-30 text-center mt-4 mb-0">
          {t('approveStage', {
            name: step?.name?.trim() || 'N/D',
          })}
        </span>
        <span className="d-block w-100 text-light fs-18 lh-30 text-center mb-4">
          {t('approveStageDescription')}
        </span>
        <div className="w-100 d-flex justify-content-center mb-5">
          <RadioButton
            className="form-check-inline mx-3"
            checked={status === PostulationStageStep.Approved}
            name="reviewStepStatus"
            id="approveRadio"
            label={t('approve')}
            disabled={isLoading}
            value={PostulationStageStep.Approved}
            onChangeValue={(v) => setValue('status', v)}
          />
          <RadioButton
            className="form-check-inline mx-3"
            checked={status === PostulationStageStep.Rejected}
            name="reviewStepStatus"
            id="rejectRadio"
            label={t('reject')}
            disabled={isLoading}
            value={PostulationStageStep.Rejected}
            onChangeValue={(v) => setValue('status', v)}
          />
        </div>
        <span className="w-100 text-primary fs-20 lh-30 text-left font-weight-bold text-uppercase">
          {t('observation')}
        </span>
        <TextAreaInput
          className="w-100"
          control={control}
          label=""
          disabled={isLoading}
          name="observation"
        />
        {!!step?.hasCalification && (
          <div className="w-100 mt-4">
            <span className="w-100 d-block text-primary fs-20 lh-30 text-left font-weight-bold text-uppercase mb-2">
              {t('calification')}
            </span>
            <TextInput
              label={t('calification')}
              name="calification"
              control={control}
              disabled={isLoading}
              formatter={formatToOnlyPositiveIntegers}
            />
            <span
              className={`w-100 ${calificationHasError ? 'text-danger' : ''}`}
            >
              {t('calificationRule', {
                min: minCalification,
                max: maxCalification,
              })}
            </span>
          </div>
        )}

        <div className="container-fluid px-0 mt-4">
          <div className="row wrap">
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleClose}
                text={t('cancel')}
                outlined={true}
                disabled={isLoading}
              />
            </div>
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleSubmit(handleConfirm)}
                text={t('confirm')}
                loading={isLoading}
                disabled={!submitEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ReviewStepModal);
