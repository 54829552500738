// Components
import { Collapse, Table } from '@octano/global-ui';
import { Col } from 'reactstrap';

// I18n
import {
  AuthorizationStatus,
  GetPostulatsToAuthorizeFilters,
  PostulantToAuthorizeItem,
} from '../../types';

// Utils
import { format } from 'rut.js';
import UsePostulantsAuthorizationPostulationsColumns, {
  UsePostulantsAuthorizationPostulationsColumnsProps,
} from '../hooks/usePostulantsAuthorizationPostulationsColumns';
import { useGetPostulantsToAuthorizePostulationsQuery } from '../../api';
import { useState } from 'react';
import { CurrentStep } from '../../../recruitment/offer-detail/types';

// Types
type ItemSelect = {
  label: string;
  value: string;
};

export type FilterFields = {
  name: string | null;
  offerId: ItemSelect | null;
  campusId: ItemSelect | null;
  careerId: ItemSelect | null;
  statusId: ItemSelect | null;
};

export type OnFilterParams = GetPostulatsToAuthorizeFilters;

export type FilterFieldsSubmit = {
  name: string | null;
  offerId: { value: string };
  campusId: { value: string };
  careerId: { value: string };
  statusId: { value: string };
};

type PostulantsAuthorizationRowProps =
  UsePostulantsAuthorizationPostulationsColumnsProps & {
    item: PostulantToAuthorizeItem;
    filters?: OnFilterParams;
  };

// Render
const PostulantsAuthorizationRow = (props: PostulantsAuthorizationRowProps) => {
  const { item } = props;
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

  const { data = [], isFetching } =
    useGetPostulantsToAuthorizePostulationsQuery(
      [item?.user?.id, props?.filters],
      {
        refetchOnMountOrArgChange: true,
        skip: !isOpenCollapse,
      },
    );

  const columns = UsePostulantsAuthorizationPostulationsColumns({
    ...props,
    onPressAuthorizeAll: () => {
      props?.onPressAuthorizeAll &&
        props?.onPressAuthorizeAll(
          item?.user?.id,
          data?.map((e) => e?.id),
        );
    },
    userId: item?.user?.id,
  });

  return (
    <Collapse
      renderTitle={() => (
        <>
          <Col>
            <span className="text-center w-100">{item?.user?.name}</span>
          </Col>
          <Col>
            <span className="text-center w-100">
              {format(item?.user?.runOrPassport)}
            </span>
          </Col>
          <Col>
            <span className="text-center w-100">
              {item?.postulationCounter?.total}
            </span>
          </Col>
          <Col>
            <span className="text-center w-100 fw-700">{`${item?.postulationCounter?.changedStatus}/${item?.postulationCounter?.total}`}</span>
          </Col>
        </>
      )}
      headerClassName="row"
      renderBtn={({ isOpened, toggle }) => (
        <>
          <Col xs={1}>
            <div className="w-100">
              <Collapse.ToggleButton
                iconName={isOpened ? 'minus' : 'plus'}
                toggle={() => {
                  setIsOpenCollapse(!isOpened);
                  toggle();
                }}
                btnClassName="bg-primary mx-auto"
                iconColor="white"
              />
            </div>
          </Col>
        </>
      )}
    >
      <Table
        columns={columns}
        isLoadingResults={isFetching}
        data={[
          ...data,
          {
            id: 0,
            offerName: '',
            currentStep: CurrentStep.Hired,
            authorizationStatus: AuthorizationStatus.Hired,
          },
        ]}
        striped
        color="transparent"
        borderless={false}
      />
    </Collapse>
  );
};

export default PostulantsAuthorizationRow;
