import { Box, Button, useMobile } from '@octano/global-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import { useStepState } from '../../hooks/useStepState';
import diffForHumans from '../../utils/time';

interface Props {
  loadingBack?: boolean;
  buttonText?: string;
  onBack?: () => void;
  updatedAt?: Date;
  description?: string;
}

export default function HeaderSection({
  buttonText,
  loadingBack = false,
  onBack,
  updatedAt,
  description,
}: Props) {
  const { t } = useTranslation();
  const { prevStep } = useStepState();
  const isMobile = useMobile();

  const backAction = onBack ?? prevStep;

  const timeFromNow = diffForHumans(updatedAt);

  return (
    <Container fluid className="mb-3">
      <Button
        icon="back"
        onClick={backAction}
        outlined
        rounded
        disabled={loadingBack}
        loading={loadingBack}
        size="sm"
        text={buttonText ?? t(`common.actions.goBackToPreviousStep`)}
        className="mb-4"
      />

      {updatedAt && (
        <Box
          body={timeFromNow}
          color="secondary"
          title={t('common.terms.applicationDate')}
          variant="outlined"
          className="float-right"
          fullwidth={isMobile}
        />
      )}
      {description && <p className="fs-16">{description}</p>}
    </Container>
  );
}
