// I18n
import { useTranslation } from 'react-i18next';

// Types
import { CheckNames } from '../../../../../types/GenericFormTypes';
import {
  AddNewItemModalMethods,
  ItemType,
} from '../../../parts/AddNewItemModal';

import ChecklistSection2 from '../../../../../components/steps/ChecklistSection2';

type RequirementStepComponentProps = {
  onClickAddButton?: AddNewItemModalMethods['open'];
};

const keyPrefix = 'views.addOffer.offerQuestions';

// Render
const RequirementStepComponent = ({
  onClickAddButton,
}: RequirementStepComponentProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const handleClickAdd = (type: ItemType) => {
    onClickAddButton && onClickAddButton(type);
  };

  return (
    <>
      <ChecklistSection2
        name={CheckNames.Requests}
        title={t(`request.title`)}
        subtitle={t(`request.subtitle`)}
        buttonTitle={t(`request.addNew.title`)}
        hasIcon={false}
        hasDescription={true}
        onClickAddButton={() => handleClickAdd('request')}
        className="mt-0"
        canAddItems={false}
        required="some"
      />
      <ChecklistSection2
        name={CheckNames.Questions}
        title={t(`question.title`)}
        subtitle={t(`question.subtitle`)}
        buttonTitle={t(`question.addNew.title`)}
        hasIcon={false}
        hasDescription={true}
        onClickAddButton={() => handleClickAdd('question')}
        className="mt-0"
      />
    </>
  );
};

export default RequirementStepComponent;
