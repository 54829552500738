import { OfferGuest } from '../../../draftMaintainer/types';
import InvitedItem from './InvitedItem';

// Types
type InvitedsBoxProps = {
  title: string;
  subtitle: string;
  inviteds?: OfferGuest[];
};

// Render
const InvitedsBox = ({ title, subtitle, inviteds = [] }: InvitedsBoxProps) => {
  return (
    <div className="px-4 py-4">
      <h3 className="fw-700 fs-20 text-primary">{title}</h3>
      <span className="fs-14 text-light">{subtitle}</span>
      <div className="container-fluid mt-3">
        <div className="d-flex row wrap">
          {inviteds?.map((invited, i) => (
            <InvitedItem {...invited} key={`invited-${i}`} className="mr-2 mb-2" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvitedsBox;
