import { Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { PathsLayouts } from '../../../config/routes';
import { TokenStatus } from '../../../types/auth';
import AccountContainer from '../shared/AccountContainer';

const prefix = `views.account.activate.activationForm`;

export default function ActivateAccountFinish() {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation<TokenStatus>();
  const expired = useMemo(
    () => location.state?.expired || undefined,
    [location],
  );

  const goBackHome = () => history.push(`${PathsLayouts.auth}/login`);

  if (expired) setTimeout(goBackHome, 10000);

  return (
    <AccountContainer
      icon={expired ? 'information' : 'success'}
      title={
        expired ? t(`${prefix}.expiredTitle`) : t(`${prefix}.successTitle`)
      }
      subTitle={
        expired ? t(`${prefix}.expiredDesc`) : t(`${prefix}.successDesc`)
      }
      wrapped
    >
      <div className="py-4">
        <Button
          type="button"
          text={t(`common.actions.logIn`)}
          onClick={() => history.push(`${PathsLayouts.auth}`)}
          fullwidth
        />
      </div>
    </AccountContainer>
  );
}
