import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import Loading from '../../../../../components/info/Loading';
import { getFormData } from '../../api';
import { SubjectFiltersResponse } from '../../type';
import DisplayError from '../../../../../components/info/DisplayError';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  SubjectFiltersResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useSubjectFiltersLoader() {
  return useFetch();
}

export const SubjectFiltersLoaderConsumer = FetchConsumer;

export default function SectionsFilterLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<SubjectFiltersResponse, AxiosResultDefaultError>
  > => {
    return getFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading />;
          } else if (error) {
            return (
              <DisplayError typeError={error.code} retryAction={refresh} />
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
