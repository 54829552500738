import { useMemo } from 'react';

import { Button, ColumnTable, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { PostulationStageStep } from '../../types';

const keyPrefix = 'views.recruitment.management';

type UseStepColumnsProps<T> = {
  maxCalification?: number;
  onClickReview?: (item: T) => void;
};

export default function useStepColumns<T>({
  maxCalification = 0,
  onClickReview,
}: UseStepColumnsProps<T> = {}) {
  const { t } = useTranslation('translation', { keyPrefix });

  const columns = useMemo<ColumnTable<T>[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: t('applicationStage'),
        tdClassName: 'text-left',
        thClassName: 'text-left',
      },
      {
        columnName: 'observation',
        headerText: t('observation'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'calification',
        headerText: t('calification'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        // width: '120px',
        cellFormat: ({ value, row }: any) => {
          if (row?.hasCalification) {
            if (row?.status !== PostulationStageStep.Pending) {
              return <strong>{`${value || '0'}/${maxCalification}`}</strong>;
            } else {
              return <></>;
            }
          }
          return t('notApplicable');
        },
      },
      {
        columnName: 'status',
        headerText: t('reviewStatus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        // width: '150px',
        cellFormat: ({ value }) => (
          <div className="d-flex justify-content-center align-center flex-row align-items-center">
            {value === PostulationStageStep.Approved && (
              <Icon name="check" size={15} color="success" />
            )}
            {value === PostulationStageStep.Rejected && (
              <Icon name="error" size={15} color="danger" />
            )}
            {value === PostulationStageStep.Pending && (
              <Icon name="warning" size={15} color="warning" />
            )}
            <p className="ml-2 mr-0 my-0">{t(`statuses.${value}`)}</p>
          </div>
        ),
      },
      {
        columnName: 'action',
        headerText: t('action'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        // width: '150px',
        cellFormat: ({ row }) => (
          <div>
            <Button
              onClick={() => onClickReview && onClickReview(row)}
              text={t('review')}
              size="sm"
            />
          </div>
        ),
      },
    ];
  }, [maxCalification, onClickReview, t]);

  return columns;
}
