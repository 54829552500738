// Components
import PostulantsAuthorizationFilters, {
  OnFilterParams as Filters,
} from './parts/Filters';
import DisplayError from '../../../components/info/DisplayError';
import {
  Button,
  Dialog,
  DisplayInfo,
  Table,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import { Card, Row, Col } from 'reactstrap';
import AuthorizePostulationModal, {
  AuthorizePostulationModalMethods,
} from './parts/AuthorizePostulationModal';

// Hooks
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetAuthorizedPostulantsQuery,
  useUpdatePostulantPostulationsMutation,
} from '../api';
import useAuthorizedPostulantsColumns from './hooks/useAuthorizedPostulantsColumns';
import AuthorizedPostulantsProvider from '../../../providers/AuthorizedPostulantsProvider';
import { AuthorizationStatus } from '../../academic-authorization/types';
import { useHistory } from 'react-router-dom';

// Redux
import { useAppSelector, useAppDispatch } from '../../../store';
import { selectedsSelector } from '../selectors';
import { hiringSlice } from '../slice';

// Types
const ITEMS_PER_PAGE = 10;
const keyPrefix = 'views.hiring.authorizedPostulants';

const AuthorizedPostulantsScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const authorizePostulationRef =
    useRef<AuthorizePostulationModalMethods>(null);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  const selecteds = useAppSelector(selectedsSelector);

  const {
    data: postulantsData,
    isFetching,
    error,
    refetch,
  } = useGetAuthorizedPostulantsQuery(
    {
      ...filters,
      page: currentPage - 1,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [updatePostulationsStatus, { isLoading: isSaving }] =
    useUpdatePostulantPostulationsMutation();

  const postulants = useMemo(
    () => postulantsData?.data || [],
    [postulantsData?.data],
  );
  const totalPostulants = useMemo(
    () => postulantsData?.total || 0,
    [postulantsData?.total],
  );
  const totalPages = useMemo(
    () => postulantsData?.totalPages || 0,
    [postulantsData?.totalPages],
  );

  const isAllSelected = useMemo(() => {
    const ids = Array.from(postulants)
      ?.filter((item) => !!item?.user?.id)
      ?.map((item) => item?.user?.id);
    let set = new Set([...selecteds]);
    let isUnSelect = !!ids?.length;

    for (let i = 0; i < ids?.length; i++) {
      if (!set.has(ids[i])) {
        isUnSelect = false;
      }
      if (!isUnSelect) {
        break;
      }
    }
    return isUnSelect;
  }, [postulants, selecteds]);

  const setSelecteds = useCallback(
    (next: number[]) => {
      dispatch(hiringSlice?.actions?.setSelectsItems(next));
    },
    [dispatch],
  );

  const handleFilter = useCallback(
    (params: Filters) => {
      setCurrentPage(1);
      setFilters(params);
    },
    [setFilters],
  );

  const handleToggleItem = useCallback(
    (userId: number) => {
      const _items = new Set(selecteds);
      if (_items?.has(parseInt(`${userId}`, 10))) {
        _items?.delete(parseInt(`${userId}`, 10));
      } else {
        _items?.add(parseInt(`${userId}`, 10));
      }
      setSelecteds(Array.from(_items));
    },
    [selecteds, setSelecteds],
  );

  const handleToggleAll = useCallback(() => {
    // onToggleAll
    if (isAllSelected) {
      setSelecteds([]);
    } else {
      const ids = Array.from(postulants)
        ?.filter((item) => !!item?.user?.id)
        ?.map((item) => item?.user?.id);
      setSelecteds(Array.from(new Set(ids))?.concat(selecteds));
    }
  }, [isAllSelected, postulants, selecteds, setSelecteds]);

  const postulationReject = useCallback(
    async (userId: string | number, postulationId: string | number) => {
      try {
        await updatePostulationsStatus({
          userId,
          postulationIds: [postulationId],
          authorizationStatus: AuthorizationStatus.DiscardedInHiring,
        });
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${keyPrefix}.rejectModal.success`),
        });
        refetch();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${keyPrefix}.rejectModal.failed`),
        });
      }
    },
    [refetch, t, updatePostulationsStatus],
  );

  const requestAuthorize = useCallback(
    (userId: string | number, postulationId: string | number) => {
      authorizePostulationRef?.current?.open(userId, postulationId);
      // showDialogConfirm({
      //   title: t(`${keyPrefix}.authorizeModal.title`),
      //   subtitle: t(`${keyPrefix}.authorizeModal.subtitle`),
      //   btnClose: {
      //     text: t(`common.actions.cancel`),
      //   },
      //   btnConfirm: {
      //     text: t(`common.actions.confirm`),
      //     onConfirm: () => postulationAuthorize(userId, postulationId),
      //   },
      // });
    },
    [],
  );
  const requestReject = useCallback(
    (userId: string | number, postulationId: string | number) => {
      showDialogConfirm({
        title: t(`${keyPrefix}.rejectModal.title`),
        subtitle: t(`${keyPrefix}.rejectModal.subtitle`),
        btnClose: {
          text: t(`common.actions.cancel`),
        },
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => postulationReject(userId, postulationId),
        },
      });
    },
    [postulationReject, t],
  );

  const handleGenerateUser = useCallback(
    (userId: string | number) => {
      let search = `?userIds=${userId}`;
      Object.entries(filters ?? {})?.forEach(([key, value]) => {
        if (value && key !== 'name') {
          search += `&${key}=${value}`;
        }
      });
      history.push({
        pathname: `/hiring/assign-sections`,
        search,
      });
    },
    [filters, history],
  );

  const handleGenerateSelectedsUser = useCallback(
    (userIds: (string | number)[]) => {
      let search = `?userIds=${userIds?.join(',')}`;
      Object.entries(filters ?? {})?.forEach(([key, value]) => {
        if (value && key !== 'name') {
          search += `&${key}=${value}`;
        }
      });
      history.push({
        pathname: `/hiring/assign-sections`,
        search,
      });
    },
    [filters, history],
  );

  const columns = useAuthorizedPostulantsColumns({
    isAllSelected,
    filters,
    onToggleAll: handleToggleAll,
    onPressAuthorize: requestAuthorize,
    onPressReject: requestReject,
    onPressGenerate: handleGenerateUser,
  });

  useEffect(() => {
    if (!isMounted) {
      dispatch(hiringSlice?.actions?.setSelectsItems([]));
      setIsMounted(true);
    }
  }, [dispatch, isMounted]);

  return (
    <AuthorizedPostulantsProvider
      value={{ selecteds, onToggle: handleToggleItem }}
    >
      <Card className="p-4">
        <Row>
          <Col className="mb-4" xs={12}>
            <p className="fs-16">{t(`${keyPrefix}.description`)}</p>
          </Col>
        </Row>
        <PostulantsAuthorizationFilters onFilter={handleFilter} />
        {Boolean(selecteds?.length) && (
          <Row>
            <Col
              className="d-flex justify-content-end align-items-center"
              xs={12}
            >
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  icon="certificates_requests"
                  style={{ height: 40, minHeight: 20 }}
                  text={t(`${keyPrefix}.generateAll`)}
                  onClick={() => handleGenerateSelectedsUser(selecteds)}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col xs={12} className="pt-1 mb-5 g-table-hidden-x">
            <Table
              columns={columns}
              data={!isFetching && !isSaving ? postulants : []}
              isLoadingResults={isFetching || isSaving}
              loadingView={{
                title: t(`common.loading.title`),
                subtitle: t(`common.loading.subtitle`),
              }}
              noResultsText={
                (error as any) ? (
                  <DisplayError typeError={(error as any)?.status} />
                ) : (
                  <div className="d-flex flex-column align-items-center no-result">
                    <DisplayInfo
                      title={t(`${keyPrefix}.empty.title`)}
                      textBody={t(`${keyPrefix}.empty.body`)}
                    />
                  </div>
                )
              }
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                onChangePage: (page: number) => {
                  setCurrentPage(page);
                },
                totalItems: totalPostulants,
                totalPages: totalPages,
              }}
            />
          </Col>
        </Row>
      </Card>
      <AuthorizePostulationModal
        ref={authorizePostulationRef}
        onConfirm={refetch}
      />
      <Dialog />
    </AuthorizedPostulantsProvider>
  );
};

export default AuthorizedPostulantsScreen;
