// Components
import { Row, Col } from 'reactstrap';
import { TextInput } from '@octano/global-ui';

// Utils
import { useValidations } from '../../../../hooks/useValidations';

// Types
import { FormFields } from '../index';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ContractViewfindersRowInputsProps = {
  name: 'teachingViewers' | 'othersViewers';
  control?: Control<FormFields>;
};

// Render
const ContractViewfindersRowInputs = ({
  control,
  name,
}: ContractViewfindersRowInputsProps) => {
  const { t } = useTranslation();
  const { validateTextNotEmpty, validateEmail, validateRut } = useValidations();

  const { fields } = useFieldArray({
    control,
    name,
  });
  return (
    <div className="w-100">
      {fields.map((item, index) => (
        <Row key={index} className="mb-5 mx-2">
          <Col xs={12} className="mb-1">
            <span className="w-100 d-block fs-16 fw-700 text-primary text-uppercase">
              {item.title}
            </span>
          </Col>

          <Col xs={12} sm={6} className="mb-1">
            <TextInput
              control={control}
              name={`${name}.${index}.viewfinder.rut`}
              formatter="rut"
              label={t(`views.settings.contractViewfinders.rut`)}
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                  rut: validateRut,
                },
              }}
            />
          </Col>
          <Col xs={12} sm={6} className="mb-1">
            <TextInput
              control={control}
              name={`${name}.${index}.viewfinder.email`}
              label={t(`views.settings.contractViewfinders.email`)}
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                  email: validateEmail,
                },
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ContractViewfindersRowInputs;
