import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// Components
import { addToast, Button, Modal, TextOutlinedInput } from '@octano/global-ui';

// Hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { OfferGuest } from '../../../../draftMaintainer/types';

// Types
export type AddEmailModalMethods = {
  open: (guest?: OfferGuest) => void;
  close: () => void;
};

type AddEmailModalProps = {
  list?: OfferGuest[];
  onConfirm?: (next: OfferGuest) => void;
};

type SavePayload = {
  name: string;
  email: string;
};

const keyPrefix = 'views.addOffer.offerInvitation.modalAdd';

// Render
const AddEmailModal = (
  { list = [], onConfirm }: AddEmailModalProps,
  ref: React.Ref<AddEmailModalMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const isLoading = false;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { control, watch, reset, setValue, handleSubmit } =
    useForm<SavePayload>({
      defaultValues: {
        email: '',
      },
    });

  const [email, name] = watch(['email', 'name']);

  const submitEnabled = useMemo(() => {
    return !!(
      email
        ?.trim()
        ?.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) && name?.trim()
    );
  }, [email, name]);

  const handleOpen = useCallback(
    (next?: OfferGuest) => {
      setIsOpen(true);
      setValue('name', next?.name?.trim() || '');
      setValue('email', next?.email?.trim() || '');
    },
    [setValue],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset]);

  const handleToggle = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsOpen((prev) => !prev);
  }, [isLoading]);

  const handleConfirm = (params: SavePayload) => {
    try {
      if (
        list?.some(
          (e) =>
            e?.email?.trim()?.toLowerCase() ===
            params?.email?.trim()?.toLowerCase(),
        )
      ) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('exists'),
        });
        return;
      }
      handleClose();
      onConfirm &&
        onConfirm({
          name: params?.name?.trim(),
          email: params?.email?.trim()?.toLowerCase(),
        });
    } catch (_error) {}
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-add-invitaion d-flex w-100 flex-column align-items-center">
        <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-0">
          {t('title')}
        </span>
        <span className="d-block w-100 text-light fs-18 lh-30 text-center mb-4">
          {t('subtitle')}
        </span>
        <div className="w-100">
          <TextOutlinedInput
            control={control}
            label={t('inputName')}
            placeholder={t('inputNamePlaceholder')}
            disabled={isLoading}
            name="name"
          />
        </div>
        <div className="w-100">
          <TextOutlinedInput
            control={control}
            label={t('inputEmail')}
            placeholder={t('inputEmailPlaceholder')}
            disabled={isLoading}
            name="email"
          />
        </div>

        <div className="container-fluid px-0 mt-4">
          <div className="row wrap">
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleClose}
                text={t('cancel')}
                outlined={true}
                disabled={isLoading}
              />
            </div>
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                onClick={handleSubmit(handleConfirm)}
                text={t('confirm')}
                loading={isLoading}
                disabled={!submitEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(AddEmailModal);
