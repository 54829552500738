// Components
import OfferDetailsStep from '../steps/OfferDetailsStep';
import RequirementStep from '../steps/RequirementStep';
import RequirementApplicantStep from '../steps/RequirementApplicantStep';
import StagesProcessStep from '../steps/StagesProcessStep';
import ReviewOfferStep from '../steps/ReviewOfferStep';
import InvitationStep from '../steps/IntivationStep';
import Loading from '../../../components/info/Loading';
import { addToast } from '@octano/global-ui';

// Hooks
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import {
  useSaveDraftMutation,
  useUpdateDraftStepMutation,
  useCreateDraftIdMutation,
} from '../api';

// Types
import {
  CreateOfferStep,
  CreateOfferStepArea,
  CreateOfferStepCode,
} from '../types';
import { OfferType } from '../../../types/jobOffer';

export type OnSubmitParams = {
  area: CreateOfferStepArea;
  nextStep?: CreateOfferStepCode;
  payload: any;
  next?: boolean;
  successMsg: string;
  failedMsg: string;
};

type RenderComponentProps = {
  scope: OfferType;
  current?: CreateOfferStep | null;
  loading?: boolean;
  offerDraftId?: string | number;
  onSave?: () => Promise<void>;
};

// Render
const RenderComponent = ({
  current,
  scope,
  offerDraftId,
  loading = false,
  onSave,
}: RenderComponentProps) => {
  const history = useHistory();
  const [createdId, setCreatedId] = useState<number | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [createDraftId] = useCreateDraftIdMutation();
  const [saveDraft] = useSaveDraftMutation();
  const [updateStep] = useUpdateDraftStepMutation();

  const handleCreateId = useCallback(async () => {
    if (createdId) {
      return createdId;
    }
    const id = await createDraftId()?.unwrap();
    setCreatedId(id);
    return id;
  }, [createDraftId, createdId]);

  const handleSubmit = useCallback(
    async (params: OnSubmitParams) => {
      const { area, nextStep, payload, next, successMsg, failedMsg } = params;
      try {
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
        let id = offerDraftId || createdId;
        if (!id) {
          id = await handleCreateId();
        }
        let draftId = id;
        if (next) {
          switch (nextStep) {
            case CreateOfferStepCode.SendInvitations:
              draftId = `confirm/${id}`;
              break;
          }
        }
        console.log('nextStep', nextStep);
        console.log('draftId', draftId);
        await saveDraft({
          id: draftId,
          area,
          payload,
        })?.unwrap();

        if (next && nextStep) {
          if (
            nextStep === CreateOfferStepCode.Creation ||
            nextStep === CreateOfferStepCode.SendInvitations
          ) {
            addToast({
              icon: 'success',
              color: 'success',
              text: successMsg,
            });
            history.push('/offer-maintainer/published-offers');
            return;
          } else {
            await updateStep({
              id,
              step: nextStep,
            })?.unwrap();
          }
        }

        addToast({
          icon: 'success',
          color: 'success',
          text: successMsg,
        });

        if (!offerDraftId) {
          const nextUrl =
            window?.location?.origin + `/offer-maintainer/create-offer/${id}`;
          window.location.replace(nextUrl);
        }

        if (onSave) {
          await onSave();
        }
        setIsSubmitting(false);
      } catch (_error) {
        setIsSubmitting(false);
        addToast({
          icon: 'error',
          color: 'danger',
          text: failedMsg,
        });
      }
    },
    [
      createdId,
      handleCreateId,
      history,
      isSubmitting,
      offerDraftId,
      onSave,
      saveDraft,
      updateStep,
    ],
  );

  const componentToRender = useMemo(() => {
    let RenderComponent;
    switch (current?.code) {
      case CreateOfferStepCode.OfferDetails:
        RenderComponent = OfferDetailsStep;
        break;
      case CreateOfferStepCode.Requirement:
        RenderComponent = RequirementStep;
        break;
      case CreateOfferStepCode.RequirementApplicant:
        RenderComponent = RequirementApplicantStep;
        break;
      case CreateOfferStepCode.StagesProcess:
        RenderComponent = StagesProcessStep;
        break;
      case CreateOfferStepCode.ReviewOffer:
        RenderComponent = ReviewOfferStep;
        break;
      case CreateOfferStepCode.InviteUsers:
        RenderComponent = InvitationStep;
        break;
    }
    if (RenderComponent && current) {
      return (
        <RenderComponent
          {...current?.renderComponentProps}
          isSubmitting={isSubmitting}
          scope={scope}
          offerDraftId={offerDraftId || createdId}
          onSave={onSave}
          onSubmit={handleSubmit}
        />
      );
    }
    return <></>;
  }, [
    createdId,
    current,
    handleSubmit,
    isSubmitting,
    offerDraftId,
    onSave,
    scope,
  ]);

  if (loading) {
    return (
      <div className="w-100 p-4">
        <Loading />
      </div>
    );
  }

  return <div className="w-100 p-4">{componentToRender}</div>;
};

export default RenderComponent;
