import dayjs from 'dayjs';
import { SaveDraftFlow } from '../../../../types/offerDraftFlowTypes';
import { CurrentStep } from '../../../../types/stepsTypes';
import { OfferDetailsFormFields } from './types';
import { ListsDictionary } from '../../hooks/useGetCreationLists';

export const createFillFormObject = (
  offerData: CurrentStep['offer'],
  lists: ListsDictionary,
): OfferDetailsFormFields | undefined => {
  if (!offerData) {
    return undefined;
  }
  return {
    schools: offerData?.school
      ? {
          label: offerData?.school?.name,
          value: offerData?.school?.id,
        }
      : null,
    contractTypes: offerData?.contract
      ? {
          label: offerData?.contract?.name,
          value: offerData?.contract?.id,
        }
      : null,
    workingDays: offerData?.workingDay
      ? {
          label: offerData?.workingDay?.name,
          value: offerData?.workingDay?.id,
        }
      : null,
    campus: offerData?.campus
      ? {
          label: offerData?.campus?.name,
          value: offerData?.campus?.id,
        }
      : null,
    modalities: offerData?.modality
      ? {
          label: offerData?.modality?.name,
          value: offerData?.modality?.id,
        }
      : null,
    specialtyArea: offerData?.specialtyArea
      ? {
          label: offerData?.specialtyArea?.name,
          value: offerData?.specialtyArea?.id,
        }
      : null,

    careers: offerData?.career
      ? {
          label: offerData?.career?.name,
          value: offerData?.career?.id,
        }
      : null,
    subjects: offerData?.subject
      ? {
          label: offerData?.subject?.name,
          value: offerData?.subject?.id,
        }
      : null,
    periods: offerData?.period
      ? {
          label: offerData?.period?.name,
          value: offerData?.period?.id,
        }
      : null,
    vacanciesNumber: offerData?.vacant?.toString(),
    subjectHours: offerData?.subjectHours?.toString() || '',
    offerDescription: offerData?.description,
    initialDate: offerData?.initialDate,
    finishDate: offerData?.finishDate,
    startContractDate: offerData?.initialDate,
    inclusionLaw: 'si' === offerData?.inclusionLaw,
    salaryFrom: offerData?.salaryMin?.toString() || '',
    salaryTo: offerData?.salaryMax?.toString() || '',
    levelResponsibility: offerData?.levelResponsibility,
    benefits: lists.benefits.map((r) => {
      return {
        ...r,
        isMark: Boolean(offerData?.benefits?.some((o) => o === r.id)),
      };
    }),
    laws: lists.laws.map((q) => {
      return {
        ...q,
        isMark: Boolean(offerData?.laws?.some((o) => o === q.id)),
      };
    }),
  };
};

export const generatePayload = (
  values: OfferDetailsFormFields,
  type: string,
): SaveDraftFlow => {
  return {
    contractId: values.contractTypes?.value,
    workingDayId: values.workingDays?.value,
    campusId: values.campus?.value,
    modalityId: values.modalities?.value,
    specialtyAreaId: values.specialtyArea?.value,
    schoolId: values.schools?.value,
    subjectId: values.subjects?.value,
    subjectHours: values.subjectHours ? parseInt(values.subjectHours) : null,
    periodId: values.periods?.value,
    careerId: values.careers?.value,
    vacant: values.vacanciesNumber ? parseInt(values.vacanciesNumber) : null,
    initialDate: values.initialDate
      ? dayjs(values.initialDate).format('YYYY-MM-DD')
      : null,
    finishDate: values.finishDate
      ? dayjs(values.finishDate).format('YYYY-MM-DD')
      : null,
    inclusionLaw: values.inclusionLaw ? 'si' : 'no',
    salaryMin: values.salaryFrom ? parseInt(values.salaryFrom) : null,
    salaryMax: values.salaryTo ? parseInt(values.salaryTo) : null,
    description: values.offerDescription,
    levelResponsibility: values.levelResponsibility,
    benefits: values.benefits.filter((o) => o.isMark).map((o) => o?.id!),
    laws: values.laws.filter((o) => o.isMark).map((o) => o.id!),
    type,
  };
};
