// Components
import { addToast, Button, TextOutlinedInput } from '@octano/global-ui';
import { Col, Form, Modal, Row } from 'reactstrap';

// Hooks
import {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Redux
import { useCreateOfferReqMutation } from '../api';

// Types
import { Base, CheckNameType } from '../../../types/GenericFormTypes';
import { CreateOfferStepArea } from '../types';
import { TEXTINPUT_MAXLENGTH } from '../../../config/constants';
import LengthCounter from '../../../components/Form/LengthCounter';

export type ItemType =
  | 'question'
  | 'request'
  | 'education'
  | 'competence'
  | 'certification'
  | 'license-type'
  | 'requirement';

export type AddNewItemModalMethods = {
  open: (type: ItemType, name?: CheckNameType) => void;
  close: () => void;
};

export type AddNewItemModalProps = {
  onSuccess?: (type: ItemType, item: Base) => void;
  onClose?: () => void;
};

const texts = {
  question: {
    title: `views.addOffer.offerQuestions.question.addNew.title`,
    buttonText: `views.addOffer.offerQuestions.question.addNew.buttonText`,
    inputText: `views.addOffer.offerQuestions.question.addNew.inputText`,
  },
  request: {
    title: `views.addOffer.offerQuestions.request.addNew.title`,
    buttonText: `views.addOffer.offerQuestions.request.addNew.buttonText`,
    inputText: `views.addOffer.offerQuestions.request.addNew.inputText`,
  },
  //
  education: {
    title: `views.addOffer.offerRequirements.education.addNew.title`,
    buttonText: `views.addOffer.offerRequirements.education.addNew.buttonText`,
    inputText: `views.addOffer.offerRequirements.education.addNew.inputText`,
  },
  competence: {
    title: `views.addOffer.offerRequirements.competence.addNew.title`,
    buttonText: `views.addOffer.offerRequirements.competence.addNew.buttonText`,
    inputText: `views.addOffer.offerRequirements.competence.addNew.inputText`,
  },
  certification: {
    title: `views.addOffer.offerRequirements.certification.addNew.title`,
    buttonText: `views.addOffer.offerRequirements.certification.addNew.buttonText`,
    inputText: `views.addOffer.offerRequirements.certification.addNew.inputText`,
  },
  'license-type': {
    title: `views.addOffer.offerRequirements.licenseType.addNew.title`,
    buttonText: `views.addOffer.offerRequirements.licenseType.addNew.buttonText`,
    inputText: `views.addOffer.offerRequirements.licenseType.addNew.inputText`,
  },
  requirement: {
    title: `views.addOffer.offerRequirements.requirement.addNew.title`,
    buttonText: `views.addOffer.offerRequirements.requirement.addNew.buttonText`,
    inputText: `views.addOffer.offerRequirements.requirement.addNew.inputText`,
  },
};

const AddNewItemModal = (
  { onSuccess, onClose }: AddNewItemModalProps,
  ref: Ref<AddNewItemModalMethods>,
) => {
  const methods = useForm<Base>({ mode: 'onChange' });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    clearErrors,
  } = methods;

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemType, setItemType] = useState<ItemType>('question');
  const [name, setName] = useState<CheckNameType>();

  const [createItem, { isLoading: creating }] = useCreateOfferReqMutation();

  const handleOpen = useCallback((t: ItemType, n?: CheckNameType) => {
    setItemType(t);
    setName(n);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    clearErrors();
    reset();
    onClose && onClose();
  }, [clearErrors, onClose, reset]);

  const submit = useCallback(
    async (values: Base) => {
      if (creating) {
        return;
      }
      try {
        const response = await createItem({
          area:
            itemType === 'question' || itemType === 'request'
              ? CreateOfferStepArea.RequirementApplicant
              : CreateOfferStepArea.Requirement,
          body: values,
          type: itemType,
        })?.unwrap();
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.responses.successfulCreation'),
        });
        onSuccess &&
          onSuccess(itemType, {
            ...response,
            nameInForm: `${name}-${response?.id}`,
            isMark: true,
          });
        clearErrors();
        reset();
        setIsOpen(false);
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.responses.failedCreation'),
        });
      }
    },
    [clearErrors, createItem, creating, itemType, name, onSuccess, reset, t],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      style={{ minWidth: '40%' }}
      isOpen={isOpen}
      centered
      contentClassName="border-0"
    >
      <div className="p-5 mx-md-5">
        <div className="text-center">
          <p className="fs-22 fw-400 text-dark">{t(texts[itemType]?.title)}</p>
        </div>

        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col className="mb-3" xs={12}>
                <TextOutlinedInput
                  name="name"
                  label={t(texts[itemType]?.inputText)}
                  placeholder={t(texts[itemType]?.inputText)}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                  maxLength={TEXTINPUT_MAXLENGTH}
                />
                <LengthCounter name="name" maxLength={TEXTINPUT_MAXLENGTH} />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="button"
                  outlined
                  text={t('common.actions.cancel')}
                  fullwidth
                  onClick={handleClose}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={t(texts[itemType]?.buttonText)}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting || creating}
                  disabled={isSubmitting || creating}
                />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default forwardRef(AddNewItemModal);
