import {
  addToast,
  Alert,
  AuthContainer,
  Button,
  Logo,
  TextInput,
} from '@octano/global-ui';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CardLink, Col, Row } from 'reactstrap';

import { accountLogin } from '../../../api/requests/auth';
import { ReactComponent as LoginImage } from '../../../assets/svg/login_image.svg';
import { ReactComponent as LogoPersonnelColor } from '../../../assets/svg/logo_personnel_color.svg';
import { ReactComponent as LogoPersonnel } from '../../../assets/svg/logo_personnel_white.svg';
import routes, { PathsLayouts } from '../../../config/routes';
import useUserState from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import { LoginField } from '../../../types/auth';
import { setJWT } from '../../../utils/auth';
import ActivateAccountModal from '../activation/ActivateAccountModal';
import PasswordRecoveryModal from '../recovery/PasswordRecoveryModal';
import SuccessModal from '../shared/SuccessModal';

export enum LoginModalType {
  None,
  Recovery,
  Activate,
  RecoverySuccess,
  ActivateSuccess,
}

const prefix = `views.account.login`;

export default function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const { setUserData } = useUserState();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<LoginField>({
    mode: 'onChange',
  });
  const { msgValidations, validateTextNotEmpty, validateEmail } =
    useValidations();

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openedModal, setOpenedModal] = useState<LoginModalType>(
    LoginModalType.None,
  );

  const modalSuccessData = useMemo(() => {
    if (
      [LoginModalType.ActivateSuccess, LoginModalType.RecoverySuccess].includes(
        openedModal,
      )
    ) {
      return {
        [LoginModalType.RecoverySuccess]: {
          title: t('views.account.recovery.successTitle'),
          description: t('views.account.recovery.successDescription'),
        },
        [LoginModalType.ActivateSuccess]: {
          title: t('views.account.activate.successTitle'),
          description: t('views.account.activate.successDescription'),
        },
      }[openedModal as number];
    }
  }, [openedModal, t]);

  const handleLogin = async (values: LoginField) => {
    setError(false);
    const { data, error } = await accountLogin({
      email: values.email,
      password: values.password,
    });

    if (error) {
      if (error?.status === 400) {
        setError(true);
        setErrorMessage(t(`${prefix}.wrongCredentials`));
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      }
    }

    if (data) {
      const permissionsNames =
        data.permissions?.map((permission) => permission.name) || [];
      setUserData({ ...data.account, permissions: permissionsNames });
      setJWT(data.access.token);

      history.push(
        `${PathsLayouts.offersMaintainer}/${
          routes[PathsLayouts.offersMaintainer].defaultPath
        }`,
      );
    }
  };

  return (
    <React.Fragment>
      <AuthContainer
        brandLogoWhite={<LogoPersonnel />}
        loginImage={<LoginImage />}
        brandLogoColor={<LogoPersonnelColor />}
      >
        <Row>
          <Col xs={12} className="text-center">
            <Logo
              alt="logo"
              style={{ maxHeight: 80 }}
              src="/tenant/logo.svg"
              fallbackSrc="/tenant/logo.png"
            />
          </Col>
          <Col xs={12} className="mt-4 mb-5 d-flex justify-content-center">
            <p className="text-center pt-4 mb-0 fs-16">
              {t(`${prefix}.welcome`)}
            </p>
          </Col>

          <Col xs={12} className="pt-2">
            <form onSubmit={handleSubmit(handleLogin)}>
              <Row>
                <Col xs={12}>
                  {error && (
                    <Alert
                      color="danger"
                      icon="error"
                      size="sm"
                      text={errorMessage}
                      toggle={() => {
                        setError(false);
                      }}
                      noFill
                    />
                  )}
                  <TextInput
                    name="email"
                    type="text"
                    control={control}
                    label={t('common.terms.email')}
                    rules={{
                      validate: {
                        email: validateEmail,
                        notEmpty: validateTextNotEmpty,
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col xs={12}>
                  <TextInput
                    name="password"
                    type="password"
                    control={control}
                    label={t('common.terms.password')}
                    rules={{
                      required: msgValidations.required,
                    }}
                  />
                </Col>
              </Row>
              <div className="py-4">
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={t(`common.actions.getIn`)}
                  fullwidth
                />
              </div>
            </form>
          </Col>
          <Col xs={12} className="text-center">
            <CardLink onClick={() => setOpenedModal(LoginModalType.Recovery)}>
              {t(`common.terms.forgotPassword`)}
            </CardLink>
          </Col>
          <Col xs={12} className="pt-3 pb-2">
            <p className="text-center mb-0">
              {t('common.terms.noAccount')}
              <CardLink
                className="ml-1"
                onClick={() => setOpenedModal(LoginModalType.Activate)}
              >
                {t(`common.terms.activateHere`)}
              </CardLink>
            </p>
          </Col>
        </Row>
      </AuthContainer>

      <PasswordRecoveryModal
        isOpen={openedModal === LoginModalType.Recovery}
        onSuccess={() => setOpenedModal(LoginModalType.RecoverySuccess)}
        onClose={() => setOpenedModal(LoginModalType.None)}
      />

      <ActivateAccountModal
        isOpen={openedModal === LoginModalType.Activate}
        onSuccess={() => setOpenedModal(LoginModalType.ActivateSuccess)}
        onClose={() => setOpenedModal(LoginModalType.None)}
      />

      <SuccessModal
        isOpen={Boolean(modalSuccessData)}
        onClose={() => setOpenedModal(LoginModalType.None)}
        title={modalSuccessData?.title}
        description={modalSuccessData?.description}
      />
    </React.Fragment>
  );
}
