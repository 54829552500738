// Components
import { Select, TextInput } from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import { Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Redux
import { useGetCreationDepQuery } from '../../../api';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { mapNameAndIdToLabelAndValue } from '../../../../../utils/mapNameAndIdToLabelAndValue';
import { formatToOnlyPositiveIntegers } from '../../../../../utils/formatter';

// Types
import { ListsDictionary } from '../../../hooks/useGetCreationLists';
import { OfferDetailsFormFields } from '../types';
import { GetCreationDepRes } from '../../../types';
import { Career } from '../../../../../types/jobOffer';

const prefix = 'views.addOffer.offerDetails.offerDetailSection';
const commonPrefix = 'common.terms';

// Render
const OfferDetailSection = ({ lists }: { lists: ListsDictionary }) => {
  const { t } = useTranslation();

  const { control, watch, setValue } = useFormContext<OfferDetailsFormFields>();
  const [school, career] = watch(['schools', 'careers']);
  const [seed, setSeed] = useState(1);

  const { data: careersData = [], isFetching: isFetchingCareers } =
    useGetCreationDepQuery<GetCreationDepRes<Career[]>>(
      { id: school?.value, dep: 'careers' },
      {
        refetchOnMountOrArgChange: true,
        skip: !school,
      },
    );
  const careers = useMemo(
    () =>
      school?.value && !isFetchingCareers
        ? careersData?.map(mapNameAndIdToLabelAndValue)
        : [],
    [careersData, isFetchingCareers, school?.value],
  );

  const { data: subjectsData = [], isFetching: isFetchingSubjects } =
    useGetCreationDepQuery<GetCreationDepRes<Career[]>>(
      { id: career?.value, dep: 'subjects' },
      {
        refetchOnMountOrArgChange: true,
        skip: !career,
      },
    );

  const handleInputChange = (keys: ('careers' | 'subjects')[]) => {
    keys.forEach((key) => {
      setValue(key, null);
    });
    setSeed(Math.random());
  };

  const subjects = useMemo(
    () =>
      career?.value && !isFetchingSubjects
        ? subjectsData?.map(mapNameAndIdToLabelAndValue)
        : [],
    [career?.value, isFetchingSubjects, subjectsData],
  );

  return (
    <Fragment key={seed}>
      <Col xs={12}>
        <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {t(`${prefix}.title`)}
        </h5>
        <p className="fs-16 m-0">{t(`${prefix}.subtitle`)}</p>
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.contractType`)}
          options={lists.contractTypes}
          name="contractTypes"
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.workingDay`)}
          options={lists.workingDays}
          name="workingDays"
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.workPlace`)}
          options={lists.campus}
          name="campus"
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.modality`)}
          options={lists.modalities}
          name="modalities"
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.specialtyArea`)}
          options={lists.specialtyArea}
          name="specialtyArea"
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.school`)}
          options={lists.schools}
          name="schools"
          control={control}
          rules={{ required: t('common.validations.required') }}
          onInputChange={() => {
            handleInputChange(['careers', 'subjects']);
          }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.career`)}
          options={careers}
          name="careers"
          control={control}
          rules={{ required: t('common.validations.required') }}
          noOptionsMessage={t(`${prefix}.noCareerOptionsMessage`)}
          onInputChange={() => {
            handleInputChange(['subjects']);
          }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.subject`)}
          options={subjects}
          name="subjects"
          control={control}
          rules={{ required: t('common.validations.required') }}
          noOptionsMessage={t(`${prefix}.noSubjectsOptionsMessage`)}
        />
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          label={t(`${commonPrefix}.subjectHours`)}
          name="subjectHours"
          control={control}
          rules={{ required: t('common.validations.required') }}
          formatter={formatToOnlyPositiveIntegers}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          label={t(`${commonPrefix}.academicPeriod`)}
          options={lists?.periods}
          name="periods"
          control={control}
          rules={{ required: t('common.validations.required') }}
          noOptionsMessage={t(`${prefix}.noPeriodsOptionsMessage`)}
        />
      </Col>
    </Fragment>
  );
};

export default OfferDetailSection;
