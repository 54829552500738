import { Icon, IconNameType } from '@octano/global-ui';
import { UncontrolledTooltip } from 'reactstrap';

interface BenefitNameType {
  benefitName: IconNameType;
  tooltip?: string;
  tooltipRequired?: boolean;
  id: string;
}

const ChecklistIcon = ({
  benefitName,
  tooltip,
  tooltipRequired,
  id,
}: BenefitNameType) => {
  return (
    <span>
      <span id={id}>
        <Icon name={benefitName} size={20} className="benefitIcon mb-2 mt-2" />
      </span>
      {tooltipRequired ? (
        <UncontrolledTooltip placement="top" target={id} fade={false}>
          {tooltip}
        </UncontrolledTooltip>
      ) : null}
    </span>
  );
};

export default ChecklistIcon;
