import clsx from 'clsx';
import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, UserMenu, useSidenav, useMobile } from '@octano/global-ui';
import useUserState from '../../hooks/useUserState';

interface Props {
  title: string;
}

const TopBar = ({ title }: Props) => {
  const isMobile = useMobile();
  const history = useHistory();
  const { toggle: toggleSidenav } = useSidenav();
  const { userData, clearUser } = useUserState();
  const { t } = useTranslation();

  const downloadUserGuide = () => {
    const url = window?.location?.origin + '/tenant/manual-administrador.pdf';
    window?.open(url, '_blank')?.focus();
  };

  const logOut = () => {
    clearUser();
    history.push('/');
  };

  return (
    <div className="g-tabs-header">
      <div className={clsx('bar', isMobile && 'barMobile')}>
        <Container fluid>
          <Row className="align-items-center justify-content-between">
            {isMobile && (
              <Col xs="auto">
                <span onClick={() => toggleSidenav()}>
                  <Icon name="menu" size="30px" className="menuIcon" />
                </span>
              </Col>
            )}
            {!isMobile && (
              <Col>
                <span className="title">{title}</span>
              </Col>
            )}
            <Col xs="auto d-flex">
              <UserMenu
                fullName={`${userData?.fullName || ''}${'\u00A0'.repeat(6)}`}
                email={userData?.email || ''}
                menuItems={[
                  {
                    icon: 'padlock',
                    text: t(`common.terms.changePassword`),
                    onClick: () => {
                      history.push('/profile/password');
                    },
                  },
                  {
                    icon: 'academic_offer',
                    text: t(`common.terms.userGuide`),
                    onClick: downloadUserGuide,
                  },
                  {
                    icon: 'log_out',
                    text: t(`common.terms.logOut`),
                    onClick: logOut,
                  },
                ]}
              />
              <div
                style={{
                  position: 'relative',
                  left: -20,
                  zIndex: -1,
                  paddingTop: 4,
                }}
              >
                <Icon name="chevron_down" color="inherit" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile && (
        <Container fluid>
          <h1 className="title">{title}</h1>
        </Container>
      )}
    </div>
  );
};

export default TopBar;
