// Components
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import Loading from '../../../../components/info/Loading';

import { Form, Row } from 'reactstrap';
import { useMobile } from '@octano/global-ui';

// Hooks
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import {
  CreateOfferStepArea,
  CreateOfferStepCode,
  OfferCreationStepProps,
} from '../../types';
import { OfferReview } from '../../../../types/offerReviewTypes';
import OfferReviewComponent from './parts/OfferReview';
import useGetStepData from '../../hooks/useGetStepData';

type ReviewOfferStepProps = OfferCreationStepProps & {};
type ReviewOfferStepData = OfferReview;

const keyPrefix = 'views.addOffer.offerReview';
const area = CreateOfferStepArea.ReviewOffer;

// Render
const ReviewOfferStep = ({
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: ReviewOfferStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const isMobile = useMobile();

  const stepWrapperRef = useRef<StepsWrappeMethods>(null);

  const { data: offerReview, isFetching } = useGetStepData<ReviewOfferStepData>(
    offerDraftId,
    area,
  );

  const methods = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (onSubmit) {
        await onSubmit({
          area,
          nextStep,
          payload: undefined,
          next,
          successMsg: t('success'),
          failedMsg: t('error'),
        });
      }
    },
    [nextStep, onSubmit, t],
  );

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Form onSubmit={methods?.handleSubmit(handleSave)}>
        <StepsWrapper
          keyPrefix={keyPrefix}
          ref={stepWrapperRef}
          offerDraftId={offerDraftId}
          onSave={onSave}
          prevStep={prevStep}
          buttonText={t('goBackToPrevious')}
        >
          <Row>
            <OfferReviewComponent offerReview={offerReview} />
            <BottomButtons
              buttons={
                nextStep === CreateOfferStepCode.Creation
                  ? [
                      {
                        outlined: true,
                        text: t('actions.cancel'),
                        onClick: () =>
                          stepWrapperRef?.current?.confirmedBack(
                            CreateOfferStepCode.OfferDetails,
                          ),
                        style: { width: isMobile ? '100%' : '246px' },
                        className: 'mr-4 mb-3',
                      },
                      {
                        type: 'submit',
                        text: t('actions.publish'),
                        style: { width: isMobile ? '100%' : '246px' },
                        className: 'mb-3',
                        disabled: isSubmitting,
                        loading: isSubmitting,
                      },
                    ]
                  : [
                      {
                        type: 'button',
                        text: t('actions.next'),
                        style: { width: isMobile ? '100%' : '246px' },
                        className: 'mb-3',
                        onClick: () => stepWrapperRef?.current?.prev(nextStep),
                        disabled: isSubmitting,
                        loading: isSubmitting,
                      },
                    ]
              }
              hint={t('infoOnClickNext')}
            />
          </Row>
        </StepsWrapper>
      </Form>
    </>
  );
};

export default ReviewOfferStep;
