import { useCallback, useMemo, useState } from 'react';

// Components
import OffersFilters, { OnFilterParams } from './parts/Filters';
import CustomCard from './parts/CustomCard';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { TablePagination } from '@octano/global-ui';

// Hooks
import { useGetOffersQuery } from '../api';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { RecruitmentCard } from './types';

type Filters = {
  campus?: number;
  school?: number;
  career?: number;
  subject?: number;
  workingDay?: number;
};

const ITEMS_PER_PAGE = 9;

// Render
export default function Recruitment() {
  const { t } = useTranslation();
  const history = useHistory();

  const [filters, setFilters] = useState<Filters>({});

  const [currentPage, setCurrentPage] = useState<number>(1);

  const offers = useGetOffersQuery(
    {
      itemsPerPage: ITEMS_PER_PAGE,
      page: currentPage - 1,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const totalPages = useMemo(
    () => offers?.data?.totalPages || 0,
    [offers?.data?.totalPages],
  );
  const totalOffers = useMemo(
    () => offers?.data?.total || 0,
    [offers?.data?.total],
  );
  const texts = useMemo<Record<string, string>>(
    () => ({
      vacant: t('views.recruitment.card.vacant'),
      vacantSelected: t('views.recruitment.card.vacantSelected'),
      vacantAvailable: t('views.recruitment.card.vacantAvailable'),
      discarded: t('views.recruitment.card.discartedPostulations'),
      inReview: t('views.recruitment.card.inReviewPostulations'),
      approved: t('views.recruitment.card.selectedPostulations'),
      applicationsReceived: t('views.recruitment.card.applicationsReceived'),
    }),
    [t],
  );

  const onClick = useCallback(
    (offerId: string | number) =>
      history.push(`/recruitment/offer-detail/${offerId}`),
    [history],
  );

  const handleFilter = useCallback(
    (params: OnFilterParams) => {
      setCurrentPage(1);
      setFilters({
        campus: params?.campusId || undefined,
        school: params?.schoolId || undefined,
        career: params?.careerId || undefined,
        subject: params?.subjectId || undefined,
        workingDay: params?.workingDayId || undefined,
      });
    },
    [setFilters],
  );

  const getItems = useCallback(
    (offer: RecruitmentCard, texts: Record<string, string>) =>
      Object.entries(offer)
        .slice(-8)
        .map(([key, value]) => {
          return {
            label: texts[key],
            value: value,
          };
        })
        ?.filter((e) => e?.label),
    [],
  );

  return (
    <Card className="p-4">
      <Row>
        <Col className="mb-4" xs={12}>
          <p className="fs-16 m-0">{t('views.recruitment.list.description')}</p>
        </Col>
      </Row>

      <OffersFilters onFilter={handleFilter} />

      {offers?.isFetching || offers?.error || !offers?.data?.data?.length ? (
        <Row className="mt-3">
          <Col xs={12}>
            {offers?.isFetching ? (
              <Loading />
            ) : (
              <DisplayError
                title={t('views.recruitment.list.filters.empty')}
                body=" "
                insideCard
                typeError={(offers?.error as any)?.status}
              />
            )}
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {Boolean(offers?.data?.data?.length) &&
              offers?.data?.data?.map((offer, index) => (
                <Col key={`key-${index}`} xs={12} md={4} className="pt-4">
                  <CustomCard
                    title={offer.title}
                    subtitle={offer.subtitle}
                    items={getItems(offer, texts)}
                    percent={(offer.vacantSelected * 100) / offer.vacant}
                    onClick={() => onClick(offer.id)}
                  />
                </Col>
              ))}
          </Row>
          <Row className="d-flex flex-row-reverse pt-5">
            <TablePagination
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                totalItems: totalOffers,
                totalPages: totalPages,
                onChangePage: (page) => setCurrentPage(page),
              }}
            />
          </Row>
        </>
      )}
    </Card>
  );
}
