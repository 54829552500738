import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PostulationManagement from '../views/recruitment/postulation-review/management';
import PostulationReview from '../views/recruitment/postulation-review/review';
import { useStepState } from './useStepState';

export const useRecruitmentSteps = () => {
  const { t } = useTranslation();
  const prefix = 'views.recruitment.titleSteps';
  const { currentStep } = useStepState();

  const stepsCreateOfferProcess = useMemo(() => {
    const availableSteps = [
      {
        id: 'review',
        name: t(`${prefix}.step1`),
        component: PostulationReview,
      },
      {
        id: 'management',
        name: t(`${prefix}.step2`),
        component: PostulationManagement,
      },
    ];
    return availableSteps;
  }, [t]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: React.FunctionComponent<any>[] = [];

    stepsCreateOfferProcess.forEach((step, index) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps, componentsSteps };
  }, [stepsCreateOfferProcess]);

  const getIndexStep = useCallback(
    /**
     * Una función que devuelve el índice de stepName en la matriz stepsCreateOfferProcess.
     * @param {string} stepId - nombre del paso al que se le recuperará el índice
     * @return {number} índice del paso ingresado
     * **/
    (stepId: string): number => {
      return stepsCreateOfferProcess.findIndex((step) => step.id === stepId);
    },
    [stepsCreateOfferProcess],
  );

  /**
   * El paso actual en base a la variable currentStep
   */

  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsCreateOfferProcess,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
  };
};
