import { Col } from 'reactstrap';

interface Props {
  title: string;
  description?: string;
  item?: boolean;
}

export default function Description({ title, description, item }: Props) {
  return (
    <>
      {item && (
        <Col xs={12} className="mb-4">
          <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
            {title}
          </h5>
          <p className="fs-18">{description}</p>
        </Col>
      )}
    </>
  );
}
