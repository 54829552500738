// Components
import { DisplayInfo, Table } from '@octano/global-ui';
import DisplayError from '../../../../components/info/DisplayError';

// Hooks
import { useMemo } from 'react';
import useStepColumns from '../hooks/useStepColumns';
import { useGetPostulationStagesQuery } from '../../api';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import {
  PostulationApplicationStage,
  PostulationStageStep,
} from '../../../recruitment/postulation-review/types';

type PostulationStepsTabProps = {
  userId: string | number;
  postulationId: string | number;
  maxCalification: number;
};

// Render
const PostulationStepsTab = ({
  userId,
  postulationId,
  maxCalification,
}: PostulationStepsTabProps) => {
  const { t } = useTranslation();

  const stages = useGetPostulationStagesQuery(
    { postulationId, userId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const data = useMemo(
    () => Array.from(stages?.data || [])?.sort((a, b) => a.order - b.order),
    [stages?.data],
  );
  const totalRegister = useMemo(
    () => stages?.data?.length || 0,
    [stages?.data],
  );
  const totalReviews = useMemo(
    () =>
      stages?.data?.filter(
        (e: PostulationApplicationStage) =>
          e?.status && e?.status !== PostulationStageStep.Pending,
      )?.length || 0,
    [stages?.data],
  );
  const totalApproves = useMemo(
    () =>
      stages?.data?.filter(
        (e: PostulationApplicationStage) =>
          e?.status === PostulationStageStep.Approved,
      )?.length || 0,
    [stages?.data],
  );

  const columns = useStepColumns({
    maxCalification,
  });

  return (
    <div className="w-100 py-4">
      <Table
        columns={columns}
        data={!stages?.isFetching ? data : []}
        isLoadingResults={stages?.isFetching}
        loadingView={{
          title: t(`common.loading.title`),
          subtitle: t(`common.loading.subtitle`),
        }}
        noResultsText={
          stages?.error ? (
            <DisplayError typeError={(stages?.error as any)?.status} />
          ) : (
            <div className="d-flex flex-column align-items-center no-result">
              <DisplayInfo
                title={t('views.recruitment.management.noResult')}
                textBody={t('views.recruitment.management.noResultDescription')}
              />
            </div>
          )
        }
      />
      {!!(stages?.data?.length && !stages?.isFetching) && (
        <div className="w-100 container-fluid">
          <div className="py-3 d-flex row alert alert-info border-0 rounded-0 mb-0">
            <strong className="flex-fill text-dark text-left">
              {t('views.recruitment.management.reviwedStages')}
            </strong>
            <strong className="flex-fill text-dark text-right pr-4">
              {`${totalReviews}/${totalRegister}`}
            </strong>
          </div>
          <div className="py-3 d-flex row alert alert-primary border-0 rounded-0">
            <strong className="flex-fill text-dark text-left">
              {t('views.recruitment.management.approveStages')}
            </strong>
            <strong className="flex-fill text-dark text-right pr-4">
              {`${totalApproves}/${totalRegister}`}
            </strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostulationStepsTab;
