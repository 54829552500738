// Components

import List from '../../../../../components/steps/List';
import Description from '../../../../../components/steps/Description';
import JobDetailHeader from './JobDetailHeader';

// Hooks
import { useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import {
  OfferReview,
  RequestEnum,
} from '../../../../../types/offerReviewTypes';

// Types
type OfferReviewComponentProps = {
  offerReview?: OfferReview;
};

const keyPrefix = 'views.addOffer.offerReview';

// Render
const OfferReviewComponent = ({ offerReview }: OfferReviewComponentProps) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const requests = useMemo<string[]>(
    () =>
      offerReview?.requests
        .filter((r) => r.shortName)
        .map((r) => r.shortName as string) ?? [],
    [offerReview?.requests],
  );

  const headerData = useMemo(
    () => ({
      name: offerReview?.name || '',
      campus: offerReview?.campus || '',
      career: offerReview?.career || '',
      workingDay: offerReview?.workingDay || '',
      modality: offerReview?.modality || '',
      period: offerReview?.period || '',
      subjectHours: offerReview?.subjectHours || 0,
    }),
    [offerReview],
  );

  return (
    <>
      <Description
        title={t('title')}
        description={t('description')}
        item={true}
      />
      <JobDetailHeader {...headerData} />
      <Description
        title={t('sections.description')}
        description={offerReview?.description}
        item={Boolean(offerReview?.description)}
      />
      <Description
        title={t('sections.responsabilityLevel')}
        description={offerReview?.levelResponsibility}
        item={Boolean(offerReview?.levelResponsibility)}
      />
      <List title={t('sections.educations')} list={offerReview?.educations} />
      <List
        title={t('sections.competencies')}
        list={offerReview?.competencies}
      />
      <List
        title={t('sections.certifications')}
        list={offerReview?.certifications}
      />
      <List
        title={t('sections.licenseTypes')}
        list={offerReview?.licenseTypes}
      />
      <List
        title={t('sections.requirements')}
        list={offerReview?.requirements}
      />
      <List
        title={t('sections.languageLevels')}
        list={offerReview?.languageLevels}
      />
      <Description
        title={t('sections.knowledge')}
        description={offerReview?.knowledge}
        item={Boolean(offerReview?.knowledge)}
      />
      <List title={t('sections.benefits')} list={offerReview?.benefits} />
      <List
        title={t('sections.steps')}
        subtitle={t('sections.stepsDescription')}
        list={offerReview?.applicationStages}
      />

      {offerReview?.laws.map((law, index) => (
        <Description
          key={index}
          title={law.name}
          description={law.description}
          item={Boolean(true)}
        />
      ))}

      <Description
        title={t('sections.hasAdministrativeInabilities')}
        description={t('sections.hasAdministrativeInabilitiesSubtitle')}
        item={requests.includes(RequestEnum.AdministrativeInabilities)}
      />

      <Description
        title={t('sections.hasInstitutionalInabilities')}
        description={t('sections.hasInstitutionalInabilitiesSubtitle')}
        item={requests.includes(RequestEnum.InstitutionalInabilities)}
      />
    </>
  );
};

export default OfferReviewComponent;
