import { Steps } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { Card } from 'reactstrap';

import withBasicProvider from '../../../hocs/withBasicProvider';
import { useRecruitmentSteps } from '../../../hooks/useRecruitmentSteps';
import { useStepState } from '../../../hooks/useStepState';
import StepProvider from '../../../providers/StepProvider';

function RecruimentSteps() {
  const { namesSteps, CurrentStepComponent, getIndexStep } =
    useRecruitmentSteps();

  const { currentStep, setCurrentStep } = useStepState();

  //const { offerDraftId } = useParams<{ offerDraftId: string }>();

  const getCurrentStepRequest = useCallback(async () => {
    //const { data } = await getCurrentStep(Number(offerDraftId));
    if (true) {
      const index = getIndexStep('review');
      setCurrentStep(index);
    }
  }, [getIndexStep, setCurrentStep]);

  useEffect(() => {
    getCurrentStepRequest();
  }, [getCurrentStepRequest]);

  return (
    <>
      <div className="w-100 mb-4 p-0">
        <Steps currentStep={currentStep} steps={namesSteps} color="secondary" />
      </div>
      <Card className="p-4">
        {CurrentStepComponent && <CurrentStepComponent />}
      </Card>
    </>
  );
}
export default withBasicProvider(StepProvider)(RecruimentSteps);
