import { Button, CheckInput } from '@octano/global-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap';

import { Base, CheckNameType } from '../../types/GenericFormTypes';
import ChecklistIcon from './ChecklistIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddNewItemModalMethods,
  ItemType,
} from '../../views/createOffers/parts/AddNewItemModal';

interface GenericProps {
  name: CheckNameType;
  type?: ItemType;
  title?: string;
  subtitle?: string;
  buttonTitle?: string;
  hasIcon?: boolean;
  hasDescription?: boolean;
  canAddItems?: boolean;
  className?: string;
  required?: 'some' | 'all';
  onClickAddButton?: AddNewItemModalMethods['open'];
}

export default function ChecklistSection2({
  name,
  type,
  title = 'Title',
  subtitle = '',
  buttonTitle = 'Button title',
  onClickAddButton = () => null,
  hasIcon = true,
  hasDescription = true,
  canAddItems = true,
  className = 'mt-5',
  required,
}: GenericProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const fieldsReadonly = watch(name);

  //el modal de agregar nuevos items debe pertenecer a este componente. pendiente de agregar.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = useFieldArray<{ [id: string]: Base[] }>({
    name,
  });

  const someSelected = useMemo(
    () => fieldsReadonly?.some((item: Base) => item.isMark),
    [fieldsReadonly],
  );

  const allSelected = useMemo(
    () => fieldsReadonly?.every((item: Base) => item.isMark),
    [fieldsReadonly],
  );

  const handleClickAdd = () => {
    onClickAddButton && type && onClickAddButton(type);
  };

  return (
    <>
      <Col xs={12} md={10} className={className}>
        <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {title}
        </h5>
        <p className="fs-16">{subtitle}</p>
      </Col>
      {canAddItems && (
        <Col xs={12} md={2} className="">
          <Button
            outlined
            size="sm"
            style={{ minWidth: 199.81 }}
            icon="plus"
            text={buttonTitle}
            onClick={() => handleClickAdd()}
            className="float-right"
          />
        </Col>
      )}

      <Col xs={12}>
        {fieldsReadonly?.map((item: Base, index: number) => {
          return (
            <div
              className="d-flex justify-content-start align-items-center mb-4"
              style={{ gap: 8 }}
              key={index}
            >
              <CheckInput
                name={`${name}.${index}.isMark`}
                label=""
                control={control}
                rules={{
                  validate: {
                    required: () => {
                      if (
                        (required === 'some' && !someSelected) ||
                        (required === 'all' && !allSelected)
                      ) {
                        return '';
                      }

                      return undefined;
                    },
                  },
                }}
              />
              {hasIcon && (
                <div className="pt-1">
                  <ChecklistIcon
                    benefitName={item.icon!}
                    key={index}
                    id={`icon-${index}`}
                  />
                </div>
              )}
              <div className="pt-2">
                <h5 className="fs-18 fw-600  text-medium fs-14">{item.name}</h5>
                {hasDescription && <p className="fs-14">{item.description}</p>}
              </div>
            </div>
          );
        })}

        {errors[name] && (
          <div className="fs-16 text-danger mb-3">
            {t('common.validations.required')}
          </div>
        )}
      </Col>
    </>
  );
}
