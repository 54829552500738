// Components
import AddEmailModal, { AddEmailModalMethods } from './parts/AddEmailModal';
import BottomButtons from '../../parts/BottomButtons';
import StepsWrapper, { StepsWrappeMethods } from '../../parts/StepWrapper';
import Description from '../../../../components/steps/Description';
import Loading from '../../../../components/info/Loading';

import { Form, Row, Col, UncontrolledTooltip } from 'reactstrap';
import {
  Button,
  Icon,
  RemoteSelect,
  addToast,
  useMobile,
} from '@octano/global-ui';

// Hooks
import useGetStepData from '../../hooks/useGetStepData';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useSearchUsersMutation } from '../../api';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import {
  CreateOfferStepArea,
  CreateOfferStepCode,
  OfferCreationStepProps,
} from '../../types';
import { CurrentStep } from '../../../../types/stepsTypes';
import { OfferGuest } from '../../../draftMaintainer/types';

type InvitationStepProps = OfferCreationStepProps & {};

const keyPrefix = 'views.addOffer.offerInvitation';
const area = CreateOfferStepArea.InviteUsers;

// Render
const InvitationStep = ({
  backRequireConfimation = false,
  isSubmitting = false,
  prevStep,
  nextStep,
  offerDraftId = 0,
  onSubmit,
  onSave,
}: InvitationStepProps) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const isMobile = useMobile();

  const stepWrapperRef = useRef<StepsWrappeMethods>(null);
  const addEmailRef = useRef<AddEmailModalMethods>(null);

  const [invitations, setInvitations] = useState<OfferGuest[]>([]);

  const { data: offerData, isFetching: isFetchingOffer } =
    useGetStepData<CurrentStep>(offerDraftId);
  const [searchUsers] = useSearchUsersMutation();

  const methods = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const handleSave = useCallback(
    async ({ next = true }: { next?: boolean }) => {
      if (!invitations?.length) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('minInviteds'),
        });
        return;
      }
      if (onSubmit) {
        await onSubmit({
          area,
          nextStep,
          payload: { guestQueue: invitations },
          next,
          successMsg: next ? t('success') : t('successSave'),
          failedMsg: next ? t('error') : t('saveError'),
        });
      }
    },
    [invitations, nextStep, onSubmit, t],
  );

  const handlePushEmail = useCallback(
    (guest: OfferGuest) => {
      if (guest?.email?.trim()) {
        if (!invitations?.some((i) => i?.email === guest?.email)) {
          setInvitations((prev) => prev?.concat([guest]));
        }
      }
    },
    [invitations],
  );

  const removeEmail = useCallback((guest: OfferGuest) => {
    setInvitations((prev) => prev?.filter((e) => e.email !== guest?.email));
  }, []);

  const handleSearch = useCallback(
    async (search: string) => {
      try {
        if (search?.trim()) {
          const response = await searchUsers(search).unwrap();
          return response?.map((user) => ({
            label: `${user?.fullName} - ${user?.rut} (${user?.email})`,
            value: `${user?.fullName?.trim()}|${user?.email
              ?.trim()
              ?.toLowerCase()}`,
          }));
        }
        return [];
      } catch (_error) {
        return [];
      }
    },
    [searchUsers],
  );

  const onClickAdd = useCallback(() => {
    if (isSubmitting) {
      return;
    }
    addEmailRef?.current?.open();
  }, [isSubmitting]);

  useEffect(() => {
    if (offerData?.offer?.guestQueue?.length) {
      setInvitations(offerData?.offer?.guestQueue || []);
    }
  }, [offerData?.offer?.guestQueue]);

  if (isFetchingOffer) {
    return <Loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods?.handleSubmit(handleSave)}>
          <StepsWrapper
            keyPrefix={keyPrefix}
            ref={stepWrapperRef}
            offerDraftId={offerDraftId}
            onSave={onSave}
            prevStep={prevStep}
            backRequireConfimation={backRequireConfimation}
            buttonText={t('goBackToPrevious')}
          >
            <Row>
              <Col xs={12}>
                <Description
                  title={t('title')}
                  description={t('description')}
                  item={true}
                />
                <div className="d-flex flex-wrap justify-content-center align-items-end">
                  <div className="flex-fill">
                    <RemoteSelect
                      value={{ value: '', label: '' }}
                      name="search"
                      label={t('searchBy')}
                      loadingMessage={t('loadingResults')}
                      noOptionsMessage={t('noResults')}
                      placeholder={t('searchByPlaceholder')}
                      placeholderSearchText={t('searchByPlaceholder')}
                      filterOptions={handleSearch}
                      onChange={(next) =>
                        handlePushEmail({
                          name: next?.value?.split('|')[0],
                          email: next?.value?.split('|')[1],
                        })
                      }
                    />
                  </div>
                  <div className="pb-3 pl-2">
                    <Button
                      text={t('addNewEmail')}
                      icon="plus"
                      style={{ fontWeight: 'bold' }}
                      outlined
                      onClick={onClickAdd}
                    />
                  </div>
                  <div className="pb-3 pl-2">
                    <div
                      className="d-flex align-items-center"
                      style={{ height: 65 }}
                    >
                      <div id="tooltip-help">
                        <Icon name="information" color="primary" />
                      </div>
                      <UncontrolledTooltip
                        placement="top"
                        target="tooltip-help"
                        fade={false}
                      >
                        {t('tooltip')}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <h2 className="text-primary fs-20 fw-700 mb-3 text-uppercase mt-5">
                  {t('guests')}
                </h2>
                <div className="d-flex flex-wrap">
                  {invitations?.map((invitation, i) => (
                    <button
                      key={i}
                      type="button"
                      className="btn bg-tertiary text-primary border border-primary rounded mr-2 mb-2"
                      onClick={() => removeEmail(invitation)}
                    >
                      {`${invitation?.name} (${invitation?.email}) X`}
                    </button>
                  ))}
                </div>
              </Col>
              <BottomButtons
                buttons={[
                  {
                    outlined: true,
                    color: 'danger',
                    text: t('actions.cancel'),
                    onClick: () =>
                      stepWrapperRef?.current?.confirmedBack(
                        CreateOfferStepCode.OfferDetails,
                      ),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mr-4 mb-3',
                  },
                  {
                    outlined: true,
                    text: t('actions.save'),
                    onClick: () => handleSave({ next: false }),
                    style: { width: isMobile ? '100%' : '155px' },
                    className: 'mr-4 mb-3',
                    disabled: isSubmitting,
                  },
                  {
                    type: 'submit',
                    text: t('actions.publish'),
                    style: { width: isMobile ? '100%' : '246px' },
                    className: 'mb-3',
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
              />
            </Row>
          </StepsWrapper>
        </Form>
      </FormProvider>
      <AddEmailModal
        ref={addEmailRef}
        list={invitations}
        onConfirm={handlePushEmail}
      />
    </>
  );
};

export default InvitationStep;
