import { Base } from '../../types/GenericFormTypes';
import { OfferType } from '../../types/jobOffer';
import { OnSubmitParams } from './parts/RenderComponent';

export enum CreateOfferStepCode {
  OfferDetails = 'detail-offer',
  Requirement = 'requirement',
  RequirementApplicant = 'requirement-applicant',
  StagesProcess = 'stages-process',
  ReviewOffer = 'review-offer',
  InviteUsers = 'invitation',
  Creation = 'creation',
  SendInvitations = 'invitation/confirm',
}

export enum CreateOfferStepArea {
  OfferDetails = 'detail',
  Requirement = 'requirement',
  RequirementApplicant = 'questions',
  StagesProcess = 'application-stages',
  ReviewOffer = 'review',
  InviteUsers = 'invitation',
}

// Steps Props
export type OfferCreationStepProps = {
  isSubmitting?: boolean;
  backRequireConfimation?: boolean;
  prevStep?: CreateOfferStepCode;
  nextStep?: CreateOfferStepCode;
  offerDraftId?: string | number;
  onSubmit?: (params: OnSubmitParams) => Promise<void>;
  onSave?: () => Promise<void>;
};

// Step Item
export type CreateOfferStep = {
  code: CreateOfferStepCode;
  name: string;
  renderComponentProps: {
    backRequireConfimation?: boolean;
    prevStep?: CreateOfferStepCode;
    nextStep?: CreateOfferStepCode;
  };
};

// Steps
export type CreationStepsRes = {
  currentStep: CreateOfferStepCode;
  steps: CreateOfferStep[];
};
export type CreationStepsReq = {
  scope?: OfferType;
  offerDraftFlowId?: number | string;
};

// Step Data
export type GetCreationStepRes<T = any> = {
  data?: T;
  isFetching: boolean;
};
export type GetCreationStepReq = {
  id?: number | string;
  area?: CreateOfferStepArea;
};

// Step Dependency
export type GetCreationDepRes<T = any> = {
  data?: T;
  isFetching: boolean;
};
export type GetCreationDepReq = {
  id?: number | string;
  dep: 'careers' | 'subjects';
};

// Create OfferReq
export type CreateOfferReqReq = {
  area: CreateOfferStepArea;
  type: string;
  body: Base;
};

// Update Draft Step
export type UpdateDraftStep = {
  id: string | number;
  step: CreateOfferStepCode;
};

// Update Draft Data
export type SaveDraftReq = {
  id?: string | number;
  area: CreateOfferStepArea;
  payload: any;
};
