// Components
import { TextOutlinedInput } from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import { useFormContext } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { formatToOnlyPositiveIntegers } from '../../../../../utils/formatter';

// Types
import { OfferDetailsFormFields } from '../types';

const prefix = 'views.addOffer.offerDetails.salaryRangeSection';

// Render
const SalaryRangeSection = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<OfferDetailsFormFields>();
  const salaryFrom: any = watch('salaryFrom');

  const validateSalaryRange = (value: any) => {
    if (parseInt(value) < salaryFrom) {
      return t(`${prefix}.salaryRangeError`);
    }
    return undefined;
  };

  return (
    <>
      <Col xs={12} className="mt-5">
        <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {t(`${prefix}.title`)}
        </h5>
        <p className="fs-16">{t(`${prefix}.subtitle`)}</p>
      </Col>
      <Col xs={12} md={2}>
        <TextOutlinedInput
          name="salaryFrom"
          label={t(`${prefix}.salaryFrom`)}
          formatter={formatToOnlyPositiveIntegers}
          control={control}
        />
      </Col>
      <Col xs={12} md={2}>
        <TextOutlinedInput
          name="salaryTo"
          label={t(`${prefix}.salaryTo`)}
          formatter={formatToOnlyPositiveIntegers}
          disabled={!salaryFrom}
          control={control}
          rules={{ validate: validateSalaryRange }}
        />
      </Col>
    </>
  );
};

export default SalaryRangeSection;
